
import React, { FC, useMemo, useState } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

// import { PortfolioMap } from "./PortfolioMap";
// import { SectorsProducts } from "./SectorsProducts";
//@ts-ignore
import numeral from "numeral"
import { isAfter } from "date-fns";
import { Link } from "react-router-dom";

// interface IPropsPortfolio {
// 	countryDeals: Array<AggregateItem>;
// 	deals: Array<Deal>;
// 	investor: TInvestor;
// }

export const OrgPortfolioFragment = ({ org }: any) => {
    const sectorDeals = org.investments?.reduce((acc: any, deal: any) => {
        const { target } = deal;
        const { sectors } = target;
        sectors?.forEach((sector: any) => {
            if (!acc[sector?.name]) {
                acc[sector?.name] = {
                    label: sector?.name,
                    value: 0,
                };
            }
            acc[sector?.name].value += 1;
        });

        return acc;
    }, {});

    const sectors = Array.from(Object.values(sectorDeals)) as any[];

    const productDeals = org?.investments.reduce((acc: any, deal: any) => {
        const { target } = deal;
        const { sub_sectors } = target;
        sub_sectors?.forEach((sector: any) => {
            if (!acc[sector?.name]) {
                acc[sector?.name] = {
                    label: sector?.name,
                    value: 0,
                };
            }
            acc[sector?.name].value += 1;
        });

        return acc;
    }, {});

    const products = Array.from(Object.values(productDeals)) as any[];

    const getTotalFunding = () => {
        //@ts-ignore
        return org?.investments?.reduce((acc, deal) => {
            return acc + deal.amount;
        }, 0);
    };

    const getTotalFundingWithFormat = () => {
        //@ts-ignore
        return numeral(org?.investments?.reduce((acc, deal) => {
            return acc + deal.amount;
        }, 0)).format("$ (0,0)");
    };

    const getLatestFunding = () => {
        //@ts-ignore
        const latestDeal = org?.investments?.reduce((acc, deal) => {
            if (isAfter(new Date(acc.date), new Date())) {
                return deal;
            }
            return acc;
        }, org?.investments[0]);
        return latestDeal;
    };

    const numberOfDeals = org?.investments?.flat().length;

    const [showMap, setShowMap] = useState(true);
    const toggle = () => setShowMap(!showMap);

    const [showSectors, setShowSectors] = useState(false);
    const toggleSectors = () => setShowSectors(!showSectors);

    return (
        <div className='w-full relative bg-white mb-20 flex'>
            <div className='w-full pr-2'>
                <p className='text-lg font-medium text-left text-gray-4100 mt-6'>Investments</p>
                <div className='flex justify-start items-start w-full gap-8 mt-6'>
                    <div className='flex flex-col justify-start items-start w-1/2 relative gap-[5px]'>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Number of investments</p>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100'>
                            {numberOfDeals > 0 ? numberOfDeals : <span className='normal-case'>No funding information available</span>}
                        </p>
                    </div>
                    <div className='flex flex-col justify-start items-start w-1/2 relative gap-[5px]'>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Total nvestments amount</p>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100 uppercase'>
                            {getTotalFunding() > 0 ? (
                                (getTotalFunding())
                            ) : (
                                <span className='normal-case'>No funding information available</span>
                            )}
                        </p>
                    </div>
                </div>
                {org?.investments?.length > 0 ? (
                    <p className='text-sm text-left text-gray-3400 w-full mt-6'>
                        <span className='text-gray-4100'>{org?.name}</span> has funded a total of{" "}
                        <span className='text-gray-4100 uppercase'>{(getTotalFunding())}</span> in funding over {org.investments?.length} rounds.
                        Their latest fund was on <span className='text-gray-4100'>{new Date(org?.investments[0]?.date).toLocaleDateString()}</span> to{" "}
                        <span className='text-gray-4100'>{getLatestFunding()?.target?.name || ""}</span>.
                    </p>
                ) : null}
                {/* <div className='flex justify-between items-center mt-6'>
                    <p className='text-lg font-medium text-left text-gray-4100'>{showMap && "Geography"}</p>
                    <div className='flex justify-between items-center gap-2 bg-gray-1000 px-2 py-1 rounded-md cursor-pointer' onClick={toggle}>
                        <p className='text-gray-3400 text-xs'>{showMap ? "Hide" : "Show"}</p>
                        {showMap && <FaChevronUp className='text-gray-3600' height={30} width={30} />}
                        {!showMap && <FaChevronDown className='text-gray-3600' height={30} width={30} />}
                    </div>
                </div>
                {showMap && (
                    <div className='w-full sm:mt-4  p-1 relative'>
                        <PortfolioMap deals={countryDeals} investor={investor} />
                    </div>
                )}
                <div className='flex justify-end items-center sm:mt-6'>
                    <div className='flex justify-between items-center gap-2 bg-gray-1000 px-2 py-1 rounded-md cursor-pointer' onClick={toggleSectors}>
                        <p className='text-gray-3400 text-xs'>{showSectors ? "Hide" : "Show"}</p>
                        {showSectors && <FaChevronUp className='text-gray-3600' height={30} width={30} />}
                        {!showSectors && <FaChevronDown className='text-gray-3600' height={30} width={30} />}
                    </div>
                </div>
                {showSectors && (
                    <div className='w-full sm:mt-4 flex flex-col sm:flex-row gap-4'>
                        <SectorsProducts title='Sectors' data={sectors} />
                        <SectorsProducts title='Products' data={products} />
                    </div>
                )} */}
                <svg
                    width='100%'
                    height={1}
                    viewBox='0 0 100vw 1'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='my-6'
                    preserveAspectRatio='xMidYMid meet'>
                    <line x1='4.37114e-8' y1='0.5' x2='100%' y2='0.500059' stroke='#EBEBF8' />
                </svg>
                <PortfolioList deals={org?.investments} />
            </div>
        </div>
    );
};

export const PortfolioList = ({ deals }: any) => {
    const companies = useMemo(() => {
        let arrCompanies = [] as any[];
        deals?.forEach((deal: any) => {
            const findCompany = arrCompanies.find((company) => company?.id === deal?.target?.id);
            if (!findCompany) {
                arrCompanies = [...arrCompanies, deal?.target];
            }
        });
        return arrCompanies;
    }, [deals]);
    return (
        <div className='w-full mt-8'>
            <p className='text-lg font-semibold text-left text-gray-4100'>Portfolio</p>
            {companies?.length > 0 ? (
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8'>
                    {companies?.map((company) => (
                        <Link to={["/organizations", company?.id].join("/")} key={company?.id}>
                            <div className='w-full sm:w-1/2 flex items-start gap-2'>
                                <img src={(company?.profile_image_url)} alt='' className='w-10 h-10 rounded-full object-contain' />

                                <div className='flex flex-col justify-center gap-1'>
                                    <p className='text-sm font-semibold text-gray-4100'>{company?.name}</p>
                                    <p className='text-xs font-normal text-gray-3400 capitalize'>{company?.type}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            ) : (
                <div className='w-full flex justify-center items-center'>
                    <p className='text-sm font-normal text-gray-3400'>No deals found</p>
                </div>
            )}
        </div>
    );
};