import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi"
import { SEARCH_QUERY } from "../../queries/search";
import { Spinner } from "../../elements/Spinners";
import { get } from "lodash";
import { Persona } from "../../elements/Persona";
import { Link } from "react-router-dom";
import { useOnClickOutside } from "usehooks-ts";
import { BUCKET_ORGS } from "../../constants";
export const SearchAnywhereInput = () => {
    const [query, setQuery] = useState("")
    const [search, { data, loading, error }] = useLazyQuery(SEARCH_QUERY)
    const ref = useRef(null)
    useEffect(() => {
        search({
            variables: {
                search: {
                    bucket: BUCKET_ORGS,
                    size: 10,
                    query: {
                        filter: [{ query_string: { query: `*${query}*`, default_field: "name" } }],
                    },
                },
            }
        })
    }, [query])

    useOnClickOutside(ref, () => setQuery(""))

    return (
        <div className="relative grow" ref={ref}>
            <FiSearch className="absolute text-[#9ba2a3] left-3 top-2.5 w-5 h-5" />
            <input
                className="rounded-md border border-gray-400 py-2 text-[16px] px-10 w-full placeholder:text-[#9ba2a3] outline-none"
                type="text"
                placeholder="Search in our database"
                value={query} onChange={(ev) => {
                    setQuery(ev.target.value)
                }}
            />
            {
                query && query.length > 1 && (
                    <div className="absolute shadow-md bg-white w-full mt-1 border-b rounded-md">
                        <div className="p-4">
                            <p className="text-base font-light">Searching: <span className="font-medium">{query}</span></p>
                            {
                                loading && <Spinner message="Searching..." />
                            }
                            <div className="flex flex-col gap-2 items-start justify-start pt-4">
                                {
                                    get(data, 'search.items', []).map((item: any) => {
                                        return (
                                            <Link to={["/organizations", item.id].join("/")} onClick={(ev) => setQuery("")}>
                                                <Persona text={item.name} imageURL={item.profile_image_url} size="sm" />
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}