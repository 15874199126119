import { gql } from "@apollo/client";

export const GET_EVENT = gql`
	query initiative($id: ID!) {
		initiative(id: $id) {
			id
			title
			description
			type
			start_at
			body
			end_at
			banner
			uri
			countries_of_interests: countries_interests {
				id
				name
				alpha2
			}
			sectors_of_interests: sectors {
				id
				name
			}
			sponsors {
				id
				name
				profile_image_url
			}
			organizers {
				id
				name
				profile_image_url
			}
			organizations_speakers {
				id
				name
				profile_image_url
			}
			has_free_access
			price_from
			price_to
			locations {
				id
				country {
					id
					name
				}
			}
			formats {
				id
				name
				description
			}
		}
	}
`;