import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
//@ts-ignore
import cn from "mxcn";
import srcLogo from "./agbase-light.svg"
import { Link, NavLink } from "react-router-dom";
import { PiCaretDownBold } from "react-icons/pi"
import { SearchAnywhereInput } from "../SearchAnywhere";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { RxCross1, RxDividerVertical, RxExit, RxHamburgerMenu, RxMagnifyingGlass, RxMixerVertical } from "react-icons/rx"
import { useAuth } from "../../context/AuthContext";
import { get } from "lodash";
import { useEventListener, useOnClickOutside, useTimeout } from "usehooks-ts";

export const Logo = () => {
    return (
        <img src={srcLogo} alt="AgBase" className="max-w-[80px] py-1" />
    )
}

const TopNavLink = ({ path, title, hasChildren = false }: { path: string, title: string, hasChildren?: boolean }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="relative">
            <NavLink className={({ isActive }) => {
                const cls = classNames({
                    "xl:border-b-2 py-2 xl:py-5 px-0 xl:px-4 -m-0.5 flex gap-2 items-center": true,
                    "text-ab-lightteal border-b-transparent": !isActive,
                    "text-ab-lime xl:border-b-ab-lime": isActive
                })
                return cls
            }} to={path} onClick={(ev) => {
                if (hasChildren) {
                    setIsOpen(!isOpen)
                    ev.preventDefault()
                }

                return ev
            }}>{title} {hasChildren && <PiCaretDownBold />}</NavLink>
            {
                hasChildren && isOpen && (
                    <ul className="absolute my-2 z-[1000] float-left m-0 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg [&[data-te-dropdown-show]]:block">
                        <li>
                            <Link
                                className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400"
                                to="/organizations"
                                onClick={(ev) => setIsOpen(false)}
                            >Action</Link>
                        </li>
                    </ul>
                )
            }
        </div>
    )
}

export const NavBarLeftMenu = () => {
    return (
        <div className="flex items-center gap-6">
            <TopNavLink path="/organizations" title="Organisations" />
            <TopNavLink path="/deals" title="Deals" />
            <TopNavLink path="/comparison" title="Comparison" />
            <TopNavLink path="/events" title="Events" />
            <TopNavLink path="/research" title="Research & Insights Hub" />
        </div>
    )
}

export const NavBarLeftMenuVertical = () => {
    return (
        <div className="flex gap-2 flex-col py-6">
            <TopNavLink path="/organizations" title="Organisations" />
            <TopNavLink path="/deals" title="Deals" />
            {/* <TopNavLink path="/comparison" title="Comparison" /> */}
            <TopNavLink path="/events" title="Events" />
            <TopNavLink path="/research" title="Research & Insights Hub" />
        </div>
    )
}

export const NavBar = () => {
    const { session } = useAuth();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
    console.log("country", get(session, 'user.country', undefined))
    return (
        <nav className="fixed bg-ab-darkgreen w-full border-b-ab-lightdarkgreen border-b-2 z-20 max-w-[100vw] h-[60px]">
            <div className="container-ab flex items-center gap-8 lg:gap-16">
                <div className="flex items-center">
                    <button className="xl:hidden text-xl text-ab-lightteal pr-5" onClick={(ev) => {
                        ev.preventDefault();
                        setIsMobileMenuOpen(!isMobileMenuOpen)
                    }}>
                        {isMobileMenuOpen ? <RxCross1 /> : <RxHamburgerMenu />}
                    </button>

                    <Link to="/" className="py-2">
                        <Logo />
                    </Link>
                </div>
                <div className="hidden xl:block">
                    <NavBarLeftMenu />
                </div>
                <div className="hidden md:flex md:grow gap-2 lg:gap-4 items-center justify-end">
                    <div className="hidden md:grow md:flex">
                        <SearchAnywhereInput />
                    </div>
                    {/* <button className="flex items-center gap-2 py-3 xl:py-2 bg-ab-yellow rounded-md px-3 xl:px-8 font-semibold">
                        <FiPlus /> <span className="hidden xl:block">Suggest new</span>
                    </button> */}
                    <RxDividerVertical className="text-3xl text-ab-lightdarkgreen" />
                    {/* <button className="rounded-md bg-ab-lightdarkgreen text-ab-lightgreen py-2.5 px-2.5 text-lg">
                        <FaBell />
                    </button> */}
                    <ProfileMenu />

                    {
                        !get(session, 'user.is_freemium', true) && (
                            <HiMiniCheckBadge className="text-ab-lime absolute top-1" />
                        )
                    }
                </div>
                <div className="flex grow justify-end items-center gap-4 md:hidden">
                    <RxMagnifyingGlass className="text-3xl text-ab-lightteal" onClick={(ev) => {
                        ev.preventDefault();
                        setIsMobileSearchOpen(true);
                    }} />
                    <button>
                        <img src={get(session, 'user.profile_image_url')} alt={get(session, 'user.email')} className="h-10 w-10 border-4 object-cover border-ab-lightdarkgreen rounded-full" />
                    </button>
                </div>
            </div>
            {
                isMobileMenuOpen && (
                    <AnimateIn
                        from="absolute top-0 opacity-100 -translate-x-96"
                        to="absolute top-0 opacity-100 translate-x-0"
                        duration={200}
                    >
                        <div className="absolute w-96 top-14 bg-ab-darkgreen h-screen">
                            <div className="px-8">
                                <NavBarLeftMenuVertical />
                            </div>
                        </div>
                    </AnimateIn>
                )
            }
            {
                isMobileSearchOpen && (
                    <AnimateIn
                        from="absolute top-0 opacity-100 translate-x-96"
                        to="absolute top-0 opacity-100 translate-x-0"
                        duration={100}
                    >
                        <div className="absolute top-0 bg-ab-darkgreen p-2 w-screen z-20">
                            <div className="px-2">
                                <div className="flex gap-4 items-center">
                                    <RxCross1 className="text-lg text-ab-lime" onClick={(ev) => {
                                        ev.preventDefault();
                                        setIsMobileSearchOpen(false);
                                    }} />
                                    <SearchAnywhereInput />
                                </div>
                            </div>
                        </div>
                    </AnimateIn>
                )
            }
            {/* {
                get(session, 'user.country', undefined) == undefined && (
                    <div className="bg-white bg-opacity-75 fixed top-0 left-0 h-screen w-screen overflow-hidden z-50">
                        
                    </div>
                )
            } */}
        </nav>
    )
}

const ProfileMenu = ({ session }: any) => {
    const { logout } = useAuth();
    const [state, setState] = useState({ isOpen: false })
    const ref = useRef(null);
    useOnClickOutside(ref, () => setState({ isOpen: false }))
    useEventListener('mouseenter', () => {
        setState({ isOpen: true })
    }, ref)


    return (
        <div className="relative" ref={ref}>
            <button onClick={(ev) => {
                ev.preventDefault()
                setState({ isOpen: true })
            }}>
                <img src={get(session, 'user.profile_image_url')} alt={get(session, 'user.email')} className="h-10 w-10 border-4 object-cover border-ab-lightdarkgreen rounded-full" />
            </button>
            {
                state.isOpen && (
                    <div className="origin-top-right absolute right-0 mt-3 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="" role="menu" aria-orientation="vertical" aria-labelledby="dropdown-button">
                            <button
                                className="flex items-center gap-2 text-left w-full rounded-md px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    logout();
                                }}
                            >
                                <RxExit /> Logout
                            </button>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

// or if using shadcn:
// import { cn } from "@/lib/utils"; // https://github.com/shadcn-ui/ui/blob/main/apps/www/lib/utils.ts

const AnimateIn = ({
    children,
    delay = 0,
    duration = 500,
    className = "",
    from,
    to,
    style,
    as = 'div',
}: {
    from: string;
    to: string;
    children?: React.ReactNode;
    delay?: number;
    duration?: number;
    className?: string;
    style?: React.CSSProperties;
    as?: keyof React.ReactHTML;
}) => {
    const [animate, setAnimate] = useState(from);
    const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

        const mediaQueryChangeHandler = (e: MediaQueryListEvent) => {
            setPrefersReducedMotion(e.matches);
        };

        setPrefersReducedMotion(mediaQuery.matches);
        mediaQuery.addEventListener('change', mediaQueryChangeHandler);

        return () => {
            mediaQuery.removeEventListener('change', mediaQueryChangeHandler);
        };
    }, []);

    useEffect(() => {
        if (prefersReducedMotion) {
            // If the user prefers reduced motion, skip the animation
            setAnimate(to);
            return;
        }

        const timer = setTimeout(() => {
            setAnimate(to);
        }, delay);

        return () => clearTimeout(timer);
    }, [delay, to, prefersReducedMotion]);

    return React.createElement(
        as,
        {
            className: cn("transition-all ease-in-out", className, animate),
            style: { transitionDuration: prefersReducedMotion ? "0ms" : `${duration}ms`, ...style },
        },
        children
    );
};

export default AnimateIn;