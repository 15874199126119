import { gql, useQuery } from "@apollo/client";
import { useDataContainerContext } from "../../../elements/DataContainer";
import { get, reduce, times } from "lodash";
import classNames from "classnames";
//@ts-ignore
import numeral from "numeral"
import { EMGeoHeatmap } from "./EMGeoHeatmap";
import { useState } from "react";
import { scaleOrdinal } from "@visx/scale";
import { EMGeoTablePieChart } from "./EMGeoTablePieChart";

export interface IEMGeoTableTab {
    pivot: string;
    pivotLabel: string;
}

const columnsKeyToText = {
    "sum.value": "Total Amount",
    "orgCount.value": "# of Companies",
    "dealsCount.value": "# of Deals",
    "max.value": "Deal Size (max)",
    "avg.value": "Deal Size (avg)"
}

export const EMGeoTableTab = ({ pivot, pivotLabel }: IEMGeoTableTab) => {
    const { filtersQuery } = useDataContainerContext()
    const [keyOfGeo, setKeyOfGeo] = useState('sum.value');
    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }   
    }

    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "rows",
                        type: "terms",
                        params: {
                            field: pivot,
                            size: 1000,
                            order: {
                                sum: "desc"
                            }
                        },
                        extra: {
                            aggs: {
                                sum: {
                                    sum: {
                                        field: "amount"
                                    }
                                },
                                max: {
                                    max: {
                                        field: "amount"
                                    }
                                },
                                orgCount: {
                                    cardinality: {
                                        field: "target.id.keyword"
                                    }
                                },
                                dealsCount: {
                                    cardinality: {
                                        field: "id.keyword"
                                    }
                                },
                                avg: {
                                    avg: {
                                        "field": "amount"
                                    }
                                }
                            }
                        },
                    },
                ],
            },
        },
        fetchPolicy: "cache-and-network",

    });

    const thcls = classNames({
        "text-sm text-left bg-ab-lightteal text-ab-darkergreen px-2 py-2 uppercase first:rounded-l-md last:rounded-r-md group-hover/highlight:bg-gray-200": true
    })

    const rows = get(data, 'aggregate.aggregations.rows.buckets', []);
    const totalValueAmount = reduce(get(data, 'aggregate.aggregations.rows.buckets', []).map((row: any) => get(row, 'sum.value', '0')), (sum, v) => sum + v, 0)
    const totalNoCompanies = reduce(get(data, 'aggregate.aggregations.rows.buckets', []).map((row: any) => get(row, 'orgCount.value', '0')), (sum, v) => sum + v, 0)
    const totalNoDeals = reduce(get(data, 'aggregate.aggregations.rows.buckets', []).map((row: any) => get(row, 'dealsCount.value', '0')), (sum, v) => sum + v, 0)
    return (
        <div className="w-full flex flex-col py-10 gap-5">
            <div className="w-full flex">
                <div className="w-1/2 min-h-[250px]">
                    {
                        loading && times(5).map((i) => {
                            const cls = classNames({
                                "h-5 bg-gray-200 animate-pulse odd:bg-gray-100 rounded w-full mb-2": true
                            })
                            return (
                                <div className={cls} />
                            )
                        })
                    }
                    {
                        !loading && <EMGeoHeatmap key={keyOfGeo} values={rows.map((r: any) => ({ key: r.key, value: get(r, keyOfGeo, 0) }))} label={get(columnsKeyToText, keyOfGeo, '')} />
                    }
                </div>
                <div className="w-1/2 min-h-[250px]">
                    {
                        loading && times(5).map((i) => {
                            const cls = classNames({
                                "h-5 bg-gray-200 animate-pulse odd:bg-gray-100 rounded w-full mb-2": true
                            })
                            return (
                                <div className={cls} />
                            )
                        })
                    }
                    {
                        !loading && <EMGeoTablePieChart key={keyOfGeo} values={rows.map((r: any) => ({ key: r.key, value: get(r, keyOfGeo, 0) }))} label={keyOfGeo} />
                    }
                </div>
            </div>
            <table className="w-full text-base table-auto">
                <thead>
                    <tr className="">
                        <th className={thcls}>{pivotLabel}</th>
                        <th className={thcls}>{columnsKeyToText['sum.value']}</th>
                        <th className={thcls}>{columnsKeyToText['orgCount.value']}</th>
                        <th className={thcls}>{columnsKeyToText['dealsCount.value']}</th>
                        <th className={thcls}>{columnsKeyToText['max.value']}</th>
                        <th className={thcls}>{columnsKeyToText['avg.value']}</th>
                    </tr>
                </thead>
                {
                    !loading && get(data, 'aggregate.aggregations.rows.buckets', []).map((row: any, index: number) => {
                        const classes = classNames({
                            "px-2.5 py-1.5 text-left": true,
                            "group-hover/highlight:bg-ab-lime": true,
                            "cursor-pointer": true
                        })
                        return (
                            <tr key={row.key} className="odd:bg-[#f3f4f6] group/highlight">
                                <td className={classes}>{row.key}</td>
                                <td className={classes} onMouseEnter={(ev) => {
                                    setKeyOfGeo('sum.value')
                                }} onClick={(ev) => {
                                    setKeyOfGeo('sum.value')
                                }}>
                                    {numeral(get(row, 'sum.value', '0')).format('$0,0')} <span className="text-sm">({(get(row, 'sum.value', 0) / totalValueAmount * 100).toFixed(1)}%)</span>
                                </td>
                                <td className={classes} onMouseEnter={(ev) => {
                                    setKeyOfGeo('orgCount.value')
                                }} onClick={(ev) => {
                                    setKeyOfGeo('orgCount.value')
                                }}>{numeral(get(row, 'orgCount.value', '-')).format('0')} <span className="text-sm">({(get(row, 'orgCount.value', 0) / totalNoCompanies * 100).toFixed(1)}%)</span></td>
                                <td className={classes} onMouseEnter={(ev) => {
                                    setKeyOfGeo('dealsCount.value')
                                }} onClick={(ev) => {
                                    setKeyOfGeo('dealsCount.value')
                                }}>{numeral(get(row, 'dealsCount.value', '-')).format('0')} <span className="text-sm">({(get(row, 'dealsCount.value', 0) / totalNoDeals * 100).toFixed(1)}%)</span></td>
                                <td className={classes} onMouseEnter={(ev) => {
                                    setKeyOfGeo('max.value')
                                }} onClick={(ev) => {
                                    setKeyOfGeo('max.value')
                                }}>{numeral(get(row, 'max.value', '0')).format('$0,0')}</td>
                                <td className={classes} onMouseEnter={(ev) => {
                                    setKeyOfGeo('avg.value')
                                }} onClick={(ev) => {
                                    setKeyOfGeo('avg.value')
                                }}>{numeral(get(row, 'avg.value', '0')).format('$0,0')}</td>
                            </tr>
                        )
                    })
                }
            </table>
            {
                loading && times(5).map((i) => {
                    const cls = classNames({
                        "h-5 bg-gray-200 animate-pulse odd:bg-gray-100 rounded w-full mb-2": true
                    })
                    return (
                        <div className={cls} />
                    )
                })
            }
        </div>
    )
}