import { get } from "lodash";
import React from "react";
import { Persona } from "../../../elements/Persona";
import { useQuery } from "@apollo/client";
import { FETCH_AGGREGATE_DATA, SEARCH_QUERY } from "../../../queries/search";


export const OrgImpactFragment = ({ org }: any) => {
    const impact = get(org, 'impact', {})
    return (
        <div className='w-full h-auto relative bg-white mb-20 fle overflow-x-auto'>
            <div className='w-full'>
                <p className='text-lg font-semibold text-left text-gray-4100 my-6'>Impact Metrics</p>
                {
                    org?.type === 'startup' && <SDGStartup org={org} />
                }
                {
                    org?.type !== "startup" && <SDGInvestors org={org} />
                }
                <p className='text-sm font-medium text-left text-gray-3400 pb-4'>
                    Impact focus
                </p>
                <div className="flex gap-4 font-medium">
                    {impact?.assistive_tech && <p className=''>Assistive technology</p>}
                    {impact?.bop && <p className=''>BOP</p>}
                    {impact?.elderly_people && <p className=' '>Elderly people</p>}
                    {impact?.employment_creation && <p className=''>Employment creation</p>}
                    {impact?.female_cofounder && <p className=''>Female co-founder</p>}
                    {impact?.female_focused && <p className=''>Female focused</p>}
                    {impact?.fifty_local && <p className=''>50% local</p>}
                    {impact?.informal_sector && <p className=''>Informal sector</p>}
                    {impact?.rural_focused && <p className=''>Rural focused</p>}
                    {impact?.youth_focused && <p className=''>Youth focused</p>}
                    {impact?.zero_emissions && <p className=''>Zero emissions</p>}
                </div>
            </div>
        </div>
    )
}


const SDGInvestors = ({ org }: any) => {
    const { data, loading, error } = useQuery(SEARCH_QUERY, {
        variables: {
            search: {
                bucket: "deals",
                size: 100,
                filters: [],
                query: {
                    filter: [
                        {
                            terms: {
                                "investors.id.keyword": [org.id]
                            }
                        }
                    ]
                }
            }
        }
    })
    console.log("deals", data)
    return (
        <>
        </>
    )
}

const SDGStartup = ({ org }: any) => {
    return (
        <>
            <p className='text-sm font-medium text-left text-gray-3400 pb-4'>
                Sustainable Development Goals (SDGs)
            </p>
            <p className='w-full flex flex-col md:flex-row gap-8 pb-6'>
                {
                    get(org, 'sdgs', []).length < 1 && (
                        <p>No records available</p>
                    )
                }
                {
                    get(org, 'sdgs', []).map((sdg: any) => {
                        return <Persona text={sdg.name} imageURL={sdg.img_pic} size="xl" />
                    })
                }
            </p>
        </>
    )
}