import { Mercator } from "@visx/geo";
import { ParentSize } from '@visx/responsive';
//@ts-ignore
import numeral from "numeral"
import React, { useState } from "react"
//@ts-ignore
import * as topojson from 'topojson-client';
import countriesDBArray from "./../Organizations/components/Maps/countries.json"
import topology from './../Organizations/components/africa-topo.json';
import { RxBackpack, RxMaskOff, RxPieChart, RxRocket } from "react-icons/rx";
import { first, get, has, keyBy, sumBy } from "lodash";
import { useDataContainerContext } from "../../elements/DataContainer";
import { StatsCardGQL } from "../../elements/GraphQL/StatsCardGQL";
import { SimpleProgressBar } from "../../elements/Progress/SimpleProgressBar";
import { CountryMap, FeatureShape } from "../Organizations/components/Maps";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { Tabs } from "../../elements/Tabs";
import { useQuery } from "@apollo/client";
import { FETCH_AGGREGATE_DATA, SEARCH_QUERY } from "../../queries/search";
import { Link } from "react-router-dom";
import { PersonaHovering } from "../../elements/Persona";
import { OrgCard } from "../../components/OrganizationCards";

const countriesByKey = keyBy(countriesDBArray, 'alpha2')
const countriesByName = keyBy(countriesDBArray, 'name')

export interface IStatCard {
    bgColor: string;
    iconColor?: string;
    title: string;
    value: any;
    icon?: JSX.Element
}
export const StatCard = ({ ...props }: IStatCard) => {
    const { title, iconColor, icon, value, bgColor } = props
    return (
        <div className="rounded-lg py-2 px-4 text-nowrap flex-1" style={{ backgroundColor: bgColor }}>
            <p className="text-ab-darkgreen text-xs">{title}</p>
            <p className="text-3xl flex gap-3 py-2 items-center font-semibold">{icon && (<span className="text-xl" style={{ color: iconColor }}>{icon}</span>)} {value}</p>
        </div>
    )
}

const getCountriesSelection = (filtersQuery: any): string[] => {
    const key = "offices.country.name.keyword"
    const countries = get(filtersQuery.filter((v: any, k: any) => v.terms && has(v.terms, key)).map((v: any) => v), "0.terms['offices.country.name.keyword']", [])
    return countries || [];
}

const getCountriesSelectionAlpha2Code = (filterQuery: any): string[] => {
    const countries = getCountriesSelection(filterQuery)
    return countries || []
}


export const DealsMapContainer = () => {
    const { filtersQuery } = useDataContainerContext()
    const countries = getCountriesSelectionAlpha2Code(filtersQuery)
    const activeCountry = first(countries)

    return (
        <div className="w-full h-full flex grow">
            <div className="flex grow">
                <div className="min-w-[430px] w-1/3 border-r shadow-md">
                    <div className="flex flex-col grow h-full">
                        <div className="container-ab py-4">
                            {
                                activeCountry && (
                                    <div className="flex items-center gap-8 pb-8">
                                        <h1 className="text-2xl font-semibold flex items-center gap-2">
                                            <FlagIcon code={get(countriesByName, `${activeCountry}.alpha2`, '') as FlagIconCode} className="rounded-sm" /> {activeCountry}
                                        </h1>
                                    </div>
                                )
                            }
                            {
                                !activeCountry && (
                                    <div className="flex items-center gap-8 pb-8">
                                        <h1 className="text-2xl font-light text-gray-400">Select a country...</h1>
                                    </div>
                                )
                            }
                            <div className="grid grid-cols-2 gap-4 pb-8">
                                <StatsCardGQL root="count.value"
                                    stat={{ title: "Number of deals", bgColor: "#fef3d8", value: "-", iconColor: "#6ba9a1", icon: <RxRocket /> }}
                                    queryVariables={{
                                        agg: {
                                            bucket: "deals",
                                            query: {
                                                filter: filtersQuery,
                                            },
                                            list: [
                                                {
                                                    key: "count",
                                                    type: "cardinality",
                                                    params: {
                                                        field: "id.keyword",
                                                    },
                                                },
                                            ],
                                        }
                                    }}
                                />
                                <StatsCardGQL root="sum.value"
                                    stat={{ title: "Total amount disclosed", bgColor: "#fef3d8", value: "-", iconColor: "#6ba9a1", icon: <RxRocket /> }}
                                    //@ts-ignore
                                    format={(value: any) => numeral(value).format('($ 0.00 a)')}
                                    queryVariables={{
                                        agg: {
                                            bucket: "deals",
                                            query: {
                                                filter: filtersQuery,
                                            },
                                            list: [
                                                {
                                                    key: "sum",
                                                    type: "sum",
                                                    params: {
                                                        field: "amount",
                                                    },
                                                },
                                            ],
                                        }
                                    }}
                                />
                                <StatsCardGQL root="count.value"
                                    stat={{ title: "Categories", bgColor: "#d9f5ec", value: "-", iconColor: "#00bb7d", icon: <RxMaskOff /> }}
                                    queryVariables={{
                                        agg: {
                                            bucket: "deals",
                                            query: {
                                                filter: filtersQuery,
                                            },
                                            list: [
                                                {
                                                    key: "count",
                                                    type: "cardinality",
                                                    params: {
                                                        field: "target.sectors.id.keyword",
                                                    },
                                                },
                                            ],
                                        }
                                    }}
                                />
                                <StatsCardGQL root="count.value"
                                    stat={{ title: "Products", bgColor: "#f1ffe9", iconColor: "#9ce56d", icon: <RxBackpack />, value: "-" }}
                                    queryVariables={{
                                        agg: {
                                            bucket: "deals",
                                            query: {
                                                filter: filtersQuery,
                                            },
                                            list: [
                                                {
                                                    key: "count",
                                                    type: "cardinality",
                                                    params: {
                                                        field: "target.sub_sectors.id.keyword",
                                                    },
                                                },
                                            ],
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <CountryDetailsTabs />
                    </div>
                </div>
                <div className="h-full min-h-0 grow max-h-[calc(100vh-120px)]">
                    <ParentSize debounceTime={1000}>
                        {(parent) => {
                            //@ts-ignore
                            return <VISXMap countries={countries} width={parent.width} height={parent.height} />
                        }}
                    </ParentSize>
                </div>
            </div>
        </div>
    )
}


const CountryDetailsTabs = () => {
    const { filtersQuery } = useDataContainerContext()

    const { data, loading, refetch } = useQuery(FETCH_AGGREGATE_DATA, {
        variables: {
            aggs: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "categories",
                        type: "terms",
                        params: {
                            field: "target.agbase_category.name.keyword",
                            size: 5,
                        },
                    },
                    {
                        key: "valuechain",
                        type: "terms",
                        params: {
                            field: "target.agbase_value_chain.name.keyword",
                            size: 5,
                        },
                    },
                ],
            },
        },
        fetchPolicy: "cache-and-network",
    });

    return (
        <div className="grow h-full">
            <Tabs index="categories" asClassName="bg-[#fafafa] w-full h-full px-8" tabs={{
                categories: {
                    title: "Top categories", key: "categories", pane: <CountryStatsWidget data={get(data, 'aggregate.aggregations.categories')} />
                },
                valuechain: {
                    title: "Value chains", key: "valuechain", pane: <CountryStatsWidget data={get(data, 'aggregate.aggregations.valuechain')} />
                },
                leading: {
                    title: "Top 5 Highest deals", key: "leading", pane: <CountryLeadingCompaniesWidget />
                },
            }} qs="cd" />
        </div>
    )
}

export const CountryLeadingCompaniesWidget = () => {
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(SEARCH_QUERY, {
        variables: {
            search: {
                bucket: "deals",
                sort: [{ key: "amount", dir: "desc" }],
                size: 5,
                query: {
                    filter: filtersQuery
                }
            }
        }
    })
    return (
        <div className="flex gap-4 flex-col py-6">
            {
                get(data, 'search.items', []).map((item: any) => {
                    return (
                        <div className="w-full flex items-center">
                            <Link to={["/organizations", item.target.id].join("/")} className="block w-full">
                                <PersonaHovering
                                    text={item.target.name} imageURL={item.target.profile_image_url}
                                    secondaryText={`${get(item, 'target.agbase_category[0].name', '')}`}
                                >
                                    <OrgCard id={item.target.id} />
                                </PersonaHovering>
                            </Link>
                            <p className="text-lg font-medium">{numeral(item.amount).format("$(0.0a)")}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export const CountryStatsWidget = ({ data }: any) => {
    const sum = sumBy(get(data, 'buckets', []), 'doc_count') + get(data, 'sum_other_doc_count', 0)
    return (
        <div className="grid gap-8 py-6" style={{ gridTemplateColumns: "3fr 3fr" }}>
            {get(data, 'buckets', []).map((i: any) => {
                return (
                    <>
                        <p className="uppercase text-ab-darkgreen font-semibold line-clamp-2">{i.key}</p>
                        <SimpleProgressBar key={i.key} percentage={Math.round(i.doc_count / sum * 100)} />
                    </>
                )
            })}
        </div>
    )
}

export type GeoMercatorProps = {
    width: number;
    height: number;
    events?: boolean;
};

//@ts-ignore
const world = topojson.feature(topology, topology.objects.countries);

export const VISXMap = function ({ width, height, countries = [], events = false }: any) {
    const [center, setCenter] = useState([
        20, 18.20682
    ])

    const { addFilterAndApply, removeFilterAndApply } = useDataContainerContext()

    const onAdd = (feature: any) => {
        const country = get(countriesByKey, feature.properties.iso_a2, {})
        
        addFilterAndApply({
            type: "select-or",
            key: "target.agbase_offices.country.name.keyword",
            value: [get(country, 'name', '')]
        })

    }
    const onRemove = (feature: any) => {
        const list = countries.filter((f: string) => f !== get(countriesByKey, `${feature.properties.iso_a2}.name`, ''))

        if (list.length < 1) {
            removeFilterAndApply({
                type: "select-or",
                key: "target.agbase_offices.country.name.keyword",
                value: list
            })
        } else {
            addFilterAndApply({
                type: "select-or",
                key: "target.agbase_offices.country.name.keyword",
                value: list
            })
        }

    }
    return width < 10 ? null : (
        <svg width={width} height={height}>
            <rect x={0} y={0} width={width} height={height} fill={'#d4e8e6'} rx={0} />
            <defs>
                <pattern id="pattern_wpxtW" patternUnits="userSpaceOnUse" width="5.5" height="5.5" patternTransform="rotate(45)">
                    <line x1="0" y="0" x2="0" y2="5.5" stroke="#00BB7D" strokeWidth="2" />
                </pattern>
            </defs>
            <Mercator<FeatureShape>
                //@ts-ignore
                data={world.features}
                //@ts-ignore
                center={center}
                scale={500}
            >
                {(mercator) => (
                    <g>
                        {mercator.features.map(({ feature, path }, i) => {
                            //@ts-ignore
                            const country = get(countriesByKey, feature?.properties?.iso_a2, {})

                            //@ts-ignore
                            if (countries.includes(country?.name || "")) {
                                return <CountryMap
                                    //@ts-ignore
                                    key={feature.properties.iso_a2}
                                    path={path} feature={feature}
                                    onClick={() => onRemove(feature)}
                                    fill="url(#pattern_wpxtW)" stroke={"#02BA7E"} />
                            }
                            return (
                                <CountryMap path={path} feature={feature}
                                    //@ts-ignore
                                    onClick={() => onAdd(feature)} key={feature.properties.iso_a2}
                                />
                            )
                        })}
                    </g>
                )}
            </Mercator >
        </svg >
    );
}
