import React from "react";
//@ts-ignore
import numeral from "numeral"
import { useDataContainerContext } from "../../../elements/DataContainer";
import { StatsCardGQL } from "../../../elements/GraphQL/StatsCardGQL";
import { BUCKET_ORGS } from "../../../constants";

export const OrgsOverviewChartBlock = () => {
    const { filtersQuery } = useDataContainerContext()

    return (
        <div className="w-full">
            <div className="w-full flex flex-wrap items-center py-4 gap-4">
                <div className="flex flex-wrap items-center w-full gap-4">
                    <StatsCardGQL root="sum.value" stat={{ title: "Total funding captured", bgColor: "#e0eeec", value: "-" }}
                        format={(value: any) => numeral(value).format('($ 0.00 a)')}
                        queryVariables={{
                            agg: {
                                bucket: BUCKET_ORGS,
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "sum",
                                        type: "sum",
                                        params: {
                                            field: "agg_deals.amount",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="count.value" stat={{ title: "Organizations", bgColor: "#fef3d8", value: "-" }}
                        queryVariables={{
                            agg: {
                                bucket: BUCKET_ORGS,
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "count",
                                        type: "cardinality",
                                        params: {
                                            field: "id.keyword",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="count.value" stat={{ title: "Categories", bgColor: "#d9f5ec", value: "-" }}
                        queryVariables={{
                            agg: {
                                bucket: BUCKET_ORGS,
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "count",
                                        type: "cardinality",
                                        params: {
                                            field: "sectors.id.keyword",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="count.value" stat={{ title: "Products", bgColor: "#f1ffe9", value: "-" }}
                        queryVariables={{
                            agg: {
                                bucket: BUCKET_ORGS,
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "count",
                                        type: "cardinality",
                                        params: {
                                            field: "sub_sectors.id.keyword",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                </div>
                {/* <div className="w-full flex flex-col gap-2">
                    <p className="text-xs">Total disclosed funding since 2022</p>
                    <SimpleProgressBarWithNumbers />
                    <p className="flex text-xs gap-1 items-center">See list of deals since 2022 <RxCaretRight /></p>
                </div> */}
            </div>
        </div>
    )
}


