import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";
import { SpinnerView } from "../elements/Spinners";
import { LOGOUT } from "../queries/auth";

export interface Session {
    user?: User;
    initialized: boolean;
    id: string;
}

export interface User {
    id: string;
    email: string;
    first_name: string;
    other_names: string;
    initialized: boolean;
}

export interface AuthContextType {
    session?: Session;
    loading: boolean;
    error?: any;
    check: any;
    setSession: any;
    // login: (email: string, password: string) => void;
    // signUp: (email: string, name: string, password: string) => void;
    logout: any;
    isLoggingOut: boolean;
}

const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);

const CHECK_AUTH = gql`
query auth {
    auth {
        id
        user {
            id
            first_name
            other_names
            is_freemium
            profile_image_url
            email
            metadata {
                id
                preferred_countries {
                    id
                    name
                }
                preferred_products {
                    id
                    name
                }
                preferred_sectors {
                    id
                    name
                }
            }
        }
    }
}
`

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
    const [session, setSession] = useState<Session>({
        id: "", initialized: false, user: undefined
    });

    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false)
    const [auth, { loading, data, error, refetch }] = useLazyQuery(CHECK_AUTH);
    const [requestLogout, { }] = useMutation(LOGOUT)

    const navigate = useNavigate();

    const check = (redirect: string) => {
        try {
            return auth()
                .then(() => {
                    if (error === undefined) {
                        setSession({ ...data.auth, initialized: true });
                        navigate(redirect);
                        return true;
                    } else {
                        return false;
                    }
                })
        } catch (e) {
            // error state
        }
    }

    const logout = () => {
        setIsLoggingOut(true)
        requestLogout().then(() => {
            window.location.href = "/"
            setIsLoggingOut(false)
        })
    }

    useEffect(() => {
        auth().then((r) => {
            setSession({ ...r.data?.auth, initialized: true })
        })
    }, []);

    // console.log("session", session, "loading", loading);
    return (
        <AuthContext.Provider value={{ session, error, loading, check, setSession, logout, isLoggingOut }}>
            {loading && !session.initialized && (
                <SpinnerView message="Checking current session.." />
            )}
            {
                isLoggingOut && (
                    <SpinnerView message="Logging out..." />
                )
            }
            {!loading && session.initialized && children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}