import { Group } from "@visx/group";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Pie } from "@visx/shape";
import { Text } from "@visx/text";
import { scaleOrdinal } from "d3";
import { reduce } from "lodash";
import numeral from "numeral";
import { useEffect, useState } from "react";

export type TEMGeoTablePieChart = {
    values: { key: string, value: number }[]
    events?: boolean;
    label: string
}

export const EMGeoTablePieChart = ({ events = false, values = [], label = "" }: TEMGeoTablePieChart) => {
    const [active, setActive] = useState<any>();
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };
    const ordinalColor2Scale = scaleLinear({
        domain: [
            0,
            1,
            Math.max(...values.map((v) => v.value)),
        ],
        range: ['#eee', '#AACAAB', '#015659']
    })

    useEffect(() => {
        if (!active) {
            setActive(values[0])
        }
    }, [active])
    const height = 300
    const totalValue = reduce(values, ((sum, v) => v.value + sum), 0)
    return (
        <ParentSize>
            {(parent) => {
                const innerWidth = parent.width - margin.left - margin.right;
                const innerHeight = height - margin.top - margin.bottom;
                const radius = Math.min(innerWidth, innerHeight) / 2;
                const centerY = innerHeight / 2;
                const centerX = innerWidth / 2;
                const top = centerY + margin.top;
                const left = centerX + margin.left;
                return (
                    <div className="relative">
                        <svg width={parent.width} height={height}>
                            <Group top={top} left={left}>
                                <Pie
                                    data={values}
                                    pieValue={(data: any) => data?.value}
                                    outerRadius={radius}
                                    innerRadius={({ data }) => {
                                        return (active && data.key === active.key) ? (radius / 1.5) : (radius / 1.25);
                                    }}
                                    cornerRadius={3}
                                    padAngle={0.005}
                                >
                                    {(pie) => {
                                        return pie.arcs.map((arc, index) => {
                                            //@ts-ignore
                                            const { key, value } = arc.data;
                                            const [centroidX, centroidY] = pie.path.centroid(arc);
                                            const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                                            return (
                                                <g
                                                    key={`arc-${key}-${index}`}
                                                    onMouseEnter={() => setActive(arc.data)}
                                                    // onMouseLeave={() => setActive(null)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <path d={pie.path(arc) || ""} fill={ordinalColor2Scale(value)} rx={5} />
                                                    {/* {
                                                        hasSpaceForLabel && (
                                                            <Text
                                                                fill="black"
                                                                x={centroidX * 1.5}
                                                                y={centroidY * 1.5}
                                                                dy=".33em"
                                                                fontSize={12}
                                                                textAnchor="middle"
                                                                pointerEvents="none"
                                                            >
                                                                {(numeral(arc.data.value).format('($0.00 a)') as string).toUpperCase()}
                                                            </Text>
                                                        )
                                                    } */}

                                                </g>
                                            );
                                        });
                                    }}
                                </Pie>
                                {active && (
                                    <>
                                        <Text textAnchor="middle" fill="#111" fontSize={28} dy={0}>
                                            {(numeral(active.value).format('($0.00 a)') as string).toUpperCase()}
                                        </Text>

                                        <Text
                                            textAnchor="middle"
                                            fill={active.color}
                                            fontSize={14}
                                            dy={20}
                                        >
                                            {active.key}
                                        </Text>
                                        <Text
                                            textAnchor="middle"
                                            fill={active.color}
                                            fontSize={14}
                                            dy={40}
                                        >
                                            {(numeral(active.value / totalValue).format('(0.00 %)') as string).toUpperCase()}
                                        </Text>
                                    </>
                                )}
                            </Group>
                        </svg>
                    </div>
                )
            }}

        </ParentSize>
    )
}