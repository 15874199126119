import React, { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useInterval } from "usehooks-ts";
//import { useAuth } from "../../context/AuthContext";
import { useLazyQuery } from "@apollo/client";
import { Spinner } from "../../elements/Spinners";
import { LoginForm } from "./fragments/LoginForm";
import { LoginLayout } from "./LoginLayout";
import { CHECK_AUTH } from "../../queries/auth";

const GOOGLE_AUTH = process.env.REACT_APP_GOOGLE_AUTH_URL

export const SignInUpContainer = () => {
    //    const { check } = useAuth();
    const [state, setState] = useState({
        count: 0, isLogging: false, isRegistering: false, isPopUpOpen: false, popUpProxy: undefined
    })
    const [auth, { loading, data, error, refetch }] = useLazyQuery(CHECK_AUTH);

    useInterval(() => {
        setState({ ...state, count: state.count + 1 })
        auth().then((res) => {
            if (res.error === undefined) {
                window.location.reload()
            }
        })
    }, state.isLogging ? 1000 : null)

    const handleGoogleLogin = (ev: any) => {
        ev.preventDefault();
        const w = window.open(GOOGLE_AUTH, "_blank", "popup=true width=500 height=600");
        //@ts-ignore
        setState({ ...state, isLogging: true, count: 1, popUpProxy: w, isPopUpOpen: true });
    };

    useInterval(() => {
        //@ts-ignore
        if (typeof state.popUpProxy != "undefined" && state.popUpProxy?.closed) {
            setState({ ...state, isLogging: false, popUpProxy: undefined, isPopUpOpen: false })
            window.location.href = "/"
        }
    }, state.isPopUpOpen ? 1000 : null)

    useEffect(() => {
        auth().then((res) => {
            if (res.error === undefined) {
                window.location.reload()
            }
        })
    }, [state.isPopUpOpen])


    return (
        <LoginLayout>
            <div className="w-full lg:w-1/3">
                <div className="py-8">
                    <p className="text-xl font-medium pb-2">Sign In</p>
                    <div className='flex flex-col gap-4'>
                        <p className="text-gray-500">
                            Don't have an account? <Link to="/sign-up" className="text-ab-lightdarkgreen font-medium">Sign up</Link>
                        </p>
                    </div>
                    {state.isLogging && (<Spinner message={`Logging in...`} />)}
                    {
                        !state.isRegistering && (
                            <>
                                <LoginForm onSuccess={() => {
                                    setState({ ...state, isLogging: true })
                                }} />
                            </>
                        )
                    }
                    <div className='w-full h-[19px] flex justify-between items-center mt-4'>
                        <div className='w-full h-px bg-[#ebeaed]' />
                        <div className='px-3 text-sm font-semibold text-left text-gray-4100'>or</div>
                        <div className='w-full h-px bg-[#ebeaed]' />
                    </div>
                    <div className='flex flex-col gap-4 mt-6'>
                        <button className="border rounded-lg flex items-center justify-center gap-4 font-semibold py-2 border-black" onClick={handleGoogleLogin}><FaGoogle /> Sign in with Google</button>
                    </div>
                    <p className='text-xs text-left mt-6'>
                        <span className='text-sm font-medium text-left text-gray-400'>Protected by reCAPTCHA and subject to the Google </span>
                        <Link to='/terms-conditions' className='text-primary'>
                            <span className='text-sm font-semibold text-left text-gray-4100'>Privacy Policy </span>
                            <span className='text-sm font-medium text-left text-gray-3400'>and </span>
                            <span className='text-sm font-semibold text-left text-gray-4100'>Terms of Service</span>
                            <span className='text-sm font-medium text-left text-gray-3400'>.</span>
                        </Link>
                    </p>
                </div>
            </div>
            <div className="hidden lg:w-2/3">

            </div>
        </LoginLayout>
    )
}