import React, { MouseEvent, MouseEventHandler } from "react";
import { Group } from "@visx/group";
import { Treemap, stratify } from "@visx/hierarchy";
import { ParentSize } from "@visx/responsive";
import { hierarchy, treemapSquarify } from "@visx/hierarchy";
import { scaleLinear } from "@visx/scale";
import { gql, useQuery } from "@apollo/client";
//@ts-ignore
import numeral from "numeral"
import { useDataContainerContext } from "../../../../elements/DataContainer";
import { find, get } from "lodash";
import { Text } from "@visx/text";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { SpinnerLogo } from "../../../../elements/Spinners/SpinnerLogo";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { OrgCard } from "../../../../components/OrganizationCards";
import { BUCKET_ORGS } from "../../../../constants";

export type TTreeMapDataProps = {

}

export type TTreeMapProps = {
    data: any;
}

export const EcosystemTreeMap = () => {
    const { filtersQuery } = useDataContainerContext()

    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }
    }
`, {
        variables: {
            agg: {
                bucket: BUCKET_ORGS,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "count",
                        type: "sum",
                        params: {
                            field: "total_funding"
                        }
                    },
                    {
                        key: "agg",
                        type: "terms",
                        params: {
                            field: "name.keyword",
                            size: 25,
                            shard_size: 1000,
                            order: {
                                max: "desc"
                            }
                        },
                        extra: {
                            aggs: {
                                max: {
                                    max: {
                                        field: 'total_funding',
                                    },
                                },
                                top_entities: {
                                    top_hits: {
                                        sort: [
                                            {
                                                total_funding: {
                                                    order: "desc"
                                                }
                                            }
                                        ],
                                        _source: {
                                            includes: ["id", "name", "total_funding"]
                                        },
                                        size: 5
                                    }
                                }
                            },
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const total_funding = get(data, 'aggregate.aggregations.count.value', 0)
    const dataset = get(data, 'aggregate.aggregations.agg.buckets', []).map((item: any) => {
        return ({
            value: item.max.value,
            key: item.key,
            id: get(item, 'top_entities.hits.hits[0]._source.id', null),
            parent: "orgs"
        })
    })

    dataset.push({ value: 0, key: "orgs", parent: null })
    dataset.push({
        value: total_funding - dataset.reduce((acc: number, item: any) => acc += item.value, 0),
        key: "Other companies",
        parent: "orgs"
    })

    const root = hierarchy(
        stratify().id((d: any) => d.key).parentId((d: any) => d.parent)(dataset).sum((d: any) => d.value)
    );

    const colorScale = scaleLinear({
        domain: [Math.min(...dataset.map((d: any) => d.value)), Math.max(...dataset.map((d: any) => d.value))],
        range: ['#eefcfa', '#d2f6f2'],
        zero: true
        // range: ['#d2f6f2', '#02BA7E']
    });

    const height = 300

    return (
        <>
            <ParentSize>
                {(parent) => {
                    const width = parent.width
                    const margin = { top: 0, left: 0, right: 0, bottom: 0 }
                    const xMax = width - margin.left - margin.right;
                    const yMax = height - margin.top - margin.bottom;

                    return (
                        <svg width={width} height={height} fill="#eee">
                            <Treemap<typeof data>
                                top={margin.top}
                                root={root}
                                size={[xMax, yMax]}
                                tile={treemapSquarify}
                                round
                            >
                                {(treemap) => (
                                    <Group rx={14}>
                                        {treemap
                                            .descendants()
                                            .map((node, i) => {
                                                return (
                                                    <Node
                                                        node={node} margin={margin}
                                                        index={i} fill={colorScale(node.data.value || 0)} dataset={dataset}
                                                    />
                                                )
                                            })}
                                    </Group>
                                )}
                            </Treemap>
                            {
                                loading && (
                                    <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                        Loading chart...
                                    </Text>
                                )
                            }
                        </svg>
                    )
                }}
            </ParentSize>
        </>
    )
}

const Node = ({ node, dataset, margin, fill, index }: any) => {
    const nodeWidth = node.x1 - node.x0;
    const nodeHeight = node.y1 - node.y0;
    const id = get(find(dataset, { key: node.data.id }), 'id', null)
    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    // If you don't want to use a Portal, simply replace `TooltipInPortal` below with
    // `Tooltip` or `TooltipWithBounds` and remove `containerRef`
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // use TooltipWithBounds
        detectBounds: true,
        // when tooltip containers are scrolled, this will correctly update the Tooltip position
        scroll: true,
    })
    const handleMouseOver = (event: MouseEvent<SVGAElement>) => {
        showTooltip({
            tooltipLeft: event.clientX,
            tooltipTop: event.clientY,
        });
    };
    const navigate = useNavigate()

    return (
        <Group
            key={`node-${node.data.id}`}
            top={node.y0 + margin.top}
            left={node.x0 + margin.left}
            textAnchor="middle"
            className={classNames({
                "cursor-pointer": id !== null,
                "hover:fill-ab-lime": true
            })}
            fill={fill}
            onClick={(ev) => {
                ev.preventDefault()
                if (id !== null) {
                    navigate(['/organizations', id].join("/"))
                }
            }}
            ref={containerRef}
            onMouseOver={handleMouseOver}
            onMouseOut={hideTooltip}
        >
            {
                node.data.depth > 0 && (
                    <>
                        <rect
                            width={nodeWidth}
                            height={nodeHeight}
                            stroke={"#ccc"}
                            strokeWidth={1}
                        />
                        {
                            (index <= 8 || id === null) && (
                                <>
                                    <Text
                                        fontSize={14}
                                        y={nodeHeight / 2}
                                        x={nodeWidth / 2}
                                        textAnchor="middle"
                                        verticalAnchor="middle"
                                        width={nodeWidth / 1.1}
                                        fill={'#015659'}
                                        className="font-light"
                                    >
                                        {node.data.id}
                                    </Text>
                                </>
                            )
                        }
                        <Text
                            fontSize={12}
                            y={10}
                            x={10}
                            textAnchor="start"
                            verticalAnchor="start"
                            width={nodeWidth / 1.1}
                            fill={'#004447'}
                            className="font-semibold uppercase"
                        >
                            {numeral(node.data.value).format('$ (0.0 a)')}
                        </Text>
                        {tooltipOpen && (
                            <TooltipInPortal
                                // set this to random so it correctly updates with parent bounds
                                key={Math.random()}
                                top={tooltipTop}
                                left={tooltipLeft}
                            >
                                {id && (<OrgCard id={id} />)}
                                {!id && (
                                    <div className="flex flex-col gap-2 p-2">
                                        <p className="font-semibold">{node.data.id}</p>
                                        <p>Total funding: {numeral(node.data.value).format('$ (0.0 a)')}</p>
                                    </div>
                                )}
                            </TooltipInPortal>
                        )}
                    </>
                )
            }
        </Group>
    );
}