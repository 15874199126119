import React, { useEffect, useState } from "react"
//@ts-ignore
import numeral from "numeral"
import { useDataContainerContext } from "../../../elements/DataContainer"
import { gql, useQuery } from "@apollo/client";
import { get, sortBy } from "lodash";
import { AreaSeries, Axis, GlyphSeries, Grid, Tooltip, XYChart } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import { format } from "date-fns";
import { curveCatmullRom } from "@visx/curve";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";
import { scaleColors } from "../../../utils/colors";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { Text } from "@visx/text";

const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y
};

export const DealsCountChart = () => {
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }   
    }
    
    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "sums",
                        type: "date_histogram",
                        params: {
                            field: "date",
                            calendar_interval: "year",
                        },
                        extra: {
                            aggs: {
                                amount: {
                                    stats: {
                                        field: "amount",
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const values = get(data, 'aggregate.aggregations.sums.buckets', []).filter((f: any) => f.key > 1293829200000).map((f: any) => ({
        y: f.amount.count, x: format(new Date(f.key_as_string), 'y')
    }));
    const height = 350
    return (
        <div className="w-full rounded-lg my-4">
            <ParentSize>
                {(parent) => {
                    return (
                        <XYChart
                            height={height} width={parent.width}
                            xScale={{ type: "band" }} yScale={{ type: "linear" }}
                            margin={{ bottom: 25, top: 50, left: 25, right: 0 }}
                        >
                            <Grid columns={false} lineStyle={{ stroke: "#d7dbdb", strokeDasharray: "1,5" }} />
                            <Axis orientation="bottom" hideAxisLine hideZero />
                            <Axis
                                orientation="left" hideAxisLine
                                hideZero
                                numTicks={5}
                            />
                            <AreaSeries
                                curve={curveCatmullRom}
                                dataKey="line"
                                strokeWidth="0.5"
                                fillOpacity={0.15}
                                lineProps={{
                                    stroke: "#fbd87d",
                                    strokeWidth: 1
                                }}
                                stroke="#08a06d" fill="#fbd87d"
                                data={values} {...accessors}
                            />
                            <Tooltip
                                showVerticalCrosshair
                                showSeriesGlyphs
                                snapTooltipToDatumX
                                renderTooltip={({ tooltipData, colorScale }) => (
                                    <div className="text-ab-darkgreen font-light px-2 py-1">
                                        <span className="uppercase">{accessors.yAccessor(tooltipData?.nearestDatum?.datum)}</span> deals in {accessors.xAccessor(tooltipData?.nearestDatum?.datum)}
                                    </div>
                                )}
                            />

                            {
                                loading && (
                                    <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                        Loading chart...
                                    </Text>
                                )
                            }
                            {/* <Annotation
                                dataKey="line"
                                datum={maxBy(values1, 'y') as{}}
                            >
                                <AnnotationLabel
                                    title={numeral(datum.y).format('($ 0.00 a)')}
                                    // subtitle={}
                                    showAnchorLine={false}
                                    backgroundFill="rgba(0,150,150,0.1)"
                                />
                                <AnnotationConnector />
                            </Annotation> */}

                        </XYChart>
                    )
                }}
            </ParentSize>
        </div>
    )
}

export const DealsCountContainer = () => {
    return (
        <div className="flex flex-col md:flex-row w-full">
            <div className="w-2/3">
                <DealsCountChart />
            </div>
            <div className="w-1/3">
                <FundingStageCountPie />
            </div>
        </div>
    )
}

export const DealsInvestmentChartContainer = () => {
    return (
        <div className="flex flex-col md:flex-row w-full">
            <div className="w-2/3">
                <DealsInvestmentChart />
            </div>
            <div className="w-1/3">
                <FundingStageAmountPie />
            </div>
        </div>
    )
}

export const DealsInvestmentChart = () => {
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }   
    }
    
    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "sums",
                        type: "date_histogram",
                        params: {
                            field: "date",
                            calendar_interval: "year",
                        },
                        extra: {
                            aggs: {
                                amount: {
                                    stats: {
                                        field: "amount",
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const values1 = get(data, 'aggregate.aggregations.sums.buckets', []).filter((f: any) => f.key > 1293829200000).map((f: any) => ({
        y: get(f, 'amount.sum', 0), x: format(new Date(f.key_as_string), 'y')
    }));

    const avgs = get(data, 'aggregate.aggregations.sums.buckets', []).filter((f: any) => f.key > 1293829200000).map((f: any) => ({
        y: get(f, 'amount.max', 0), x: format(new Date(f.key_as_string), 'y')
    }));

    const height = 350
    return (
        <div className="w-full rounded-lg my-4">
            <ParentSize>
                {(parent) => {
                    return (
                        <XYChart
                            height={height} width={parent.width}
                            xScale={{ type: "band" }} yScale={{ type: "linear" }}
                            margin={{ bottom: 50, top: 50, left: 75, right: 0 }}
                        >
                            <Grid columns={false} lineStyle={{ stroke: "#d7dbdb", strokeDasharray: "1,5" }} />
                            <Axis orientation="bottom" hideAxisLine hideZero />
                            <Axis
                                orientation="left" hideAxisLine
                                hideZero
                                tickFormat={(v) => (numeral(v).format('($0.00a)') as string).toUpperCase()}
                                numTicks={5}
                            />
                            <AreaSeries
                                curve={curveCatmullRom}
                                dataKey="line"
                                strokeWidth="0.5"
                                fillOpacity={0.15}
                                lineProps={{
                                    stroke: "#00bb7d",
                                    strokeWidth: 1
                                }}
                                stroke="#08a06d" fill="#07ba7e"
                                data={values1} {...accessors}
                            />
                            <GlyphSeries
                                data={avgs}
                                dataKey="circles"
                                {...accessors}
                            />

                            <Tooltip
                                showVerticalCrosshair
                                showSeriesGlyphs
                                snapTooltipToDatumX
                                renderTooltip={({ tooltipData, colorScale }) => {
                                    const sum = accessors.yAccessor(get(tooltipData, 'datumByKey.line.datum', {}))
                                    const time = accessors.xAccessor(get(tooltipData, 'datumByKey.line.datum', {}))
                                    const max = accessors.yAccessor(get(tooltipData, 'datumByKey.circles.datum', {}))
                                    return (
                                        <div className="text-ab-darkgreen font-medium px-2 py-1">
                                            <p className="pb-2">
                                                <span className="uppercase">
                                                    {numeral((sum)).format('($ 0.00 a)')}
                                                </span> in {time}</p>
                                            <p>Highest deal {numeral((max)).format('($ 0.00 a)')} ({Math.round(max / sum * 100)}%)</p>
                                        </div>
                                    )
                                }}
                            />
                            {
                                loading && (
                                    <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                        Loading chart...
                                    </Text>
                                )
                            }
                        </XYChart>
                    )
                }}
            </ParentSize>
        </div>
    )
}

const remapFundingStage = (type: string) => {
    return [
        "Equity", "Grant", "Debt", "Award or Prize", "Acquisition",
        "Non-Equity Assistance", "Unspecified", "Convertible"
    ].includes(type) ? type : "Others";
}

export const FundingStageCountPie = () => {
    const { filtersQuery } = useDataContainerContext()
    const [active, setActive] = useState<any>();
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };

    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "stages",
                        type: "terms",
                        params: {
                            field: "type.keyword",
                            size: 7
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    useEffect(() => {
        if (!loading && data) {
            const datum = get(data, 'aggregate.aggregations.stages.buckets[0]', {})
            setActive({ key: datum.key, value: datum.doc_count })
        }
    }, [loading, data])

    if (loading) return <p>Loading...</p>

    const values = get(data, 'aggregate.aggregations.stages.buckets', [])
        .map((v: any) => ({ key: v.key, value: v.doc_count }))

    const sorted = sortBy(values, (o: any) => o.value)
    const ordinalColor2Scale = scaleOrdinal({
        domain: sorted.map((v: any) => v.key),
        range: scaleColors,
    });

    const height = 300
    return (
        <ParentSize>
            {(parent) => {
                const innerWidth = parent.width - margin.left - margin.right;
                const innerHeight = height - margin.top - margin.bottom;
                const radius = Math.min(innerWidth, innerHeight) / 2;
                const centerY = innerHeight / 2;
                const centerX = innerWidth / 2;
                const top = centerY + margin.top;
                const left = centerX + margin.left;
                return (
                    <div className="relative">
                        <svg width={parent.width} height={height}>
                            <Group top={top} left={left}>
                                <Pie
                                    data={sorted}
                                    pieValue={(data: any) => data?.value}
                                    outerRadius={radius}
                                    // innerRadius={({ data }) => {
                                    //     return radius / 1.5
                                    // }}
                                    innerRadius={({ data }) => {
                                        return (active && data.key === active.key) ? (radius / 1.5) : (radius / 1.25);
                                    }}
                                    cornerRadius={3}
                                    padAngle={0.005}
                                >
                                    {(pie) => {
                                        return pie.arcs.map((arc, index) => {
                                            //@ts-ignore
                                            const { key, value } = arc.data;
                                            const [centroidX, centroidY] = pie.path.centroid(arc);
                                            const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                                            return (
                                                <g
                                                    key={`arc-${key}-${index}`}
                                                    onMouseEnter={() => setActive(arc.data)}
                                                    // onMouseLeave={() => setActive(null)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <path d={pie.path(arc) || ""} fill={ordinalColor2Scale(value)} rx={5} />
                                                    {
                                                        hasSpaceForLabel && (
                                                            <Text
                                                                fill="white"
                                                                x={centroidX}
                                                                y={centroidY}
                                                                dy=".33em"
                                                                fontSize={14}
                                                                textAnchor="middle"
                                                                pointerEvents="none"
                                                            >
                                                                {arc.data.value}
                                                            </Text>
                                                        )
                                                    }

                                                </g>
                                            );
                                        });
                                    }}
                                </Pie>
                                {
                                    loading && (
                                        <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                            Loading chart...
                                        </Text>
                                    )
                                }
                                {active && (
                                    <>
                                        <Text textAnchor="middle" fill="#111" fontSize={36} dy={0}>
                                            {active.value}
                                        </Text>

                                        <Text
                                            textAnchor="middle"
                                            fill={active.color}
                                            fontSize={15}
                                            dy={20}
                                        >
                                            {active.key}
                                        </Text>
                                    </>
                                )}
                            </Group>
                        </svg>
                        <div className="absolute">
                            <LegendOrdinal scale={ordinalColor2Scale} labelFormat={(label) => `${label}`} >
                                {(labels) => (
                                    <div className="flex flex-wrap w-full px-8">
                                        {labels.slice(0, values.length).map((label, i) => (
                                            <LegendItem
                                                key={`legend-quantile-${i}`}
                                                margin="0 5px"
                                                onClick={() => {

                                                }}
                                            >
                                                <svg width={5} height={5}>
                                                    <rect fill={label.value} width={5} height={5} />
                                                </svg>
                                                <LegendLabel align="left" margin="0 0 0 4px">
                                                    {label.text}
                                                </LegendLabel>
                                            </LegendItem>
                                        ))}
                                    </div>
                                )}
                            </LegendOrdinal>
                        </div>
                    </div>
                )
            }}

        </ParentSize>
    )
}

export const FundingStageAmountPie = () => {
    const { filtersQuery } = useDataContainerContext()
    const [active, setActive] = useState<any>();
    const margin = { top: 20, right: 20, bottom: 20, left: 20 };

    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "stages",
                        type: "terms",
                        params: {
                            field: "type.keyword",
                        },
                        extra: {
                            aggs: {
                                amount: {
                                    stats: {
                                        field: "amount",
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    useEffect(() => {
        if (!loading && data) {
            const datum = get(data, 'aggregate.aggregations.stages.buckets[0]', {})
            setActive({ key: datum.key, value: get(datum, 'amount.sum', 0) })
        }
    }, [loading, data])

    if (loading) return <p>Loading...</p>

    const values = get(data, 'aggregate.aggregations.stages.buckets', [])
        .map((v: any) => ({ key: v.key, value: get(v, 'amount.sum', 0) }))

    const sorted = sortBy(values, (o: any) => o.value)
    const ordinalColor2Scale = scaleOrdinal({
        domain: sorted.map((v: any) => v.key),
        range: scaleColors,
    });
    const height = 300

    console.log(ordinalColor2Scale)
    return (
        <ParentSize>
            {(parent) => {
                const innerWidth = parent.width - margin.left - margin.right;
                const innerHeight = height - margin.top - margin.bottom;
                const radius = Math.min(innerWidth, innerHeight) / 2;
                const centerY = innerHeight / 2;
                const centerX = innerWidth / 2;
                const top = centerY + margin.top;
                const left = centerX + margin.left;
                return (
                    <div className="relative">
                        <svg width={parent.width} height={height}>
                            <Group top={top} left={left}>
                                <Pie
                                    data={sorted}
                                    pieValue={(data: any) => data?.value}
                                    outerRadius={radius}
                                    // innerRadius={({ data }) => {
                                    //     return radius / 1.5
                                    // }}
                                    innerRadius={({ data }) => {
                                        return (active && data.key === active.key) ? (radius / 1.5) : (radius / 1.25);
                                    }}
                                    cornerRadius={3}
                                    padAngle={0.005}
                                >
                                    {(pie) => {
                                        return pie.arcs.map((arc, index) => {
                                            //@ts-ignore
                                            const { key, value } = arc.data;
                                            const [centroidX, centroidY] = pie.path.centroid(arc);
                                            const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                                            return (
                                                <g
                                                    key={`arc-${key}-${index}`}
                                                    onMouseEnter={() => setActive(arc.data)}
                                                    // onMouseLeave={() => setActive(null)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <path d={pie.path(arc) || ""} fill={ordinalColor2Scale(value)} rx={5} />
                                                    {/* {
                                                        hasSpaceForLabel && (
                                                            <Text
                                                                fill="black"
                                                                x={centroidX * 1.5}
                                                                y={centroidY * 1.5}
                                                                dy=".33em"
                                                                fontSize={12}
                                                                textAnchor="middle"
                                                                pointerEvents="none"
                                                            >
                                                                {(numeral(arc.data.value).format('($0.00 a)') as string).toUpperCase()}
                                                            </Text>
                                                        )
                                                    } */}

                                                </g>
                                            );
                                        });
                                    }}
                                </Pie>
                                {
                                    loading && (
                                        <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                            Loading chart...
                                        </Text>
                                    )
                                }
                                {active && (
                                    <>
                                        <Text textAnchor="middle" fill="#111" fontSize={28} dy={0}>
                                            {(numeral(active.value).format('($0.00 a)') as string).toUpperCase()}
                                        </Text>

                                        <Text
                                            textAnchor="middle"
                                            fill={active.color}
                                            fontSize={14}
                                            dy={20}
                                        >
                                            {active.key}
                                        </Text>
                                    </>
                                )}
                            </Group>
                        </svg>
                        <div className="absolute">
                            <LegendOrdinal scale={ordinalColor2Scale} labelFormat={(label) => `${label}`} >
                                {(labels) => (
                                    <div className="flex flex-wrap w-full px-8">
                                        {labels.slice(0, values.length).map((label, i) => (
                                            <LegendItem
                                                key={`legend-quantile-${i}`}
                                                margin="0 5px"
                                                onClick={() => {

                                                }}
                                            >
                                                <svg width={5} height={5}>
                                                    <rect fill={label.value} width={5} height={5} />
                                                </svg>
                                                <LegendLabel align="left" margin="0 0 0 4px">
                                                    {label.text}
                                                </LegendLabel>
                                            </LegendItem>
                                        ))}
                                    </div>
                                )}
                            </LegendOrdinal>
                        </div>
                    </div>
                )
            }}

        </ParentSize>
    )
}