import { get } from "lodash";
import React from "react";
import { FiLinkedin } from "react-icons/fi";

export const OrgTeamFragment = ({ org }: { org: any }) => {
    return (
        <div className="w-full">
            <p className="text-lg font-semibold text-left text-gray-4100 my-6">Team Members</p>

            {get(org, 'employees', []).length > 0 && (
                <div className="flex gap-4">
                    {get(org, 'employees', []).map((e: any) => {
                        const name = [get(e, 'person.first_name'), get(e, 'person.other_names')].join(" ")
                        return (
                            <div>
                                <img
                                    className="w-[120px] h-[120px] mb-4 rounded-full object-contain"
                                    src={`https://ui-avatars.com/api/?background=f2f2f2&name=${name}&size=256&font-size=0.1`} alt={name}
                                />
                                <p className="text-lg font-medium text-left text-gray-4100 flex items-center gap-2">
                                    {name} {
                                        get(e, 'person.socials.linkedin', undefined) && (
                                            <a target="_blank" href={get(e, 'person.socials.linkedin', '')}><FiLinkedin /></a>
                                        )
                                    }
                                </p>
                                <p className="text-sm font-normal text-left text-gray-3400">{e.role}</p>
                            </div>
                        )
                    })}
                </div>
            )
            }
            {
                get(org, 'employees', []).length < 1 && (
                    <p className="text-sm font-normal text-left text-gray-3400">No team members recorded</p>
                )
            }
        </div>
    )
}