import React, { useState } from "react";
import { RxCaretSort, RxPaperPlane, RxTable } from "react-icons/rx";
import { DataGridGraphQL } from "../../elements/GraphQL/DataGridGQL";
import { SEARCH_QUERY } from "../../queries/search";
import { DataContainerProvider, useDataContainerContext } from "../../elements/DataContainer";
import { get, xorBy } from "lodash";
import { IDataGridHeader } from "../../elements/DataGrid/types";
import { useQuery } from "@apollo/client";
import { isPast } from "date-fns";
import { NavBar } from "../../components/NavBar";
import { DataContainerFilters } from "../../elements/DataContainer/DataContainerFilters";
import { FiSearch } from "react-icons/fi";
import Fuse from "fuse.js";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useDocumentTitle } from "usehooks-ts";
import { useProjectDocumentTitle } from "../../utils/document";

export default function EventsDirectory() {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <div className="pt-[60px] min-h-screen h-full">
                <DataContainerProvider filters={[]}>
                    <div className="w-full flex">
                        <div className="grow">
                            <EventsDirectoryPage />
                        </div>
                    </div>
                </DataContainerProvider>
            </div>
        </div>
    )
}

function EventsDirectoryPage() {
    const [isTabular, setIsTabular] = useState(false);
    const [visibleHeaders, setVisibleHeaders] = useState([] as IDataGridHeader[]);
    const [searchInput, setSearchInput] = useState("")
    const navigator = useNavigate();
    const { filtersQuery } = useDataContainerContext()
    useProjectDocumentTitle("Events Directory")

    const { data, loading, error } = useQuery(SEARCH_QUERY, {
        variables: {
            search: {
                bucket: "initiatives",
                size: 100,
                sort: [
                    {
                        key: "start_at",
                        dir: "desc",
                    },
                ],
                query: {
                    filter: filtersQuery
                },
            },
        }
    });

    const fuseOptions = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        // fieldNormWeight: 1,
        keys: [
            "title",
        ]
    };
    const fuse = (new Fuse(get(data, 'search.items', []), fuseOptions)).search(searchInput);
    const events = searchInput.length > 0 ? fuse.map((f) => f.item) : get(data, 'search.items', [])
    return (
        <div className='w-full h-full overflow-y-auto bg-white p-3 sm:p-8'>
            <div className="w-full flex">
                <div className='flex w-full items-center justify-end gap-8'>
                    <div className="relative grow">
                        <FiSearch className="absolute text-[#9ba2a3] left-3 top-2.5 w-5 h-5" />
                        <input
                            className="rounded-md border border-gray-400 py-2 px-10 w-full placeholder:text-[#9ba2a3] outline-none"
                            type="text"
                            placeholder="Search by title..."
                            onChange={(ev) => {
                                ev.preventDefault();
                                setSearchInput(ev.target.value);
                            }}
                            value={searchInput}
                        />
                    </div>
                    {/* <div className='flex items-center gap-2'>
                        <RxPaperPlane
                            onClick={() => setIsTabular(false)}
                        />
                        <RxTable
                            onClick={() => setIsTabular(true)}
                        />
                    </div> */}
                </div>
            </div>
            <div className='flex flex-col sm:flex-row sm:items-center justify-between gap-2 my-4'>
                <div className='text-lg font-normal text-left text-gray-4100'>Upcoming Events</div>
            </div>
            {isTabular ? (
                <DataGridGraphQL
                    availableColums={[]}
                    loading={false}
                    query={SEARCH_QUERY}
                    root="search"
                    property="items"
                    onColumnsChange={(value: object) => {
                        const visible = xorBy(visibleHeaders, [value], "key") as IDataGridHeader[]
                        setVisibleHeaders(visible)
                    }}
                    filters={filtersQuery}
                    variables={{
                        search: {
                            bucket: "initiatives",
                            sort: [
                                {
                                    key: "start_at",
                                    dir: "desc",
                                },
                            ],
                            query: {
                                filters: [{ term: { enabled: true } }],
                            },
                        },
                    }}
                    entityName="events"
                    options={{
                        colsWidth: "auto"
                    }}
                    headers={visibleHeaders}
                    data={[]}
                />
            ) : (
                <div className='w-full h-full overflow-y-auto mb-16'>
                    <div className='grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {events.map((event: any) => {
                            const container = classNames({
                                "w-full h-[275px] relative bg-[#fff] overflow-hidden rounded-lg border border-[#f2f2f2] cursor-pointer": true,
                                "grayscale opacity-50": isPast(new Date(event.start_at))
                            })
                            return (
                                <div
                                    className={container}
                                    onClick={() => navigator(["/events", event.id].join("/"))}>
                                    <div className='h-[120px] bg-cover bg-no-repeat bg-center rounded-t-lg'>
                                        <img src={event?.banner} alt={event?.title} className='w-full h-full object-cover' />
                                    </div>
                                    <div className='p-5'>
                                        <div className='flex justify-start items-start absolute top-5 left-5 rounded bg-card px-2 py-1'>
                                            <p className='text-xs font-normal text-left text-gray-4100 '>{event?.organizers?.map((org: any) => org?.name).join(", ")}</p>
                                        </div>
                                        <p className='text-sm font-normal text-left text-gray-4100 uppercase line-clamp-1'>{event?.title}</p>
                                        <div className='h-[60px]'>
                                            <p className='text-sm font-normal text-left text-gray-3400 line-clamp-2'>{event?.description}</p>
                                        </div>
                                        <p className='text-sm font-normal text-left text-gray-3400'>{new Date(event?.start_at).toDateString()}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div className='flex justify-center w-full mt-2'>
                        {user?.is_freemium && (
                            <p className='text-sm text-gray-4100 bg-secondary mt-2 px-2 py-1 rounded'>
                                Speak to our team to upgrade and enjoy unlimited platform access!
                            </p>
                        )}
                    </div> */}
                </div>
            )
            }
        </div >
    );
}