import { ApolloClient, InMemoryCache } from "@apollo/client";
//@ts-ignore
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Organization: {
                fields: {
                    socials: {
                        merge: true,
                    },
                },
            },
        },
    }),
    //@ts-ignore
    link: createUploadLink({
        uri: [process.env.REACT_APP_ENDPOINT, 'query'].join("/"),
        fetchOptions: {
            credentials: "include",
        },
    })
});