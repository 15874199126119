import React, { useState } from "react";
// @ts-ignore
import numeral from "numeral"
import { useDataContainerContext } from "../../elements/DataContainer";
import { useQuery } from "@apollo/client";
import { SEARCH_QUERY } from "../../queries/search";
import { get, has, truncate } from "lodash";
import { Persona } from "../../elements/Persona";
import { formatDistance } from "date-fns";
import { useDocumentTitle } from "usehooks-ts";
import { DealsOverviewCards } from "./fragments/DealsOverviewCards";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { DealsCountContainer, DealsInvestmentChartContainer } from "./fragments/DealsInvestmentChart";
import { Tabs } from "../../elements/Tabs";
import { SectionHeading } from "../../elements/Sections";
import { NumberInPerspectiveChart, NumberInPerspectiveRangeChart } from "../../components/Charts/NumbersInPerspective";
import { Link } from "react-router-dom";

const ranges = [
    { to: 10000 },
    { from: 10000, to: 100000 },
    { from: 100000, to: 500000 },
    { from: 500000, to: 1000000 },
    { from: 1000000, to: 10000000 },
    { from: 10000000 },
].map((range) => {
    let key = ""
    if (has(range, 'from') && has(range, 'to')) {
        key = `${numeral(range.from || 0).format("$(0.0a)")}-${numeral(range.to || 0).format("$(0.0a)")}`
    } else if (has(range, 'from') && !has(range, 'to')) {
        //@ts-ignore
        key = `>${numeral(range.from || 0).format("$(0.0a)")}+`
    } else if (!has(range, 'from') && has(range, 'to')) {
        key = `<${numeral(range.to || 0).format("$(0.0a)")}`
    }

    return ({
        from: range?.from,
        to: range?.to,
        key: key
    })
})

export default function DealsOverviewContainer() {
    const [isCollapsed, setIsCollapsed] = useState(false);
    useDocumentTitle("Deals overview")
    return (
        <div className="container-ab max-w-[100vw] py-2">
            <div className="relative hidden md:flex">
                <h2 className="font-semibold text-sm py-2 grow uppercase">{!isCollapsed && "Latest deals"}</h2>
                <div>
                    <button className="xl:block hidden xl:items-center bg-ab-lime text-black text-xs px-4 py-1 justify-center rounded-md" onClick={((ev) => {
                        ev.preventDefault();
                        setIsCollapsed(!isCollapsed)
                    })}>
                        {
                            isCollapsed && (
                                <>Show latest <RxCaretDown className="text-2xl" /></>
                            )
                        }
                        {
                            !isCollapsed && (
                                <>Hide <RxCaretUp className="text-2xl" /></>
                            )
                        }

                    </button>
                </div>
            </div>
            {
                !isCollapsed && (
                    <div className="hidden md:block max-w-screen">
                        <LatestDealsBlock />
                        <hr />
                    </div>
                )
            }
            <div className="w-full py-4">
                <DealsOverviewCards />
                <div className="w-full hidden md:block">
                    <SectionHeading title="Investment Deals" description="Breakdown of investments by year" />
                    <Tabs index="investments" style="pills" qs="dt" tabs={{
                        investments: { key: "investments", title: "Value of investments", pane: <DealsInvestmentChartContainer /> },
                        count: { key: "count", title: "Number of deals", pane: <DealsCountContainer /> }
                    }} />
                </div>
            </div>
            <SectionHeading title="Numbers in prospective" description="Breakdown of no. of deals" />
            <div className="flex">
                <div className="w-full pb-4 flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2">
                        <Tabs
                            index="categories" style="pills" qs="ps"
                            tabs={{
                                countries: {
                                    title: "Countries",
                                    key: "countries",
                                    pane: <NumberInPerspectiveChart
                                        aggsType="terms" bucket="deals"
                                        maxBars={10} color="#eee" histogramLabel="Categories"
                                        histogramFieldKey="target.agbase_offices.country.name.keyword" />
                                },
                                categories: {
                                    title: "Categories",
                                    key: "categories",
                                    pane: <NumberInPerspectiveChart
                                        aggsType="terms" bucket="deals"
                                        maxBars={10} color="#eee" histogramLabel="Categories"
                                        histogramFieldKey="target.agbase_category.name.keyword" />
                                },
                                valuechain: {
                                    title: "Value Chain",
                                    key: "valuechain",
                                    pane: <NumberInPerspectiveChart
                                        bucket="deals" aggsType="terms"
                                        maxBars={10} color="#eee" histogramLabel="Categories"
                                        histogramFieldKey="target.agbase_value_chain.name.keyword" />
                                },
                                sdgs: {
                                    title: "SDGs",
                                    key: "sdgs",
                                    pane: <NumberInPerspectiveChart
                                        maxBars={10} color="#eee" histogramLabel="SDGs" bucket="deals"
                                        histogramFieldKey="target.sdgs.name.keyword" />
                                }
                            }}
                        />
                    </div>
                    <div className="w-full lg:w-1/2">
                        <Tabs
                            index="types" style="pills" qs="dts"
                            tabs={{
                                size: {
                                    title: "Deal Size",
                                    key: "size",
                                    pane: <NumberInPerspectiveRangeChart extra={{
                                        keyed: false,
                                        ranges: ranges,
                                    }}
                                        aggsType="range" bucket="deals"
                                        maxBars={10} color="#eee" histogramLabel="Ticket Size"
                                        histogramFieldKey="amount" />
                                },
                                types: {
                                    title: "Types",
                                    key: "types",
                                    pane: <NumberInPerspectiveChart
                                        aggsType="terms" bucket="deals"
                                        maxBars={10} color="#eee" histogramLabel="Types"
                                        histogramFieldKey="type.keyword" />
                                },
                                stages: {
                                    title: "Stages",
                                    key: "stages",
                                    pane: <NumberInPerspectiveChart
                                        aggsType="terms" bucket="deals"
                                        maxBars={10} color="#eee" histogramLabel="Stages"
                                        histogramFieldKey="stage.keyword" />
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}

const LatestDealsBlock = () => {
    const { filtersQuery } = useDataContainerContext()
    const { loading, data, error } = useQuery(SEARCH_QUERY, {
        variables: {

            search: {
                size: 5,
                bucket: "deals",
                sort: [{ key: "date", dir: "desc", },],
                query: {
                    filter: filtersQuery,
                },

            },
            fetchPolicy: "cache-and-network",
        }
    });

    return (
        <div className="flex gap-4 overflow-auto py-4 pb-8">
            {
                get(data, 'search.items', []).map((datum: any) => {
                    const investors = get(datum, 'investors', [])

                    return (
                        <div className="bg-[#eefcfa] inline-flex flex-shrink-0 w-[25rem] h-[10rem] p-4 rounded-lg border border-ab-lightteal flex-col">
                            <div className="grow pb-2">
                                <div className="font-semibold text-lg pb-2">
                                    <Link to={["/organizations", get(datum, 'target.id')].join("/")}>
                                        <Persona text={get(datum, 'target.name')} imageURL={get(datum, 'target.profile_image_url')} />
                                    </Link>
                                </div>
                                {
                                    investors.length > 0 && (
                                        <p className="text-medium text-ab-grey">Investors: {truncate(investors.map((i: any) => i.name).join(", "), { length: 128 })}</p>
                                    )
                                }
                            </div>
                            <div className="flex items-center">
                                <p className="text-3xl uppercase font-semibold grow ">{
                                    get(datum, 'amount', 0) > 0 && numeral(get(datum, 'amount', 0)).format("($ 0,00 a)")
                                }{
                                        get(datum, 'amount', 0) < 1 && <span className="text-ab-gray text-xl">UNDISCLOSED</span>
                                    }</p>
                                <p className="text-ab-grey">{formatDistance(new Date(get(datum, 'date', '')), new Date(), { addSuffix: true })}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}