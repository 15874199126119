import React from "react";
//@ts-ignore
import numeral from "numeral"
import { format, isAfter } from "date-fns";
import { Link } from "react-router-dom";
import { get } from "lodash";

const formatMoney = (amount: number) => {
    return numeral(amount).format('($ 0.00 a)')
}

export const OrgFundingFragment = ({ org, deals }: any) => {

    const getTotalFunding = () => {
        return deals?.reduce((acc: any, deal: any) => {
            return acc + deal.amount;
        }, 0);
    };

    const getLatestFunding = () => {
        const latestDeal = deals?.reduce((acc: any, deal: any) => {
            if (isAfter(new Date(deal.date), new Date(acc.date))) {
                return deal;
            }
            return acc;
        }, deals[0]);
        return latestDeal;
    };

    return (
        <div className='w-full h-auto relative bg-white mb-20 flex'>
            <div className='w-full'>
                <p className='text-lg font-semibold text-left text-gray-4100 mt-6'>Funding</p>
                <div className='flex justify-start items-start w-full gap-8 mt-6'>
                    <div className='flex flex-col justify-start items-start w-1/2 relative gap-[5px]'>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Number of Funding Rounds</p>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100'>
                            {deals?.length > 0 ? deals?.length : <span className='normal-case'>No funding information available</span>}
                        </p>
                    </div>
                    <div className='flex flex-col justify-start items-start w-1/2 relative gap-[5px]'>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Total Funding Amount</p>
                        <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100 uppercase'>
                            {getTotalFunding() > 0 ? (
                                formatMoney(getTotalFunding())
                            ) : (
                                <span className='normal-case'>No funding information available</span>
                            )}
                        </p>
                    </div>
                </div>
                {deals?.length > 0 ? (
                    <p className='text-sm text-left text-gray-3400 w-full mt-6'>
                        <span className='text-gray-4100'>{org?.name}</span> has raised a total of{" "}
                        <span className='text-gray-4100 uppercase'>{formatMoney(getTotalFunding())}</span> in funding over {deals?.length} rounds.
                        Their latest funding was raised on <span className='text-gray-4100'>{new Date(deals[0]?.date).toLocaleDateString()}</span>{" "}
                        from <span className='text-gray-4100'>{getLatestFunding()?.investors[0]?.name || ""}</span> round.
                    </p>
                ) : null}

                <svg
                    width='100%'
                    height={1}
                    viewBox='0 0 100vw 1'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='my-6'
                    preserveAspectRatio='xMidYMid meet'>
                    <line x1='4.37114e-8' y1='0.5' x2='100%' y2='0.500059' stroke='#EBEBF8' />
                </svg>
                <p className='text-lg font-semibold text-left text-gray-4100 mt-6'>Activity</p>
                {
                    deals?.length < 0 && (
                        <p className="py-4">No deals captured for this company</p>
                    )
                }
                <div className='flex flex-col w-full mt-2 overflow-x-auto'>
                    {deals?.length > 0
                        ? deals?.map((deal: any) => (
                            <div
                                className='flex flex-col lg:flex-row justify-between items-start w-full gap-2 mt-6 p-3 rounded-md bg-[#eefcfa]'
                                key={deal?.id}>
                                <div className='w-full flex flex-col justify-start items-start relative gap-[5px]'>
                                    <p className='text-md text-left text-gray-4100'>
                                        {deal?.stage}
                                        <span className='px-2 py-1 bg-[#eefcfa] text-gray-4100 uppercase'>
                                            {deal?.amount === 0 ? "Undisclosed" : formatMoney(deal?.amount)}
                                        </span>
                                    </p>
                                    <p className='text-left text-sm text-gray-3400'>{deal?.type}</p>
                                </div>
                                <div className='w-full flex items-center relative gap-1 overflow-x-auto'>
                                    {deal?.investors?.map((investor: any) => {
                                        return (
                                            <Link to={["/organizations", investor?.id].join("/")} key={investor.id}>
                                                <div className='w-12 h-12 bg-[#fff] flex items-center justify-center rounded-sm border border-gray-1000' title={investor?.name}>
                                                    <img
                                                        key={investor?.id}
                                                        src={investor?.profile_image_url}
                                                        alt=''
                                                        className='w-10 h-10 object-contain'
                                                    />
                                                </div>
                                            </Link>
                                        )
                                    }
                                    )}
                                </div>
                                <div className='flex flex-col justify-center items-start relative gap-[5px]'>
                                    <p className='text-sm text-left text-gray-3400'>{format(new Date(deal.date), "dd/MM/yyyy")}</p>
                                </div>
                            </div>
                        ))
                        : null}
                </div>
            </div>
        </div>
    );
}