import React, { useEffect, useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { useEventListener, useHover, useOnClickOutside } from "usehooks-ts";
import { IDataGrid, IDataGridArrayCell, IDataGridHeader, IDataGridOptions, IDataGridRow } from "./types";
// import {DataGridPagination} from "./DataGridPagination";
import _, { join, xorBy } from "lodash"
// @ts-ignore
import { DataGridRow } from "./Row";
import { RxArrowDown, RxArrowUp } from "react-icons/rx";
import { DataGridPagination } from "./DataGridPagination";
import classNames from "classnames";

interface IDataGridHeadersSort {
    key: string;
    dir: string;
}

interface IDataGridHeaders {
    columns: IDataGridHeader[];
    options: IDataGridOptions;
    sortBy?: (sort: IDataGridHeadersSort) => void;
    sort?: IDataGridHeadersSort[]
    isRowSelected?: boolean;
    onSelectAll?: any
}

// the header receives headers
function DataGridHeaders({ ...props }: IDataGridHeaders) {
    const { options, columns, sortBy, sort, onSelectAll, isRowSelected } = props
    return (
        <div role="rowheader" className="contents"
            style={{
                gridRowStart: 1
            }}>
            {
                options.isSelectable && (
                    <div role="gridcell"
                        key={`DataGridHeader__header__item__${1}`}
                        className="bg-bs-light-gray-50 uppercase text-sm  text-bs-light-gray flex justify-center px-2 py-3 first:rounded-l-lg last:rounded-r-lg"
                        style={{ gridColumnStart: 1 }}>
                        <input type="checkbox" onChange={onSelectAll} checked={isRowSelected} />
                    </div>
                )
            }
            {columns.map((column: IDataGridHeader, index: number) => {
                const isSortable = sort && column.sortingKey
                const sortValue = _.find(sort, { key: column.sortingKey })
                return (
                    <div role="gridcell"
                        key={`DataGridHeader__header__item__${index + (options.isSelectable ? 2 : 1)}`}
                        onClick={() => {
                            if (column?.sortable == true) {
                                if (sortBy && column.sortingKey && sortValue?.dir === "desc") {
                                    sortBy({ key: column.sortingKey, dir: "asc" })
                                } else if (sortBy && column.sortingKey) {
                                    sortBy({ key: column.sortingKey, dir: "desc" })
                                }

                            }
                        }}
                        className="font-bold text-nowrap bg-[#eefcfa] text-ab-lightdarkgreen uppercase text-xs flex items-center cursor-pointer gap-1.5 px-2 py-3 first:px-4 first:rounded-l-lg last:rounded-r-lg"
                        style={{ gridColumnStart: index + (options.isSelectable ? 2 : 1) }}>
                        {
                            column?.sortable && isSortable && sortValue && sortValue.dir === "asc" && (
                                <RxArrowUp className="w-4 h-4" />
                            )
                        }
                        {
                            column?.sortable && isSortable && sortValue && sortValue.dir === "desc" && (
                                <RxArrowDown className="w-4 h-4" />
                            )
                        }
                        {
                            column?.sortable && isSortable && !sortValue && (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                    stroke="currentColor" className="w-3 h-3">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
                                </svg>
                            )
                        }
                        {
                            column.renderHeader && column.renderHeader(column)
                        }
                        {
                            !column.renderHeader && column.title
                        }
                    </div>
                )
            })}
        </div>
    )
}

const DEFAULT_OPTIONS = {
    isSearchingEverywhere: false,
    isSelectable: false,
    colsWidth: "minmax(0, 1fr)"
}

export function DataGrid({ ...props }: IDataGrid) {
    const {
        headers = [],
        allHeaders,
        availableColums,
        data = [],
        options,
        entityName,
        count,
        took,
        sort,
        sortBy,
        pages = 1,
        currentPage = 1,
        rowsPerPage = 10,
        loading,
        onChangeRowsPerPage = () => {
        },
        onPagination = () => {
        },
        onColumnsChange,
        selectedRows,
        onSelectAllRows,
        onSelectRow
    } = props
    const [opts, setOpts] = useState({ ...DEFAULT_OPTIONS, ...options });
    const columnsNumber: number = headers.length + (opts.isSelectable ? 1 : 0);
    const rowsNumber: number = data.length;
    const rowHeight = 35;
    const isColumnSelected = (col: IDataGridHeader) => headers.some(header => header.key === col.key);
    const isRowSelected = (row: any) => selectedRows ? selectedRows.includes(row) : false;
    const isTableSelected = () => selectedRows ? selectedRows.length === data.length && selectedRows.length !== 0 : false;

    return (
        <div className="select-none w-full min-w-full">
            <div className="flex justify-center items-center py-2.5">
                <div className="flex-grow">
                    <p>
                        {
                            loading && (
                                <>
                                    <span>Loading..</span>
                                </>
                            )
                        }
                        {
                            !loading && (
                                <p className="px-2 text-gray-400 font-medium">
                                    show <span className="text-ab-darkgreen">{count === 10000 ? "10,000+" : count} {entityName?.toLowerCase()}</span>
                                </p>
                            )
                        }
                    </p>
                </div>
                <div className="flex justify-center items-center gap-5">
                    {
                        options?.isSearchingEverywhere && (
                            <div className="mx-4 self-center border py-2 px-4">
                                <input
                                    type="checkbox"
                                    checked={opts.isSearchingEverywhere}
                                    onChange={() => setOpts({ ...opts, isSearchingEverywhere: !opts.isSearchingEverywhere })}
                                /> Search Everywhere
                            </div>
                        )
                    }
                    <ColumnView
                        options={availableColums === undefined ? [] : availableColums}
                        handleColumnChange={onColumnsChange === undefined ? () => { } : onColumnsChange}
                        isColumnSelected={isColumnSelected}
                    />
                </div>
            </div>
            <div style={{ maxHeight: `calc(100vh - 250px)`, overflow: 'auto' }} className="">
                <div role="grid" className="grid border-b-bs-light-gray-100 border-b striped"
                    style={{
                        width: "100%",
                        overflow: 'auto',
                        gridTemplateColumns: opts.isSelectable ? `${rowHeight}px repeat(${columnsNumber - 1}, ${opts.colsWidth})` : `repeat(${columnsNumber}, ${opts.colsWidth})`,
                        gridTemplateRows: `auto repeat(${rowsNumber}, auto)`
                    }}>
                    <DataGridHeaders columns={headers} options={opts}
                        sortBy={sortBy} sort={sort} onSelectAll={onSelectAllRows} isRowSelected={isTableSelected()}
                    />
                    {
                        data.map((row, index) =>
                            <DataGridRow
                                options={opts}
                                key={`DataGridRow__row__${index}`}
                                headers={headers}
                                row={row}
                                isRowSelected={isRowSelected(row)}
                                onSelectRow={(row: any) => {
                                    if (onSelectRow) {
                                        onSelectRow(row)
                                    }
                                }}
                                rowNumber={index} />)
                    }
                </div>
            </div>
            <DataGridPagination
                rowsPerPage={rowsPerPage}
                max={pages}
                current={currentPage}
                onClick={(value) => {
                    onPagination(value);
                }}
                onChangeRowsPerPage={onChangeRowsPerPage}
            />
        </div>
    )
}


export interface ColumnViewProps {
    options: IDataGridHeader[]
    handleColumnChange: (col: IDataGridHeader) => void;
    isColumnSelected: (col: IDataGridHeader) => boolean;
}

export const ColumnView = ({ ...props }: any) => {
    const { options, handleColumnChange, isColumnSelected } = props
    const [state, setState] = React.useState({
        isOpen: false,
        selection: options.filter((o: IDataGridHeader) => isColumnSelected(o))
    });
    const ref = useRef(null)

    const handleClickOutside = () => {
        setState({ ...state, isOpen: false })
    }


    useOnClickOutside(ref, handleClickOutside)
    useEventListener('mouseleave', handleClickOutside, ref)

    return (
        <div className="dropdown inline-block relative z-20" ref={ref}>
            <button className="bg-transparent border border-ab-darkteal font-medium py-1 px-4 rounded inline-flex items-center" onClick={(ev) => {
                ev.preventDefault()
                setState({ ...state, isOpen: true })
            }}>
                <span className="mr-1">Show:</span>
                <span className="text-ab-darkteal">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /> </svg>
                </span>
            </button>
            {
                state.isOpen && (
                    <div className="absolute text-nowrap min-w-56 rounded-lg text-gray-700 right-0 w-fit bg-white p-4 shadow-lg border">
                        <p className="font-semibold pb-2">Select columns to show:</p>
                        <ul className="pb-2">
                            {
                                options.length < 0 && (
                                    <p>Selector not available</p>
                                )
                            }
                            {options.map((o: any) => {
                                const clses = classNames({
                                    "flex items-center gap-2 py-2 text-sm cursor-pointer": true,
                                    "font-semibold": isColumnSelected(o)
                                })
                                return (
                                    <li key={o.key}
                                        className={clses}
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            handleColumnChange(o)
                                        }}
                                    >
                                        <input type="checkbox" checked={isColumnSelected(o)} onChange={(ev) => {
                                            ev.preventDefault()
                                            handleColumnChange(o)
                                        }} /> {o.title}
                                    </li>
                                )
                            })}
                        </ul>
                        {/* <button
                            className="bg-ab-lightteal text-ab-darkgreen block w-full px-4 py-2 rounded-lg font-semibold"
                        >Apply</button> */}
                    </div>
                )
            }
        </div>
    )
}