import { gql, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SIGN_UP } from "../../../queries/auth";
import { get, has } from "lodash";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

type TRegisterInputs = {
    email: string
    password: string
    first_name: string
    other_names: string
    terms_conditions_accepted: boolean
}

export const RegisterForm = ({ onResponse }: any) => {
    const {
        register,
        handleSubmit
    } = useForm<TRegisterInputs>()

    const [state, setState] = useState({
        sessionID: null,
        otp_expire: null,
        first_name: null
    })

    const [create, { loading, error, data, reset }] = useMutation(SIGN_UP)
    const navigate = useNavigate()

    const onRegistrationSubmit = (data: any) => {
        create({ variables: { input: data } })
            .then(r => {
                if (has(r, 'data.createUser.id')) {
                    navigate(`/sign-in?email=${get(r, 'data.createUser.email')}`)
                }
            });
    }

    console.log(error)
    return (
        <div className="w-full pt-4">
            {
                error && (
                    <p className="bg-red-200 text-red-800 rounded-md py-1 px-2 my-2">{get(error, 'message')}</p>
                )
            }
            <form onSubmit={handleSubmit(onRegistrationSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-ab-grey">
                        Email
                    </label>
                    <input
                        type="text" autoComplete="off" placeholder="Email address"
                        className={classNames({
                            "border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm": true,
                            "border-red-500": has(error, 'email'),
                            "focus:bg-ab-lime": true
                        })}
                        {...register("email", { required: "Email is required" })}
                    />
                </div>
                <div className="flex gap-2 w-full">
                    <div className="flex flex-col gap-2 grow">
                        <label className="text-ab-grey">
                            First Name
                        </label>
                        <input
                            type="text" autoComplete="off" placeholder="Your first name"
                            className={classNames({
                                "border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm": true,
                                "border-red-500": has(error, 'first_name'),
                                "focus:bg-ab-lime": true
                            })}
                            {...register("first_name", { required: true })}
                        />
                    </div>
                    <div className="flex flex-col gap-2 grow">
                        <label className="text-ab-grey">
                            Other Names
                        </label>
                        <input
                            type="text" autoComplete="off" placeholder="Your surname and other names"
                            className={classNames({
                                "border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm": true,
                                "border-red-500": has(error, 'other_names'),
                                "focus:bg-ab-lime": true
                            })}
                            {...register("other_names", { required: true })}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <label className="">
                        Password
                    </label>
                    <input
                        type="password" autoComplete="off" placeholder="Choose your password"
                        className={classNames({
                            "border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm": true,
                            "border-red-500": has(error, 'password'),
                            "focus:bg-ab-lime": true
                        })}
                        {...register("password", { required: true })}
                    />
                </div>
                <div className="flex flex-row items-baseline gap-2">
                    <input type="checkbox" {...register("terms_conditions_accepted", { required: true })} />
                    <label>
                        By clicking create an account, I agree that I have read and accepted the Terms of Use and Privacy Policy.
                    </label>
                </div>
                <button type="submit" className="bg-ab-lightdarkgreen py-2 px-2 flex items-center justify-center rounded-md text-ab-lightteal" disabled={loading}>
                    {
                        loading
                            ? <svg className="animate-spin h-5 w-5 mr-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> : "Create a new account"
                    }
                </button>
            </form>
        </div>
    )
}
