import { format } from "date-fns";
import React from "react"
import { ContactInformation } from "./OrgContactInfoFragment";
import { get, flatten, uniq, find } from "lodash";
//@ts-ignore
import numeral from "numeral"

export const getOperatingStatus = (status: string) => {
    switch (status) {
        case "y":
            return "OPERATIONAL";
        case "n":
            return "SHUT DOWN";
        case "c":
            return "NO RECENT ACTIVITY";
        default:
            return status;
    }
};

const InfoBoxColsLayout = ({ cols = 2, children }: any) => {
    return (
        <div className={`grid grid-cols-${cols} gap-8 my-6 w-full`}>
            {children}
        </div>
    )
}

const InfoBoxRow = ({ title, children }: any) => {
    return (
        <div className='flex flex-col justify-start items-start relative gap-[5px]'>
            <p className='text-sm font-medium text-left text-gray-3400'>{title}</p>
            <p className='text-sm font-medium text-left text-gray-4100'>{children}</p>
        </div>
    )
}

export const OrgProfileStartupFragment = ({ org }: any) => {
    let stages = org?.deals?.map((deal: any) => {
        return {
            ...deal,
            date: new Date(deal.date).getTime(),
        };
    })
        ?.sort((a: any, b: any) => b.date - a.date)
        ?.map((deal: any) => deal?.stage) || [];

    const headquarter = find(get(org, 'offices', []), { is_hq_primary: true })

    const companyContact = {
        email: get(org, 'primary_email_contact', "No information available"),
        phone: "No information available",
        hqLocation: [get(headquarter, 'city.name', "No information avialable"), get(headquarter, 'country.name', "No information avialable")].join(", "),
        hqAddress: get(headquarter, 'address', "No information avialable"),
        operatingCountries: get(org, 'ops_countries', []),
    };

    // remove duplicate stages and show the number eg. Seed (2)
    const stagesCount = stages.reduce((acc: any, stage: any) => {
        if (acc[stage]) {
            acc[stage] += 1;
        } else {
            acc[stage] = 1;
        }
        return acc;
    }, {});

    stages = Object.keys(stagesCount).map((stage) => {
        return stagesCount[stage] > 1 ? `${stage} (${stagesCount[stage]})` : stage;
    });

    const getBusinessModel = () => {
        if (org?.business_models) {
            return org?.business_models?.map((model: any) => model?.name).join(", ");
        } else {
            return "No information available";
        }
    };

    return (
        <div className='w-full h-auto relative bg-white sm:mb-20 flex'>
            <div className='w-full pr-8'>
                <p className='text-lg font-semibold text-left text-gray-4100 mt-6'>About</p>
                <p className='text-sm font-normal text-left text-gray-3400 w-full mt-6'>{org?.description || ""}</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-6">
                    <InfoBoxRow title="Founded date">
                        {(org?.founded && new Date(org?.founded).getFullYear() > 10 && format(new Date(org?.founded), "yyyy")) || "No information available"}
                    </InfoBoxRow>
                    <InfoBoxRow title="Operating Status">
                        {getOperatingStatus(get(org, 'operating_status', ''))}
                    </InfoBoxRow>
                    <InfoBoxRow title="Company size">
                        {get(org, 'number_employees', 'No information available')}
                    </InfoBoxRow>
                    <InfoBoxRow title="Business model">
                        {getBusinessModel()}
                    </InfoBoxRow>
                    <InfoBoxRow title="Stages raised">
                        <div className="flex gap-2">
                            {stages?.map((stage: any, index: number) => (
                                <div className='flex justify-start whitespace-nowrap items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary' key={index}>
                                    <p className='text-sm font-medium text-left text-gray-4100'>{stage}</p>
                                </div>
                            ))}
                        </div>
                    </InfoBoxRow>
                    <InfoBoxRow title="Latest stage recorded">
                        {stages.length > 0 ? stages[0] : "No information available"}
                    </InfoBoxRow>
                </div>
                <hr />
                <ContactInformation contact={companyContact} />
                <hr />
                <div className='w-auto h-auto my-6'>
                    <p className='text-lg font-semibold text-left text-gray-4100'>Industry specifics</p>
                    <InfoBoxColsLayout cols={1}>
                        <InfoBoxRow title="Sectors of interest">
                            <div className='flex justify-start items-center flex-wrap gap-2 mt-2'>
                                {
                                    get(org, 'agbase_category', []).map((c: any) => {
                                        return (
                                            <div className='flex justify-start items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary'>
                                                <p className='text-sm font-medium text-left text-gray-4100'>{c.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InfoBoxRow>
                        <InfoBoxRow title="Value chain of interest">
                            <div className='flex justify-start items-center flex-wrap gap-2 mt-2'>
                                {
                                    get(org, 'agbase_value_chain', []).map((c: any) => {
                                        return (
                                            <div className='flex justify-start items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary'>
                                                <p className='text-sm font-medium text-left text-gray-4100'>{c.name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InfoBoxRow>
                    </InfoBoxColsLayout>
                </div>
            </div>
        </div>
    );
};

export const OrgProfileInvestoFragment = ({ org }: any) => {

    const getCumulativeInvestment = () => {
        return numeral(get(org, 'investments', []).reduce((acc: any, curr: any) => {
            return acc + curr?.amount;
        }, 0)).format('$ (0.0a)');
    };

    const stages = Object.entries(get(org, 'investments', []).map((deal: any) => {
        return {
            ...deal,
            date: new Date(deal.date).getTime(),
        };
    })
        .sort((a: any, b: any) => b.date - a.date)
        .map((deal: any) => deal?.type)
        .reduce(function (acc: any, curr: any) {
            return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
        }, {})
    ).map((value) => `${get(value, 0, '')} (${get(value, 1, '')})`)


    const headquarter = find(get(org, 'offices', []), { is_hq_primary: true })
    const ops_countries = uniq(flatten(get(org, 'investments', []).map((i: any) => {
        return get(i, 'target.ops_countries', [])
    }))) as any[]

    const investorContact = {
        email: get(org, 'primary_email_contact', "No information available"),
        phone: "No information available",
        hqLocation: [get(headquarter, 'city.name', "No information avialable"), get(headquarter, 'country.name', "No information avialable")].join(", "),
        hqAddress: get(headquarter, 'address', "No information avialable"),
        operatingCountries: ops_countries,
    };


    const categories = uniq(flatten(get(org, 'investments', []).map((i: any) => {
        return get(i, 'target.agbase_category', [])?.map((c: any) => get(c, 'name', ''))
    }))) as string[]

    const value_chains = uniq(flatten(get(org, 'investments', []).map((i: any) => {
        return get(i, 'target.agbase_value_chain', [])?.map((c: any) => get(c, 'name', ''))
    }))) as string[]

    return (
        <div className='w-full h-auto relative bg-white mb-20 flex'>
            <div className='w-full pr-8'>
                <p className='text-lg font-medium text-left text-gray-4100 mt-6'>About</p>
                <p className='text-sm font-normal text-left text-gray-3400 w-full mt-6'>{org?.description || "No information available "}</p>
                <InfoBoxColsLayout cols={2}>
                    <InfoBoxRow title="Founded date">
                        {(org && new Date(org?.founded).getFullYear() > 10 && format(new Date(org?.founded), "yyyy")) || "No information available"}
                    </InfoBoxRow>
                    <InfoBoxRow title="Operational status">
                        {getOperatingStatus(org?.operating_status)}
                    </InfoBoxRow>
                    <InfoBoxRow title="Management">
                        {org?.founders || "No information available"}
                    </InfoBoxRow>
                    <InfoBoxRow title="Type">
                        <span className="capitalize">{get(org, 'specificer', get(org, 'type', ''))}</span>
                    </InfoBoxRow>
                    <InfoBoxRow title="Total cumulative investments">
                        {getCumulativeInvestment() ? (
                            (getCumulativeInvestment())
                        ) : (
                            <span className='normal-case'>No funding information available</span>
                        )}
                    </InfoBoxRow>
                    <InfoBoxRow title="Stages of interest">
                        <div className='flex justify-start items-center flex-wrap gap-2 mt-2'>
                            {stages?.map((stage: any, index: number) => (
                                <div className='flex justify-start items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary' key={index}>
                                    <p className='text-sm font-medium text-left text-gray-4100'>{stage}</p>
                                </div>
                            ))}
                        </div>
                    </InfoBoxRow>
                </InfoBoxColsLayout>
                <hr />
                <ContactInformation contact={investorContact} />
                <hr />
                <div className='w-full h-auto my-6'>
                    <p className='text-lg font-medium text-left text-gray-4100'>Industry specifics</p>
                    <InfoBoxColsLayout cols={1}>
                        <InfoBoxRow title="Sectors of interest">
                            <div className='flex justify-start items-center flex-wrap gap-2 mt-2'>
                                {
                                    categories.map((c: string) => {
                                        return (
                                            <div className='flex justify-start items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary'>
                                                <p className='text-sm font-medium text-left text-gray-4100'>{c}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InfoBoxRow>
                        <InfoBoxRow title="Value chain of interest">
                            <div className='flex justify-start items-center flex-wrap gap-2 mt-2'>
                                {
                                    value_chains.map((c: string) => {
                                        return (
                                            <div className='flex justify-start items-center h-8 relative gap-[13px] px-3 py-2.5 rounded bg-secondary'>
                                                <p className='text-sm font-medium text-left text-gray-4100'>{c}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </InfoBoxRow>
                    </InfoBoxColsLayout>
                </div>
                {/* <FeaturingInvestor /> */}
            </div>
        </div>
    );
};
