import React, { ChangeEvent, useEffect, useState } from "react";
import { IFilter } from "./FilterFactory";
import { FilterTitle } from "./fragments/FilterTitle";
import { RxCross1, RxExit } from "react-icons/rx";
import _ from "lodash";

interface IFilterFreeSearch extends IFilter {

}

export default function FilterFreeSearch({ ...props }: IFilterFreeSearch) {
    const { title, type, id, addFilter, removeFilter, defaultValues } = props
    const value = _.get(defaultValues, 'value', '');
    const [state, setState] = useState({
        query: value
    });
    const update = (ev: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            query: ev.target.value
        });
    }
    const reset = () => {
        setState({
            ...state,
            query: ''
        });
    }

    useEffect(() => {
        setState({ ...state, query: value })
    }, [value])

    useEffect(() => {
        if (state.query.length < 1) {
            removeFilter({
                type, key: id, value: ''
            })
        } else {
            addFilter({
                type, key: id, value: state.query
            })
        }
    }, [state.query]);

    return (
        <div className="w-full relative">
            <FilterTitle title={title} />
            <div className="relative w-full">
                {
                    state.query.length > 0 && (
                        <span
                            className="absolute right-3 top-3 py-2 cursor-pointer"
                            onClick={reset}
                        >
                            <RxCross1 className="w-3 h-3" />
                        </span>
                    )
                }
                <input type="text"
                    className="w-full mt-2 border border-ab-lightdarkgreen rounded-md bg-ab-darkgreen text-ab-lightteal py-1.5 outline-none focus:border-r-bs-light-black placeholder:text-ab-lightteal px-4 placeholder:font-medium"
                    placeholder="All"
                    value={state.query}
                    onChange={update}
                />
            </div>
        </div>
    )
}