import React from "react";

import { Axis, BarSeries, Grid, Tooltip, XYChart } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import { gql, useQuery } from "@apollo/client";
import { get } from "lodash";
//@ts-ignore
import { TickLabelProps } from "@visx/axis";
import { scaleLinear } from "@visx/scale";
import { Text } from "@visx/text";
import { useDataContainerContext } from "../../elements/DataContainer";
import { error } from "console";
import { BUCKET_ORGS } from "../../constants";

const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
};

interface INumberInPerspectiveChart {
    color: string;
    histogramLabel: string;
    histogramFieldKey: string;
    maxBars?: number
    aggsType?: any;
    bucket?: string;
}

interface INumberInPerspectiveRangeChart extends INumberInPerspectiveChart {
    extra?: any
}

const tickLabelProps: TickLabelProps<any> = () =>
({
    fill: "#000",
    fontSize: 10,
    textAnchor: "middle",
    verticalAnchor: "start",
    width: 10,
} as const);

const getColorByIndex = (index: number) => {
    const colors = ['#0A2F51', '#0E4D64', '#137177', '#188977', '#1D9A6C',
        '#39A96B', '#56B870', '#74C67A', '#99D492', '#BFE1B0', '#DEEDCF'];
    return colors[index % colors.length]
}

export const NumberInPerspectiveRangeChart = ({ ...props }: INumberInPerspectiveRangeChart) => {
    const { color, histogramFieldKey, extra = {}, histogramLabel, maxBars = 1000, aggsType = 'terms', bucket = BUCKET_ORGS } = props
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: bucket,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "agg",
                        type: aggsType,
                        extra_params: {
                            ...extra
                        },
                        params: {
                            field: histogramFieldKey
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    console.log(get(data, 'aggregate.aggregations.agg.buckets', []))
    const xydata = get(data, 'aggregate.aggregations.agg.buckets', [])
        .map((datum: any) => ({ x: datum.key, y: get(datum, 'doc_count', 0) }))

    if (get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0) > 0) {
        xydata.push({
            x: "Others", y: get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0)
        })
    }

    const colors = scaleLinear({
        domain: [
            Math.min(...xydata.map((v: any) => v.y)),
            Math.max(...xydata.map((v: any) => v.y)),
        ],
        range: ['#eee', '#015659']
    })
    const height = 350;

    return (
        <ParentSize className="w-full">
            {(parent: any) => {
                const xticks = xydata.length > 0 ? parent.width / xydata.length / 20 : 20
                const yticks = xydata.length > 0 ? height / xydata.length / 3 : 20
                console.log(xydata, xticks)
                return (
                    <XYChart
                        margin={{ bottom: 75, left: 0, right: 0, top: 25 }}
                        width={parent.width} height={height} xScale={{ type: "band", padding: 0.45 }} yScale={{ type: 'linear' }}>
                        <Axis orientation="bottom" numTicks={xticks} tickFormat={(v: string) => v} tickLabelProps={tickLabelProps} />
                        <Grid columns={false} numTicks={data?.length} lineStyle={{ color: "#eee" }} />
                        <BarSeries dataKey={histogramLabel} data={xydata} {...accessors}
                            barPadding={0.3} colorAccessor={(datum: any, index: number) => colors(get(datum, 'y', 0))}
                        />
                        <Tooltip
                            snapTooltipToDatumX
                            snapTooltipToDatumY
                            showVerticalCrosshair
                            showSeriesGlyphs
                            renderTooltip={({ tooltipData }: any) => (
                                <div className="font-normal cursor-pointer px-2 py-4">
                                    {accessors.xAccessor(tooltipData?.nearestDatum?.datum)}
                                    {': '}
                                    {accessors.yAccessor(tooltipData?.nearestDatum?.datum)}
                                </div>
                            )}
                        />
                        {
                            loading && (
                                <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                    Loading chart...
                                </Text>
                            )
                        }
                    </XYChart>
                )
            }}
        </ParentSize>
    )
}

export const NumberInPerspectiveChart = ({ ...props }: INumberInPerspectiveChart) => {
    const { color, histogramFieldKey, histogramLabel, maxBars = 1000, aggsType = 'terms', bucket = BUCKET_ORGS } = props
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: bucket,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "agg",
                        type: aggsType,
                        params: {
                            size: maxBars,
                            field: histogramFieldKey
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const xydata = get(data, 'aggregate.aggregations.agg.buckets', [])
        .map((datum: any) => ({ x: datum.key, y: get(datum, 'doc_count', 0) }))

    if (get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0) > 0) {
        xydata.push({
            x: "Others", y: get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0)
        })
    }

    const colors = scaleLinear({
        domain: [
            Math.min(...xydata.map((v: any) => v.y)),
            Math.max(...xydata.map((v: any) => v.y)),
        ],
        range: ['#eee', '#015659']
    })
    const height = 350;

    return (
        <ParentSize className="w-full">
            {(parent: any) => {
                const xticks = parent.width / xydata.length / 8
                const yticks = height / xydata.length / 10
                return (
                    <XYChart
                        margin={{ bottom: 75, left: 0, right: 0, top: 25 }}
                        width={parent.width} height={height} xScale={{ type: "band", padding: 0.35 }} yScale={{ type: 'linear' }}>
                        <Axis orientation="bottom" numTicks={xticks} tickFormat={(v: string) => v} tickLabelProps={tickLabelProps} />
                        <Grid columns={false} numTicks={data?.length} lineStyle={{ color: "#eee" }} />
                        <BarSeries dataKey={histogramLabel} data={xydata} {...accessors}
                            barPadding={0.3} colorAccessor={(datum: any, index: number) => colors(get(datum, 'y', 0))}
                        />
                        <Tooltip
                            snapTooltipToDatumX
                            snapTooltipToDatumY
                            showVerticalCrosshair
                            showSeriesGlyphs
                            renderTooltip={({ tooltipData }: any) => (
                                <div className="font-normal cursor-pointer px-2 py-4">
                                    {accessors.xAccessor(tooltipData?.nearestDatum?.datum)}
                                    {': '}
                                    {accessors.yAccessor(tooltipData?.nearestDatum?.datum)}
                                </div>
                            )}
                        />
                        {
                            loading && (
                                <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                    Loading chart...
                                </Text>
                            )
                        }
                    </XYChart>
                )
            }}
        </ParentSize>
    )
}