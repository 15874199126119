import React from "react"
import srcBackground from "./background.png"



export const SimpleProgressBar = ({ ...props }: { percentage?: number, value?: number }) => {
    const {
        percentage = 0,
        value
    } = props
    return (
        <div className="w-full">
            <div className="h-5 w-full bg-[#eee] rounded-md relative flex">
                <div className="h-5 bg-ab-lightdarkgreen rounded-md flex items-center"
                    style={{
                        width: `${percentage}%`,
                        backgroundImage: `url(${srcBackground})`
                    }}>
                    {
                        percentage > 25 && (
                            <p className="text-ab-lightteal self-center justify-center flex px-4">{value ? value : `${percentage}%`}</p>
                        )
                    }
                </div>
                {
                    percentage <= 25 && (
                        <p className="text-ab-darkgreen self-center px-12 grow justify-self-end" style={{ paddingLeft: percentage }}>{value ? value : `${percentage}%`}</p>
                    )
                }
            </div>
        </div>
    )
}

export const SimpleProgressBarWithNumbers = () => {
    return (
        <div className="w-full">
            <div className="h-6 w-full bg-[#eee] rounded-md relative">
                <div className="h-6 bg-ab-lightgreen rounded-md flex items-center absolute bg-repeat"
                    style={{ width: "56%" }}>
                    <p className="text-white font-light justify-center w-full flex gap-4"><span className="font-medium">$ 560.0M</span> (56%)</p>
                </div>
                <p className="text-right px-4 text-ab-gray">$ 1.0B</p>
            </div>
        </div>
    )
}