import React from "react"
import { LoginLayout } from "./LoginLayout"
import { Link } from "react-router-dom"
import { RegisterForm } from "./fragments/RegisterForm"

export default function RegisterContainer() {
    return (
        <LoginLayout>
            <div className="w-full lg:w-1/3">
                <div className="py-8">
                    <p className="text-xl font-medium pb-2">Sign Up</p>
                    <div className='flex flex-col gap-4'>
                        <p className="text-gray-500">
                            Do you have an account? <Link to="/login" className="text-ab-lightdarkgreen font-medium">Sign in</Link>
                        </p>
                    </div>
                    <RegisterForm />
                </div>
            </div>
        </LoginLayout>
    )
}