import React from "react";
import { NavBar } from "../../components/NavBar";
import { DataContainerProvider, useDataContainerContext } from "../../elements/DataContainer";
import { filters } from "./filters";
import { DataContainerFilters } from "../../elements/DataContainer/DataContainerFilters";
import { EMTable } from "./components/EMTable";
import { Tabs } from "../../elements/Tabs"
import { EMGeoTableTab } from "./components/EMGeoTableTab";
import classNames from "classnames";

const EMContent = () => {
    const { areFiltersVisible, setAreFiltersVisibile } = useDataContainerContext();
    const clsesFilters = classNames({
        "fixed": true,
        "w-20rem": areFiltersVisible,
        "xl:w-[2.5rem]": !areFiltersVisible
    })
    const clsesContainer = classNames({
        "overflow-hidden grow": true,
        "ml-[20rem] w-[calc(100vw-20rem)]": areFiltersVisible,
        "xl:ml-[2.5rem] xl:w-[calc(100vw-2.5rem)] xl:max-w-[calc(100vw-2.5rem)]": !areFiltersVisible
    })

    return (
        <div className="w-full flex">
            <div className={clsesFilters}>
                <DataContainerFilters />
            </div>
            <div className={clsesContainer}>
                <div className="px-10 flex flex-col grow">
                    <Tabs index="countries" style="pills" qs="cp" tabs={{
                        countries: {
                            key: "countries", title: "Country Comparison",
                            pane: (
                                <div className="w-full grow">
                                    <EMGeoTableTab pivot="target.agbase_offices.country.alpha3.keyword" pivotLabel="Africa HQ Country" />
                                </div>
                            )
                        },
                        // regions: {
                        //     key: "regions", title: "Regions Comparison",
                        //     pane: (
                        //         <div className="w-full grow">
                        //             <EMGeoTableTab pivot="target.offices_hq_global.country.sub_region.keyword" pivotLabel="Africa HQ Region" />
                        //         </div>
                        //     )
                        // },
                        category: {
                            key: "category", title: "Category Comparison",
                            pane: <EMTable pivot="target.agbase_category.name.keyword" pivotLabel="Categories" />
                        },
                        "value-chain": {
                            key: "value-chain", title: "Value Chain Comparison",
                            pane: <EMTable pivot="target.agbase_value_chain.name.keyword" pivotLabel="Value Chain" />
                        }
                    }} />
                </div>
            </div>
        </div>
    )
}

export const EMAnalysisContainer = () => {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <div className="pt-[60px] min-h-screen h-full">
                <DataContainerProvider filters={filters}>
                    <EMContent />
                </DataContainerProvider>
            </div >
        </div >
    )
}