import React from "react";
import { FlagIcon } from "react-flag-kit";

type ContactProps = {
    children: React.ReactNode;
};
const Contact: React.FC<ContactProps> = ({ children }) => <div className='flex flex-col md:flex-row text-sm justify-start items-start w-full gap-8 mt-5'>{children}</div>;

type ContactItemProps = {
    children: React.ReactNode;
};
const ContactItem: React.FC<ContactItemProps> = ({ children }) => (
    <div className='flex flex-col text-sm justify-start items-start flex-grow-0 flex-shrink-0 w-1/2 relative gap-[5px]'>{children}</div>
);

type ContactItemTitleProps = {
    children: React.ReactNode;
};
const ContactItemTitle: React.FC<ContactItemTitleProps> = ({ children }) => (
    <p className='flex-grow-0  flex-shrink-0 text-sm font-medium text-left text-gray-3400'>{children}</p>
);

type ContactItemValueProps = {
    children: React.ReactNode;
};
const ContactItemValue: React.FC<ContactItemValueProps> = ({ children }) => (
    <p className='flex-grow-0 flex-shrink-0  text-sm font-medium text-left text-gray-4100'>{children}</p>
);

interface IPropsContactInformation {
    companyContact: any;
}

export const ContactInformation = ({ contact }: any) => {
    console.log(contact)
    return (
        <div className='w-full my-6'>
            <p className='text-lg font-semibold text-left text-gray-4100'>Contact information</p>
            <Contact>
                <ContactItem>
                    <ContactItemTitle>Email</ContactItemTitle>
                    <ContactItemValue>{contact?.email}</ContactItemValue>
                </ContactItem>
                <ContactItem>
                    <ContactItemTitle>Phone number</ContactItemTitle>
                    <ContactItemValue>{contact?.phone}</ContactItemValue>
                </ContactItem>
            </Contact>
            <Contact>
                <ContactItem>
                    <ContactItemTitle>HQ Location</ContactItemTitle>
                    <ContactItemValue>{contact?.hqLocation}</ContactItemValue>
                </ContactItem>
                <ContactItem>
                    <ContactItemTitle>HQ Address</ContactItemTitle>
                    <ContactItemValue>{contact?.hqAddress}</ContactItemValue>
                </ContactItem>
            </Contact>
            <Contact>
                <div className='w-full'>
                    <ContactItemTitle>Operating countries</ContactItemTitle>
                    <div className='flex flex-wrap gap-4 w-full mt-2'>
                        {contact?.operatingCountries.map((country: any) => (
                            <ContactItemValue key={country?.id}>
                                <div className='py-1 rounded-md flex'>
                                    <FlagIcon code={country?.alpha2} className="rounded-sm" /> &nbsp; {country?.name}
                                </div>
                            </ContactItemValue>
                        ))}
                    </div>
                </div>
            </Contact>
        </div>
    );
};