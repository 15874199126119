import { gql, useQuery } from "@apollo/client";
import { IStatCard, StatCard } from "../../containers/Organizations/components/OverviewMaps";
import { useDataContainerContext } from "../DataContainer";
import { get } from "lodash";

export type TStatsCardGQL = {
    queryVariables: any;
    root: string;
    stat: IStatCard;
    format?: (ev: any) => JSX.Element | string
}
export const StatsCardGQL = ({ queryVariables, root, stat, format }: TStatsCardGQL) => {
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }   
    }
    
    `, {
        variables: { ...queryVariables },

    });

    const vals = format ? format(get(data, `aggregate.aggregations.${root}`, '-')) : get(data, `aggregate.aggregations.${root}`, '-')
    return (
        <StatCard {...stat} value={vals} />
    )
}

