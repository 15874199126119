import React from "react";
import { useDataContainerContext } from "../../../../elements/DataContainer";
import { AreaSeries, Axis, Grid, Tooltip, XYChart } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import { curveCardinal } from "@visx/curve";
import { format } from "date-fns";
import { gql, useQuery } from "@apollo/client";
import { get } from "lodash";
import "css-skeletons"
import { SpinnerChartBlock } from "../../../../elements/Spinners";
import { Text } from "@visx/text";
import { BUCKET_ORGS } from "../../../../constants";

const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
};

interface IEcosystmHistoryChart {
    color: string;
    histogramLabel: string;
    histogramFieldKey: string;
    histogramFieldValueCount: string;
}

export const EcosystemHistoryChartNoCompanies = ({ ...props }: IEcosystmHistoryChart) => {
    const { color, histogramFieldKey, histogramFieldValueCount, histogramLabel } = props
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: BUCKET_ORGS,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "agg",
                        type: "date_histogram",
                        params: {
                            field: histogramFieldKey,
                            calendar_interval: "year",
                            format: "yyyy"
                        },
                        extra: {
                            aggs: {
                                sub: {
                                    value_count: {
                                        field: histogramFieldValueCount,
                                    },
                                },
                                top_entities: {
                                    top_hits: {
                                        sort: [
                                            {
                                                total_funding: {
                                                    order: "desc"
                                                }
                                            }
                                        ],
                                        _source: {
                                            includes: ["name", "total_funding"]
                                        },
                                        size: 5
                                    }
                                }
                            },
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const xydata = get(data, 'aggregate.aggregations.agg.buckets', [])
        .filter((datum: any) => new Date(datum.key_as_string) > new Date('2000'))
        .map((datum: any) => ({ x: new Date(datum.key_as_string), y: get(datum, 'sub.value', 0) }))
    const dataset = get(data, `aggregate.aggregations.agg.buckets`, [])

    const height = 250

    return (
        <ParentSize className="w-full">
            {(parent) => {
                const xticks = xydata.length > 0 ? parent.width / xydata.length / 20 : 20
                const yticks = xydata.length > 0 ? height / xydata.length / 3 : 20
                return (
                    <XYChart
                        margin={{ bottom: 20, left: 30, right: 0, top: 25 }}
                        width={parent.width} height={height} xScale={{ type: "time" }} yScale={{ type: 'linear' }}>
                        <Axis orientation="bottom" hideZero numTicks={xticks} tickFormat={(v: Date, i: number) => format(v, 'yyyy')} />
                        <Axis orientation="left" hideZero hideAxisLine numTicks={yticks} tickFormat={(v: any) => v} />
                        <Grid columns={false} numTicks={yticks} lineStyle={{ color: "#f5f5f5" }} />
                        <AreaSeries dataKey={histogramLabel} data={xydata} {...accessors}
                            curve={curveCardinal} fill={color} lineProps={{ width: 0.5 }} />
                        <Tooltip
                            snapTooltipToDatumX
                            snapTooltipToDatumY
                            showHorizontalCrosshair
                            showVerticalCrosshair
                            horizontalCrosshairStyle={{ stroke: "#ccc" }}
                            verticalCrosshairStyle={{ stroke: "#ccc" }}
                            showSeriesGlyphs
                            detectBounds
                            renderTooltip={({ tooltipData }) => {
                                // const key = accessors.xAccessor(tooltipData?.nearestDatum?.datum);
                                // const top5 = find(dataset, { key_as_string: format(key, 'yyyy') })
                                // console.log("top5", top5)
                                return (
                                    <div className="font-normal cursor-pointer px-2 py-4">
                                        <p className="pb-2 font-medium">YEAR {format(accessors.xAccessor(tooltipData?.nearestDatum?.datum), 'yyyy')}</p>
                                        {tooltipData?.nearestDatum?.key}
                                        {': '}
                                        {accessors.yAccessor(tooltipData?.nearestDatum?.datum)}
                                        {/* {
                                            top5 && (
                                                get(top5, 'top_entities.hits.hits', [])).map((i: any) => {
                                                    return (
                                                        <p>{get(i, '_source.name')} - {get(i, '_source.total_funding')}</p>
                                                    )
                                                }
                                                )
                                        } */}
                                    </div>
                                )

                            }}
                        />
                        {
                            loading && (
                                <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                    Loading chart...
                                </Text>
                            )
                        }
                    </XYChart>
                )
            }}
        </ParentSize>
    )
}