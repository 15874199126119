export const filters: any[] = [
    {
        title: "Filters",
        filters: [
            {
                type: "select-or",
                title: "Regional HQ Countries",
                key: "target.agbase_offices.country.name.keyword",
                id: "target.agbase_offices.country.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "target.operating_status.keyword",
                id: "target.operating_status.keyword",
                dynamic: false,
                bucket: "deals",
                options: [
                    {
                        "key": "y",
                        "value": "y",
                        "title": "Operating"
                    },
                    {
                        "key": "n",
                        "value": "n",
                        "title": "Shut down"
                    },
                    {
                        "key": "c",
                        "value": "c",
                        "title": "Under review"
                    }
                ]
            },
            {
                type: "select-or",
                title: "Region",
                key: "target.agbase_offices.country.region.keyword",
                id: "target.agbase_offices.country.region.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "range",
                title: "Deals date",
                key: "date",
                id: "date"
            },
            {
                type: "select-or",
                title: "Categories",
                key: "target.agbase_category.name.keyword",
                id: "target.agbase_category.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "target.agbase_value_chain.name.keyword",
                id: "target.agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "range",
                title: "Founded between",
                key: "target.founded",
                id: "target.founded"
            },
            {
                title: "SDG name",
                type: "select-or",
                key: "target.sdgs.name.keyword",
                id: "target.sdgs.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "target.attributes.organization:ResourceTag/Impact",
                id: "target.attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: 'deals',
                options: []
            }
        ]
    },
    {
        title: "Founders Information",
        filters: [
            {
                type: "select-or",
                title: "Ethnicity",
                key: "target.employee.person.ethnicity.keyword",
                id: "target.employee.person.ethnicity.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "select-or",
                title: "Gender",
                key: "target.employee.person.gender.keyword",
                id: "target.employee.person.gender.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "select-or",
                title: "Education Level",
                key: "target.employee.person.education_level.keyword",
                id: "target.employee.person.education_level.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },

        ]
    }
];