import React, { useEffect, useState } from "react";
import { TabsContextProvider } from "./context/TabsContext";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { get } from "lodash";

export interface ITabPane {
}

export interface ITabContext {
    tabs: ITabPane[];
    prefixCls: string;
}

// export const TabContext = createContext<ITabContext>(null);

export function TabPaneList() {

}

export function TabPane({ children, label, id }: { children: React.ReactNode, label: string, id: string }) {
    return (
        <p>
            {children}
        </p>
    )
}


export function TabNavList() {
    return (
        <li className="mr-2">
            <a href="#"
                className="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                <svg
                    className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clipRule="evenodd"></path>
                </svg>
                Profile
            </a>
        </li>
    )
}

export interface ITab {
    title: string;
    pane: JSX.Element;
    key: string;
}

export interface ITabs {
    tabs: any;
    index: string;
    as?: React.ElementType;
    asClassName?: string
    qs?: string;
    style?: "pills" | "nav"
}

interface ITabsState {
    activeIndex: string
}

export function Tabs({ tabs, index, as: TabContainer = "div", asClassName, qs = "t", style = "nav" }: ITabs) {
    const [state, setState] = useState({ activeIndex: index } as ITabsState);
    const [searchParams, setSearchParams] = useSearchParams()
    const qstring = searchParams.get(qs)
    useEffect(() => {
        if (qstring) {
            setState({ activeIndex: qstring as string })
        } else {
            searchParams.set(qs, index)
            setSearchParams(searchParams, { replace: true })
        }
    }, [qstring])

    return (
        <TabsContextProvider>
            <div style={{
                padding: "0 0 0 0", marginBottom: "0rem",
                borderBottom: style === "nav" ? "1px solid #f0f0f0" : "transparent", width: "100%"
            }} className="overflow-auto">
                <ul className="flex -mb-0 font-light flex-row text-center text-sm items-center gap-4 md:gap-8 cursor-pointer">
                    {
                        Object.keys(tabs).map((key: string, index: number) => {
                            const styleTabs = style === "nav"
                                ? classNames({
                                    "inline-flex text-nowrap px-2 py-4 rounded-t-lg active group border-b-2 cursor-pointer select-none": true,
                                    "text-ab-gray border-b-transparent hover:border-ab-lime": tabs[key].key !== state.activeIndex,
                                    "border-b-2 border-ab-lightgreen font-medium": tabs[key].key === state.activeIndex,
                                })
                                : classNames({
                                    "rounded-xl text-nowrap inline-flex px-4 py-2": true,
                                    "text-ab-gray bg-gray-100 hover:bg-ab-lime": tabs[key].key !== state.activeIndex,
                                    "text-ab-darkergreen bg-ab-lightteal font-medium": tabs[key].key === state.activeIndex,
                                });
                            const styleContainerTabs = style === "nav" ? "first:ml-8" : "text-ab-grey"
                            return (
                                <li className={styleContainerTabs} key={key} onClick={(ev) => {
                                    ev.preventDefault();
                                    setState({ activeIndex: tabs[key].key })
                                    searchParams.set(qs, tabs[key].key)
                                    setSearchParams(searchParams, { replace: false })
                                }}>
                                    <p
                                        className={styleTabs}
                                        aria-current="page">
                                        {tabs[key].title}
                                    </p>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <TabContainer className={asClassName}>{get(tabs, `[${state.activeIndex}].pane`, <p>This section doesn't exist</p>)}</TabContainer>
        </TabsContextProvider>
    )
}