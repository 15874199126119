import { findIndex } from "lodash";

/**
 * Create or replace an element inside an array
 *
 * @param array
 * @param element
 * @param key
 */
export function upsert(array: any[], element: any, key: string): any[] {
    const arr = Array.from(array);
    const i = findIndex(arr, { [key]: element[key] });
    if (i > -1) {
        arr[i] = element;
    } else {
        arr.push(element);
    }

    return arr;
}