import numeral from "numeral";

export const formatMoney = (number: number | undefined) => {
    if (number === undefined) {
        return numeral(0).format(`($0 a)`).toUpperCase();
    }
    if (number < 1000000) {
        return numeral(number).format(`($0 a)`).toUpperCase();
    }
    return numeral(number).format(`($0.0 a)`).toUpperCase();
};