import React from "react";
import { NavBar } from "../../components/NavBar";
import { useParams } from "react-router-dom";
import { Tabs } from "../../elements/Tabs";
import { useQuery } from "@apollo/client";
import { FETCH_COMPANY_BY_ID } from "../../queries/organizations";
import { OrganizationProfileComponent } from "./components/OrganizationProfile";
import { get } from "lodash";
import { OrgSidebarFragment } from "./fragments/OrgSidebarFragment";
import { OrgPortfolioFragment } from "./fragments/OrgPortfolioFragment";
import { OrgsDealHistoryFragment } from "./fragments/OrgDealsHistoryFragment";
import { OrgFundingFragment } from "./fragments/OrgFundingFragment";
import { OrgImpactFragment } from "./fragments/OrgImpactFragment";
import { OrgTeamFragment } from "./fragments/OrgTeamFragment";

export default function OrganizationContainer() {
    const params = useParams()
    const { data, loading } = useQuery(FETCH_COMPANY_BY_ID, {
        variables: {
            id: params.id,
        },
    });
    const org = get(data, 'organization', {})

    return (
        <div className="h-full min-h-screen">
            <NavBar />
            {
                loading && (
                    <p>Loading...</p>
                )
            }
            {
                !loading && (
                    <div className="pt-[60px] min-h-screen h-full flex">
                        <div className="flex flex-col lg:flex-row grow">
                            <div className="flex lg:w-[350px] lg:max-w-[350px] lg:grow bg-ab-teal25">
                                <OrgSidebarFragment org={org} />
                            </div>
                            <div className="flex flex-col grow max-w-[100vw]">
                                {
                                    (org.type === "startup" || org.type === "" || org.type === "ngo_charity") && (
                                        <Tabs index="profile" tabs={{
                                            profile: {
                                                key: "profile", pane: <OrganizationProfileComponent org={org} />, title: "Profile"
                                            },
                                            funding: {
                                                key: "funding", pane: <OrgFundingFragment org={org} deals={get(org, 'deals', [])} />, title: "Funding"
                                            },
                                            team: {
                                                key: "team", pane: <OrgTeamFragment org={org} />, title: "Team"
                                            },
                                            impact: { key: "impact", pane: <OrgImpactFragment org={org} />, title: "Impact Metrics" },
                                        }} as="div" asClassName="container-ab" />
                                    )
                                }
                                {
                                    (org.type === "investor" || org.type === "hub") && (
                                        <Tabs index="profile" tabs={{
                                            profile: {
                                                key: "profile", pane: <OrganizationProfileComponent org={org} />, title: "Profile"
                                            },
                                            portfolio: {
                                                key: "portfolio", pane: <OrgPortfolioFragment org={org} />, title: "Portfolio"
                                            },
                                            deals: {
                                                key: "deals", pane: <OrgsDealHistoryFragment org={org} />, title: "Deal History"
                                            },
                                            impact: { key: "impact", pane: <OrgImpactFragment org={org} />, title: "Impact Metrics" },
                                        }} as="div" asClassName="container-ab" />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}