import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import OrganizationsContainer from './containers/Organizations/OrganizationsContainer';
import { client } from "./networking/graphql";
import { EMAnalysisContainer } from './containers/EMAnalysis/EMAnalysisContainer';
import DealsContainer from './containers/Deals/DealsContainer';
import EventsDirectory from './containers/Events/EventsDirectory';
import { EventDetailsContainer } from './containers/Events/EventDetails';
import OrganizationContainer from './containers/Organizations/OrganizationContainer';
import { AuthProvider, useAuth } from './context/AuthContext';
import { SignInUpContainer } from './containers/Login/LoginContainer';
import { PasswordResetContainer } from './containers/Login/PasswordResetContainer';
import RegisterContainer from './containers/Login/RegisterContainer';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { ResearchInsightContainer, ResearchInsightsContainer } from './containers/ResearchInsights';
import { get } from 'lodash';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const routes = [
  {
    path: "/",
    element: <Navigate to="/organizations" />
  },
  {
    path: "/organizations",
    element: <OrganizationsContainer />,
  },
  {
    path: "/organizations/:id",
    element: <OrganizationContainer />,
  },
  {
    path: "/deals",
    element: <DealsContainer />,
  },
  {
    path: "/events",
    element: <EventsDirectory />,
  },
  {
    path: "/events/:id",
    element: <EventDetailsContainer />,
  },
  {
    path: "*",
    element: <Navigate to="/organizations" />,
  },
  {
    path: "/comparison",
    element: <EMAnalysisContainer />,
  },
  {
    path: "/research",
    element: <ResearchInsightsContainer />
  },
  {
    path: "/research/resources/:id",
    element: <ResearchInsightContainer />,
  },
];

const AgBase = () => {
  const { session } = useAuth()

  if (!session?.id) {
    return (
      <>
        <Routes>
          <Route path='/sign-in' element={<SignInUpContainer />} />
          <Route path='/sign-up' element={<RegisterContainer />} />
          <Route path='/password-reset' element={<PasswordResetContainer />} />
          <Route path='*' element={<Navigate to="/sign-in" />} />
        </Routes>
      </>
    )
  }

  return (
    <Routes>
      {routes.map((r) => (
        <Route key={r.path} path={r.path} element={r.element} />
      ))}
    </Routes>
  )
}

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <AuthProvider>
          <AgBase />
        </AuthProvider>
      </BrowserRouter>
    </ApolloProvider>
    <CookieConsent
      overlay
      containerClasses='container-ab mx-auto flex !items-center'
      cookieName='agabse-cookie-consent'
      style={{ background: "#2B373B" }}
      buttonStyle={{ background: "#057a7e", color: "#fffff", borderRadius: "0.25rem" }}
    >
      <p>This website employs solely technical cookies that are indispensable for its fundamental operations.</p>
      <p>By continuing to utilize this website, you hereby consent to the usage of these technical cookies.</p>
    </CookieConsent>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
