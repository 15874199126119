import React, { useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";
import { CHECK_OTP, LOGIN } from "../../../queries/auth";

type TLoginEmailInputs = {
    username: string
    password: string
}

type TOTPInputs = {
    id: string
    otp: string;
}

export const LoginForm = ({ onSuccess }: any) => {
    const { check } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        register: loginRegister,
        handleSubmit: loginHandleSubmit
    } = useForm<TLoginEmailInputs>({
        defaultValues: {
            username: searchParams.get('email') as string
        }
    })

    const {
        register: otpRegister,
        handleSubmit: otpHandleSubmit
    } = useForm<TOTPInputs>()

    const [state, setState] = useState({

        sessionID: null,
        otp_expire: null,
        first_name: null,
        error: null
    })

    const [login, { loading, error, data, reset }] = useMutation(LOGIN)

    const [checkOTP, { error: OTPError, loading: OTPLoading }] = useMutation(CHECK_OTP);


    const onLoginSubmit = (data: any) => {
        login({ variables: { ...data } }).then(r => {
            setState({
                sessionID: r.data.login.id,
                first_name: r.data.login.user.first_name,
                otp_expire: r.data.login.otp_expire_at,
                error: null
            })
        }).catch((e: any) => {
            setState({
                ...state,
                error: e.message
            })
        });
    }

    const onOTPSubmit = (data: any) => {
        checkOTP({ variables: { ...data } })
            .then((r) => {
                check().then((res: any) => {
                    onSuccess(res)
                })
            })
    }

    if (state.sessionID !== null && state.first_name !== null) {
        return (
            <div className="w-full pt-4">
                <p>Enter the code we sent to the email associated with your account.</p>
                <form onSubmit={otpHandleSubmit(onOTPSubmit)}>
                    <div className="flex flex-col gap-2">
                        <label className="text-ab-grey">
                            OTP (One Time Password)
                        </label>
                        <input
                            type="text" autoComplete="off" placeholder="Check your inbox for an OTP" {...otpRegister("otp")}
                            className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                        />
                        <input
                            type="text" autoComplete="off" {...otpRegister("id")} value={state.sessionID}
                            className="hidden"
                        />
                        <button type="submit" className="bg-ab-lightdarkgreen py-2 px-16 rounded-md text-ab-lightteal block">Submit OTP</button>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div className="w-full pt-4">
            {
                state.error && (
                    <p className="bg-red-200 text-red-800 rounded-md py-1 px-2 my-2">{state.error}</p>
                )
            }
            {
                searchParams.get('email') && (
                    <p className="bg-green-200 text-green-800 rounded-md py-1 px-2 my-2">You can now sign-in</p>
                )
            }
            <form onSubmit={loginHandleSubmit(onLoginSubmit)} className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-ab-grey">
                        Email
                    </label>
                    <input
                        type="text" autoComplete="off" placeholder="Email address" {...loginRegister("username")}
                        className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="">
                        Password
                    </label>
                    <input
                        type="password" autoComplete="off" placeholder="Your password" {...loginRegister("password")}
                        className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                    />
                </div>
                <div className="flex w-full items-center">
                    <div className="w-full md:w-1/2 justify-start flex">
                        <button type="submit" className="bg-ab-lightdarkgreen py-2 px-16 rounded-md text-ab-lightteal block">Sign in</button>
                    </div>
                    <div className="w-full md:w-1/2 justify-end flex">
                        <Link to="/password-reset">Forgot password?</Link>
                    </div>
                </div>
            </form>
        </div>
    )
}