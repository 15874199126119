import React from "react";
import { NavBar } from "../../components/NavBar";
import { DataContainerProvider, useDataContainerContext } from "../../elements/DataContainer";
import { filters } from "./filters";
import { DataContainerFilters } from "../../elements/DataContainer/DataContainerFilters";
import { Tabs } from "../../elements/Tabs";
import { OverviewMaps } from "./components/OverviewMaps";
import OrganizationsDirectoryContainer from "./OrganizationsDirectoryContainer";
import OverviewContainer from "./OrganizationsOverviewContainer";
import classNames from "classnames";
import OrganizationsGridContainer from "./OrganizationsGridContainer";
import OrganizationsDemographicsContainer from "./OrganizationsDemographicsContainer";


const nav = {
    "overview": {
        title: 'Overview',
        key: 'overview',
        pane: (
            <OverviewContainer />
        )
    },
    "directory": {
        title: 'Directory',
        key: 'directory',
        pane: <OrganizationsDirectoryContainer />
    },
    "grid": {
        title: 'Grid',
        key: 'grid',
        pane: <OrganizationsGridContainer />
    },
    "map": {
        title: 'Map',
        key: 'map',
        pane: <OverviewMaps />
    },
    // "demographics": {
    //     title: "Demographics",
    //     key: "demographics",
    //     pane: <OrganizationsDemographicsContainer />
    // }
}

export default function OrganizationsContainer() {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <div className="pt-[60px] min-h-screen h-full flex">
                <DataContainerProvider filters={filters}>
                    <OrganizationsContainerPage />
                </DataContainerProvider>
            </div>
        </div>
    )
}

const OrganizationsContainerPage = () => {
    const { areFiltersVisible, setAreFiltersVisibile } = useDataContainerContext();
    const clsesFilters = classNames({
        "fixed": true,
        "w-[25rem] z-50": areFiltersVisible,
        "xl:w-[2.5rem]": !areFiltersVisible
    })
    const clsesContainer = classNames({
        "overflow-hidden grow": true,
        "max-w-[calc(100vw)] xl:ml-[20rem] xl:w-[calc(100vw-20rem)]": areFiltersVisible,
        "max-w-[calc(100vw)] xl:ml-[2.5rem] xl:w-[calc(100vw-2.5rem)] xl:max-w-[calc(100vw-2.5rem)]": !areFiltersVisible
    })
    return (
        <div className="grow flex">
            <div className={clsesFilters}>
                <DataContainerFilters />
            </div>
            <div className={clsesContainer}>
                <Tabs tabs={nav} index="overview" as="div" asClassName="w-full h-full" />
            </div>
        </div>
    )
}