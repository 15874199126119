import { gql } from "@apollo/client";

export const CHECK_AUTH = gql`
query auth {
    auth {
        id
        user {
            id
            first_name
            other_names
            email
        }
    }
}`


export const CHECK_OTP = gql`
mutation checkOTP($id: ID!, $otp: String) {
    checkOTP(input: { id: $id, otp: $otp }) {
        id
        access_token
        expire_at
        user {
            email
            first_name
            other_names
        }
    }
}
`

export const SIGN_UP = gql`
mutation createUser($input: NewUser!) {
    createUser(input: $input) {
        id
        email
    }
}
`

export const LOGIN = gql`
mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
        id
        otp_expire_at
        status
        user {
            email
            first_name
        }
    }
}
`

export const SEND_PASS_RESET = gql`
mutation sendPasswordReset($email: String!) {
    sendPasswordReset(email: $email) {
        id
    }
}
`

export const LOGOUT = gql`
	mutation logout {
		logout {
			id
		}
	}
`;

export const RESEND_OTP = gql`
	mutation resendOTP($input: InputOTPSession) {
		resendOTP(input: $input) {
			id
		}
	}
`;

export const VERIFY_PASSWORD = gql`
	mutation authVerifyPasswordReset($email: String!, $token: String!) {
		authVerifyPasswordReset(email: $email, token: $token) {
			id
			access_token
			expire_at
			user {
				email
				first_name
				other_names
			}
		}
	}
`;

export const AUTH_UPDATE_PASSWORD = gql`
	mutation authUpdatePassword($new_password: String!) {
		authUpdatePassword(new_password: $new_password) {
			id
		}
	}
`;