import React, { useState } from "react"
import { LoginLayout } from "./LoginLayout"
import { Link } from "react-router-dom"
import { RxCaretLeft } from "react-icons/rx"
import { useForm } from "react-hook-form"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { AUTH_UPDATE_PASSWORD, CHECK_AUTH, CHECK_OTP, SEND_PASS_RESET, VERIFY_PASSWORD } from "../../queries/auth"
import { useInterval } from "usehooks-ts"
import { SpinnerView } from "../../elements/Spinners"

export const PasswordResetContainer = () => {

    const [state, setState] = useState({
        isSubmittingEmail: true,
        email: "",
        isSubmittingOTP: false,
        isChangingPass: false,
        isLogging: false
    })

    const [auth, { loading, data, error, refetch }] = useLazyQuery(CHECK_AUTH);

    useInterval(() => {
        auth().then((res) => {
            if (res.error === undefined) {
                window.location.reload()
            }
        })
    }, state.isLogging ? 1000 : null)

    return (
        <LoginLayout>
            <div className="py-8 w-1/3">
                <p className="text-xl font-medium pb-2">Reset your password</p>
                <Link to="/login" className="flex items-center gap-2 text-base">
                    <RxCaretLeft /> <span className="text-gray-500">Have an account?</span> <span className="text-medium">Back to login</span>
                </Link>
                {state.isLogging && <SpinnerView message="Updating..." />}
                {
                    state.isSubmittingEmail && <PasswordResetForm onSuccess={(email: string) => setState({ ...state, email, isSubmittingEmail: false, isSubmittingOTP: true })} />
                }
                {
                    state.isSubmittingOTP && (
                        <OTPForm email={state.email} onSuccess={() => setState({ ...state, isSubmittingEmail: false, isSubmittingOTP: false, isChangingPass: true, isLogging: true })} />
                    )
                }
                {
                    state.isChangingPass && (
                        <ResetForm onSuccess={() => setState({ ...state, isChangingPass: false, isLogging: true })} />
                    )
                }
            </div>
        </LoginLayout>
    )
}

const ResetForm = ({ onSuccess = () => { } }: any) => {
    const {
        register,
        handleSubmit
    } = useForm()

    const [error, setError] = useState<string>()
    const [authUpdatePassword, { loading }] = useMutation(AUTH_UPDATE_PASSWORD);

    const handle = (data: any) => {
        authUpdatePassword({
            variables: {
                new_password: data.password,
            },
        })
            .then(({ data }) => {
                if (data?.authUpdatePassword) {
                    onSuccess()
                }
            })
            .catch(() => {
                setError("There was an error updating password. Please try again.");
            });
    }

    return (
        <div className="flex flex-col gap-2 w-full py-8">
            <p>Now you can update your password.</p>
            {
                error && (
                    <p className="bg-red-200 text-red-600 py-2 px-8">{error}</p>
                )
            }
            <form onSubmit={handleSubmit(handle)} className="flex flex-col gap-4">
                <input
                    type="password" autoComplete="off" placeholder="Insert your new password" {...register("password")}
                    className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                />
                <div className="flex">
                    <button type="submit" disabled={loading} className="bg-ab-lightdarkgreen py-2 px-16 rounded-md text-ab-lightteal block">Update password</button>
                </div>
            </form >
        </div>
    )
}

const OTPForm = ({ email, onSuccess = () => { } }: any) => {
    const {
        register,
        handleSubmit
    } = useForm()
    const [error, setError] = useState<string>()
    const [authVerifyPasswordReset, { loading }] = useMutation(VERIFY_PASSWORD);

    const handle = (data: any) => {
        authVerifyPasswordReset({
            variables: {
                email: email,
                token: data.otp,
            },
        }).then((res) => {

            if (res.data?.authVerifyPasswordReset && res.data.authVerifyPasswordReset.id) {
                localStorage.setItem("forgotPassword", "true");
                onSuccess({
                    id: res.data?.authVerifyPasswordReset?.id,
                    user: res.data?.authVerifyPasswordReset?.user,
                });
            } else {
                setError("There was an error validating your details. Please try again.");
            }
        }).catch((err) => setError(err.message))
    }

    return (
        <div className="flex flex-col gap-2 w-full py-8">
            <p>Please check your email <span className="font-semibold">{email}</span>, we've sent a One-Time-Password.</p>
            {
                error && (
                    <p className="bg-red-200 text-red-600 py-2 px-8">{error}</p>
                )
            }
            <form onSubmit={handleSubmit(handle)} className="flex flex-col gap-4">
                <input
                    type="text" autoComplete="off" placeholder="Type here the OTP we've sent via email" {...register("otp")}
                    className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                />
                <div className="flex w-1/2 items-end">
                    <button type="submit" disabled={loading} className="bg-ab-lightdarkgreen py-2 px-16 rounded-md text-ab-lightteal block">Submit OTP</button>
                </div>
            </form >
        </div>
    )
}

const PasswordResetForm = ({ onSuccess = () => { }, onFailure }: any) => {
    const {
        register,
        handleSubmit
    } = useForm()
    const [error, setError] = useState<string>()
    const [sendPasswordReset, { loading }] = useMutation(gql`
        mutation sendPasswordReset($email: String!) {
            sendPasswordReset(email: $email) {
                id
            }
        }
    `);
    const handle = (data: any) => {
        sendPasswordReset({
            variables: {
                email: data.email,
            },
        })
            .then(({ data }) => {

                if (data.sendPasswordReset && data.sendPasswordReset.id) {
                    setError(undefined)
                    onSuccess(data.sendPasswordReset.id)
                } else {
                    setError("There was an error validating your email. Please try again.");
                }
            })
            .catch((error) => {

                setError("There was an error on the server. Please try again.");
            });
    }
    return (
        <div className="flex flex-col gap-2 w-full py-8">
            <p>Enter your email and we'll send you instructions on how to reset your password.</p>
            {
                error && (
                    <p className="bg-red-200 text-red-600 py-2 px-8">{error}</p>
                )
            }
            <form onSubmit={handleSubmit(handle)} className="flex flex-col gap-4">
                <input
                    type="text" autoComplete="off" placeholder="Your email address" {...register("email")}
                    className="border border-ab-lightdarkgreen rounded-md py-1.5 px-2 focus:outline-none shadow-sm"
                />
                <div className="flex">
                    <button type="submit" disabled={loading} className="bg-ab-lightdarkgreen py-2 px-16 rounded-md text-ab-lightteal block">Submit request</button>
                </div>
            </form >
        </div>
    )
}