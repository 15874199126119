import React from "react"
//@ts-ignore
import numeral from "numeral"
import { useDataContainerContext } from "../../../elements/DataContainer"
import { StatsCardGQL } from "../../../elements/GraphQL/StatsCardGQL"

export const DealsOverviewCards = () => {
    const { filtersQuery } = useDataContainerContext()
    return (
        <div className="w-full max-w-screen">
            <div className="w-full flex items-center py-4 gap-4">
                <div className="flex flex-wrap items-center w-full gap-4">
                    <StatsCardGQL root="count.value" stat={{ title: "Total no. investments", bgColor: "#f1ffe9", value: "-" }}
                        queryVariables={{
                            agg: {
                                bucket: "deals",
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "count",
                                        type: "cardinality",
                                        params: {
                                            field: "id.keyword",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="sum.value" stat={{ title: "Total $ amount", bgColor: "#e0eeec", value: "-" }}
                        format={(value: any) => numeral(value || 0).format('($ 0.00 a)')}
                        queryVariables={{
                            agg: {
                                bucket: "deals",
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "sum",
                                        type: "sum",
                                        params: {
                                            field: "amount",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="avg.value" stat={{ title: "Average $ investment", bgColor: "#fef3d8", value: "-" }}
                        format={(value: any) => numeral(value).format('($ 0.00 a)')}
                        queryVariables={{
                            agg: {
                                bucket: "deals",
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "avg",
                                        type: "avg",
                                        params: {
                                            field: "amount",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                    <StatsCardGQL root="max.value" stat={{ title: "Largest $ investment", bgColor: "#d9f5ec", value: "-" }}
                        format={(value: any) => numeral(value).format('($ 0.00 a)')}
                        queryVariables={{
                            agg: {
                                bucket: "deals",
                                query: {
                                    filter: filtersQuery,
                                },
                                list: [
                                    {
                                        key: "max",
                                        type: "max",
                                        params: {
                                            field: "amount",
                                        },
                                    },
                                ],
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}