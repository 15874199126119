import { gql } from "@apollo/client";

export const FETCH_COMPANY_BY_ID = gql`
	query organization($id: ID) {
		organization(id: $id) {
			name
			specifier
			profile_image_url
			primary_email_contact
			type
			created_at
			operating_status
			founded
			number_employees
			updated_at
			sdgs {
				id
				name
				img_pic
			}
			impact {
				assistive_tech
				bop
				elderly_people
				employment_creation
				female_cofounder
				female_focused
				fifty_local
				informal_sector
				rural_focused
				youth_focused
				zero_emissions
				id
			}
			business_models {
				id
				name
			}
			ops_countries {
				id
				name
				alpha2
			}
				 employees {
          id
          role
          person {
              id
              first_name
              other_names
              gender
			  socials {
			  linkedin
			  }
          }
      }
			description
			investments {
				id
				date
				equity
				type
				amount
				investors {
					id
					name
					profile_image_url
				}
				target {
					id
					name
					profile_image_url
					type
					ops_countries {
						id
						name
						alpha2
					}
					agbase_category {
						id
						name
					}
					agbase_value_chain {
						id
						name
					}
				}
			}
			deals {
				id
				date
				equity
				type
				amount
				stage
				investors {
					id
					name
					profile_image_url
				}
			}
			sectors {
				id
				value: name
			}
			offices {
				id
				is_hq_regional
				is_hq_primary
				address
				country {
					id
					name
					alpha2
				}
				city {
					id
					name
					subdivision : sub_division {
						id
						name
						country {
							id 
							name
							alpha2
						}
					}
				}
			 }
			socials {
				website
				facebook
				instagram
				youtube
				linkedin
				angellist
			}
			agbase_category {
				id
				name
			}
			agbase_value_chain {
				id
				name
			}
		}
	}
`;