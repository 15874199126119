import { BUCKET_ORGS } from "../../constants";

export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "select-or",
                title: "Type",
                key: "type.keyword",
                id: "type.keyword",
                options: [
                    { key: "startup", value: "startup", title: "Startup" },
                    { key: "investor", value: "investor", title: "Investor" },
                    { key: "hub", value: "hub", title: "Hub" },
                ]
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "operating_status.keyword",
                id: "operating_status.keyword",
                dynamic: false,
                bucket: BUCKET_ORGS,
                options: [
                    {
                        "key": "y",
                        "value": "y",
                        "title": "Operating"
                    },
                    {
                        "key": "n",
                        "value": "n",
                        "title": "Shut down"
                    },
                    {
                        "key": "c",
                        "value": "c",
                        "title": "Under review"
                    }
                ]
            },
            {
                type: "range",
                title: "Founded between",
                key: "founded",
                id: "founded"
            },
            {
                type: "search",
                title: "Organisation name",
                key: "name",
                id: "name"
            },
            {
                type: "select-or",
                title: "Categories",
                key: "agbase_category.name.keyword",
                id: "agbase_category.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "agbase_value_chain.name.keyword",
                id: "agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            }
        ]
    },
    {
        title: "Geography",
        filters: [
            {
                type: "select-or",
                title: "Global HQ Countries",
                key: "offices_hq_global.country.name.keyword",
                id: "offices_hq_global.country.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
            {
                type: "select-or",
                title: "Regional HQ Countries",
                key: "agbase_offices.country.name.keyword",
                id: "agbase_offices.country.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
            {
                type: "select-or",
                title: "Countries of operations",
                key: "ops_countries.country.name.keyword",
                id: "ops_countries.country.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
            {
                type: "select-or",
                title: "Regions of operations",
                key: "ops_countries.region.keyword",
                id: "ops_countries.region.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
        ]
    },
    {
        title: "Impact Metrics",
        filters: [
            {
                title: "SDG name",
                type: "select-or",
                key: "sdgs.name.keyword",
                id: "sdgs.name.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "attributes.organization:ResourceTag/Impact",
                id: "attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: BUCKET_ORGS,
                options: []
            }
        ]
    },
    {
        title: "Funding",
        filters: [
            {
                title: "Funding not available",
                type: "boolean",
                key: "no_funding",
                id: "no_funding",
            },
            {
                type: "select-or",
                title: "Investment type",
                key: "agg_deals.type.keyword",
                id: "agg_deals.type.keyword",
                dynamic: true,
                options: [],
                bucket: BUCKET_ORGS
            },
            {
                title: "Funding received",
                type: "range",
                key: "total_funding",
                id: "total_funding",
            }
        ]
    },
    {
        title: "Founders Information",
        filters: [
            // {
            //     type: "select-or",
            //     title: "Ethnicity",
            //     key: "employees.person.ethnicity.keyword",
            //     id: "employees.person.ethnicity.keyword",
            //     dynamic: true,
            //     bucket: BUCKET_ORGS
            // },
            {
                type: "select-or",
                title: "Gender",
                key: "employees.person.gender.keyword",
                id: "employees.person.gender.keyword",
                dynamic: true,
                bucket: BUCKET_ORGS
            },
            {
                type: "select-or",
                title: "Education Level",
                key: "employees.person.education_level.keyword",
                id: "employees.person.education_level.keyword",
                dynamic: true,
                options: [],
                bucket: BUCKET_ORGS
            },
            {
                type: "select-or",
                title: "Education Degree",
                key: "employees.person.education_degree.keyword",
                id: "employees.person.education_degree.keyword",
                dynamic: true,
                options: [],
                bucket: BUCKET_ORGS
            },

        ]
    }
];