import { IDataGridHeader, IDataGridOptions, IDataGridRow } from "./types";
import React, { useRef } from "react";

export interface TDataGridRow {
    headers: IDataGridHeader[],
    row: IDataGridRow,
    rowNumber: number,
    options: IDataGridOptions,
    isRowSelected?: boolean
    onSelectRow?: (row: any) => void;
}

export const DataGridRow = ({ headers, row, rowNumber, options, onSelectRow, isRowSelected }: TDataGridRow) => {
    const renderCellValue = (h: IDataGridHeader, index: number, isSelectable: boolean) => {
        if (h.render) {
            return h.render(row);
        }

        const dataIndex = row[h.dataIndex];
        if (!Array.isArray(dataIndex)) {
            return dataIndex;
        } else {
            return dataIndex.join(",")
        }
    };

    const handleSelectRow = (row: any) => {
        if (onSelectRow) {
            onSelectRow(row)
        }
    }

    const renderSelectableCell = (isSelectable: boolean) => {
        if (isSelectable) {
            return (
                <div
                    role="gridcell"
                    key={`DataGridRow__row__${rowNumber}__item__1`}
                    style={{ gridColumnStart: 1 }}
                    className="self-center border-b border-b-bs-light-gray-50 items-center justify-center flex h-full"
                >
                    <input type="checkbox" checked={isRowSelected} onClick={() => handleSelectRow(row)} />
                </div>
            );
        }

        return null;
    };

    const isSelectable = options && options.isSelectable || false;

    const rowRef = useRef<HTMLDivElement>(null)

    return (
        <div role="row" className="contents text-nowrap group hover:bg-slate-200" style={{ gridRowStart: rowNumber + 2, }} ref={rowRef}>
            {renderSelectableCell(isSelectable)}
            {headers.map((h: IDataGridHeader, index: number) => (
                <div
                    role="gridcell"
                    key={`DataGridRow__row__${rowNumber}__item__${index + (isSelectable ? 2 : 1)
                        }`}
                    style={{
                        gridColumnStart: index + (isSelectable ? 2 : 1),
                        // overflow: "hidden",
                        // minWidth: "280px",
                    }}
                    className="self-center border-b border-b-[#F5F5F5] items-center flex h-full px-3 py-3 group-hover:bg-[#fef3d8] hover:bg-[#f1ebd1]"
                >
                    {renderCellValue(h, index, isSelectable)}
                </div>
            ))}
        </div>
    );
};