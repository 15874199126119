import { gql, useLazyQuery, useQuery } from "@apollo/client"
import { NavBar } from "../../components/NavBar"
import { DataContainerProvider, useDataContainerContext } from "../../elements/DataContainer"
import { useProjectDocumentTitle } from "../../utils/document"
import { SEARCH_QUERY } from "../../queries/search"
import { find, flatten, flattenDeep, get, xor } from "lodash"
import { Link, useNavigate, useParams } from "react-router-dom"
import { getHtmlFromLexicalJSON } from "../../utils/lexicon"
import { format } from "date-fns"
import { FiChevronLeft, FiChevronsRight, FiDownload } from "react-icons/fi"
import { filters } from "./filters"
import { DataContainerFilters } from "../../elements/DataContainer/DataContainerFilters"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { SpinnerView } from "../../elements/Spinners"

export const ResearchInsightsContainer = () => {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <div className="pt-[60px] min-h-screen h-full flex">
                <DataContainerProvider filters={filters}>
                    <ResearchInsightsPage />
                </DataContainerProvider>
            </div>
        </div>
    )
}


const ResearchInsightsPage = () => {
    const { filtersQuery } = useDataContainerContext()
    const { areFiltersVisible, setAreFiltersVisibile } = useDataContainerContext();
    const clsesFilters = classNames({
        "fixed": true,
        "w-20rem": areFiltersVisible,
        "xl:w-[2.5rem]": !areFiltersVisible
    })
    const clsesContainer = classNames({
        "overflow-hidden grow": true,
        "ml-[20rem] w-[calc(100vw-20rem)]": areFiltersVisible,
        "xl:ml-[2.5rem] xl:w-[calc(100vw-2.5rem)] xl:max-w-[calc(100vw-2.5rem)]": !areFiltersVisible
    })
    useProjectDocumentTitle("Research & Insights Hub")

    const { data, loading, error } = useQuery(SEARCH_QUERY, {
        variables: {
            search: {
                bucket: "resources",
                size: 100,
                sort: [
                    {
                        key: "published_at",
                        dir: "desc",
                    },
                ],
                query: {
                    filter: filtersQuery
                },
            },
        }
    });

    return (
        <div className="grow flex">
            <div className={clsesFilters}>
                <DataContainerFilters />
            </div>
            <div className={clsesContainer}>
                <div className="px-10">
                    {loading && (
                        <SpinnerView message="Loading resources..." />
                    )}
                    {get(data, 'search.items', []).map((report: any) => {
                        return (
                            <ResearchCard resource={report} key={report.id} />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export const ResearchFilterableTag = ({ className, name, id, filterKey }: { className?: string, name: string, id: string, filterKey: string }) => {
    const { addFilterAndApply, removeFilterAndApply, filtersActive } = useDataContainerContext()
    const activeFilterKey = get(find(filtersActive, { 'key': filterKey }), 'value', []) as []
    //@ts-ignore
    const isActive = activeFilterKey !== undefined && activeFilterKey.includes(name)

    return (
        <button
            className={classNames({
                "uppercase  px-2 rounded-md py-0.5 text-xs": true,
                "bg-ab-darkgreen text-white": isActive,
                "hover:bg-ab-lightgreen hover:text-white text-ab-darkgreen bg-ab-lime": !isActive
            })}
            key={id}
            onClick={(e) => {
                e.preventDefault()
                const filterValues = xor(activeFilterKey, [name]) as []
                if (filterValues.length > 0) {
                    addFilterAndApply({ type: 'select-or', key: filterKey, value: xor(activeFilterKey, [name]) })
                } else {
                    removeFilterAndApply({ type: 'select-or', key: filterKey, value: [] })
                }
            }}
        >
            {name}
        </button>
    )
}

export const ResearchCard = ({ resource }: any) => {
    const URL = ['/research', resource?.index, resource.id].join("/")
    const navigate = useNavigate()
    return (
        <article className="flex flex-col md:flex-row md:gap-8 border-t border-t-gray-100 py-8 flex-nowrap group">
            <img src={resource?.image_preview} className="aspect-video object-cover min-w-[225px] lg:max-w-[225px] bg-gray-100 rounded-sm" />
            <div className="grow flex flex-col py-2 items-start">
                <div className="flex grow flex-col pb-4">
                    <p className="flex gap-2 pb-2 flex-wrap">
                        {
                            get(resource, 'resource_type', [])?.map((i: any) => {
                                return <ResearchFilterableTag name={i.name} id={i.id} key={i.id} filterKey="resource_type.name.keyword" />
                            })
                        }
                        {
                            get(resource, 'agbase_category', [])?.map((i: any) => {
                                return <ResearchFilterableTag name={i.name} id={i.id} key={i.id} filterKey="agbase_category.name.keyword" />
                            })
                        }
                        {
                            get(resource, 'agbase_value_chain', [])?.map((i: any) => {
                                return <ResearchFilterableTag name={i.name} id={i.id} key={i.id} filterKey="agbase_value_chain.name.keyword" />
                            })
                        }
                    </p>
                    <h1 className="text-2xl pb-2 hover:underline hover:cursor-pointer">
                        <Link to={URL}>{resource?.title}</Link>
                    </h1>
                    <div className="text-base leading-tight hover:bg-gray-50 hover:cursor-pointer text-ab-grey grow line-clamp-3" onClick={(ev) => {
                        ev.preventDefault()
                        navigate(URL)
                    }}>
                        {
                            resource?.description && (
                                <div dangerouslySetInnerHTML={{ __html: getHtmlFromLexicalJSON(resource?.description || '') }} />
                            )
                        }
                    </div>
                </div>
                <div className="flex items-center gap-2 text-sm">
                    <p>
                        {
                            get(resource, 'authors', []).map((a: any, index: number) => (
                                <span className="font-medium" key={index}>{a.display_name}{index !== get(resource, 'authors', []).length - 1 && ", "}</span>
                            ))
                        }
                    </p>
                    <p className="text-ab-grey">{format(new Date(resource?.published_at), 'PPP')}</p>
                </div>
            </div>
            <div className="hidden md:flex items-center hover:cursor-pointer" onClick={(ev) => {
                ev.preventDefault()
                navigate(URL)
            }}>
                <FiChevronsRight className="self-center h-10 w-10 group-hover:text-ab-lightgreen text-gray-300" />
            </div>
        </article>
    )
}

export const ResearchInsightContainer = () => {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <ResearchInsightView />
        </div>
    )
}

export const ResearchInsightView = () => {
    const [report, setReport] = useState({})
    const params = useParams()

    const [getReport, { data, loading, error }] = useLazyQuery(GET_REPORT_BY_ID)

    useEffect(() => {
        getReport({
            variables: {
                id: params.id
            }
        }).then((res) => {
            setReport(get(res, 'data.getResourceUploadByID', {}))
        })
    }, [params.id])

    if (loading) {
        return <SpinnerView message="Loading report..." />
    }
    return (
        <div className="pt-[60px] min-h-screen h-full flex">
            <div className="px-10">
                <div className="py-4 max-w-agbase w-full mx-auto flex flex-col gap-4">
                    <p className="font-medium">
                        <Link to="/research" className="flex items-center gap-4">
                            <FiChevronLeft /> <span>RESEARCH AND INSIGHTS HUB</span>
                        </Link>
                    </p>
                </div>
                <div className="py-4 max-w-agbase w-full mx-auto flex gap-10">
                    <div className="w-3/4">
                        <ReportSingleView report={report} />
                    </div>
                    <div className="w-1/4 border-l">
                        <div className="px-10">
                            <p className="font-medium text-base pb-4">RELATED RESEARCH & INSIGHTS</p>
                            <p>No other readings suggested</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ReportSingleView = ({ report }: any) => {
    return (
        <div className="flex flex-col lg:flex-row gap-0 lg:gap-16">
            <div className="flex flex-col lg:w-full gap-2">
                <h1 className="text-4xl pt-4 lg:pt-0 font-medium md:leading-10 pb-2 grow">
                    {get(report, 'title', '')}
                </h1>
                <Link to={get(report, 'path', '')} target="_blank"
                    className="bg-ab-lightteal hover:bg-ab-darkergreen hover:text-white justify-center flex gap-2 items-center text-black px-2 py-1.5 text-base rounded-md"
                >
                    <FiDownload /> Download resource
                </Link>
                <div className="flex gap-12">
                    <div className="flex-grow flex flex-col">
                        <p className="flex gap-4 items-center">
                            {
                                get(report, 'authors', []).map((a: any, index: number) => (
                                    <span className="font-medium" key={index}>{a.display_name}{index !== get(report, 'authors', []).length - 1 && ", "}</span>
                                ))
                            }
                            <span className="text-ab-grey">{format(new Date(get(report, 'published_at', '2024')), 'PPP')}</span>
                        </p>
                        <div className="py-4">
                            <div className="w-full flex flex-wrap gap-4">
                                {
                                    get(report, 'resource_type', [])?.map((i: any) => {
                                        return (
                                            <span
                                                className="uppercase text-xs bg-ab-grey px-2 rounded-md py-0.5 text-white"
                                                key={i.namek}>{i?.value}</span>
                                        )
                                    })
                                }
                                {
                                    get(report, 'agbase_category', [])?.map((i: any) => {
                                        return (
                                            <span
                                                className="uppercase text-xs bg-ab-lime px-2 rounded-md py-0.5 text-ab-darkgreen"
                                                key={i.namek}>{i?.value}</span>
                                        )
                                    })
                                }
                                {
                                    get(report, 'agbase_value_chain', [])?.map((i: any) => {
                                        return (
                                            <span
                                                className="uppercase text-xs bg-ab-lightteal px-2 rounded-md py-0.5 text-ab-darkgreen"
                                                key={i.name}>{i?.value}</span>
                                        )
                                    })
                                }
                                <div className="text-base">
                                    <img
                                        className="w-lg:max-h-96 object-cover lg:object-contain float-right p-4"
                                        src={get(report, 'image_preview', '')} />
                                    {
                                        get(report, 'description', undefined) && (
                                            <div dangerouslySetInnerHTML={{ __html: getHtmlFromLexicalJSON(get(report, 'description', '') || '') }} />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const GET_REPORT_BY_ID = gql`
    query getResourceUploadByID($id: ID!) {
    getResourceUploadByID(id: $id) {
        id
        description
        title
        is_premium
        history
        path
        is_spotlight
        tags {
            id
            value: name
        }
        ops_countries {
            id
            value: name
        }
        resource_type {
            id
            value: name
            name
        }
        authors {
            id
            value: display_name
            display_name
        }
        agbase_visibility {
            id
            value: name
        }
        agbase_value_chain {
            id
            value: name
        }
        agbase_category {
            id
            value: name
        }
        sectors {
            id
            value: name
        }
        sub_sectors {
            id
            value: name
        }
        sdgs {
            id
            value: name
        }
        technologies {
            id
            value: name
        }
        status
        slug
        image_preview
        published_at
    }
}
`