import React, { createContext, ReactNode } from "react";

export interface ITabsContext {
    tabs: any;
    activeKey: string;
}

const TabsContext = createContext<ITabsContext>({} as ITabsContext);

export function TabsContextProvider({ children }: { children: ReactNode }) {

    return (
        <TabsContext.Provider value={{} as ITabsContext}>
            {children}
        </TabsContext.Provider>
    )
}