import React from "react";
import { TEvent } from "../types";
import { FlagIcon } from "react-flag-kit";
import { differenceInDays, format, intervalToDuration } from "date-fns";

type EventsRegisterProps = {
    event: TEvent;
};
const EventsRegister: React.FC<EventsRegisterProps> = ({ event }) => {

    const { hours, minutes } = intervalToDuration({
        end: new Date(event.start_at),
        start: new Date()
    })

    const days = differenceInDays(new Date(event.start_at), new Date());

    const registerToEvent = () => {
        window.open(event.uri, "_blank");
    };

    const getEventType = (type: string) => {
        switch (type) {
            case "IN-PERSON":
                return "In Person";
            case "ONLINE":
                return "Online";
            case "CFA":
                return "CFA";
            default:
                return "";
        }
    };

    const formarts = event?.formarts?.map((formart) => formart?.name).join(", ");

    return (
        <div className='lg:w-1/3 w-full overflow-x-auto rounded-lg bg-white p-6' style={{ boxShadow: "0px 10px 20px 0 rgba(0,0,0,0.1)" }}>
            <p className='text-sm font-medium text-left text-gray-3400'>Event starts in</p>

            <div className='w-full grid grid-cols-3 mt-4 gap-4'>
                <div className='w-full h-20 rounded bg-[#f4f4f4] flex flex-col items-center justify-center'>
                    <p className='text-2xl font-medium text-center text-gray-4100'>{(!days || days < 1) ? "-" : days}</p>
                    <p className='text-sm text-center text-gray-3400'>day(s)</p>
                </div>
                <div className='w-full h-20 rounded bg-[#f4f4f4] flex flex-col items-center justify-center'>
                    <p className='text-2xl font-medium text-center text-gray-4100'>{(!hours || hours < 1) ? "-" : hours}</p>
                    <p className='text-sm text-center text-gray-3400'>hours</p>
                </div>
                <div className='w-full h-20 rounded bg-[#f4f4f4] flex flex-col items-center justify-center'>
                    <p className='text-2xl font-medium text-center text-gray-4100'>{(!minutes || minutes < 1) ? "-" : minutes}</p>
                    <p className='text-sm text-center text-gray-3400'>minutes</p>
                </div>
            </div>

            <div className='flex flex-col justify-start items-start gap-[5px] mt-4'>
                <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Location</p>
                <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100'>
                    <span className='flex gap-2 text-sm font-medium text-left text-gray-4100 flex-wrap'>
                        {event?.countries_of_interests?.map((country) => (
                            <span className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100' key={country?.id}>
                                <FlagIcon code={country?.alpha2} /> {country?.name}
                            </span>
                        ))}
                    </span>
                    <br />
                </p>
            </div>

            <div className='flex flex-col justify-start items-start gap-[5px] mt-2'>
                <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Type</p>
                <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100 overflow-clip'>{getEventType(event.type)}</p>
            </div>

            {event?.formarts?.length > 0 ? (
                <div className='flex flex-col justify-start items-start gap-[5px] mt-2'>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Event formart</p>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100 overflow-clip'>{formarts}</p>
                </div>
            ) : null}

            <div className='flex justify-between items-center gap-[5px] mt-4 flex-wrap'>
                <div className='flex flex-col justify-start items-start gap-[5px]'>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>Begin </p>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100'>
                        {format(new Date(event.start_at), "PPp")}
                    </p>
                </div>
                <div className='flex flex-col justify-start items-start gap-[5px] flex-wrap'>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-3400'>End</p>
                    <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-gray-4100'>
                        {format(new Date(event.end_at), "PPp")}
                    </p>
                </div>
            </div>

            <div
                className='flex justify-center items-start w-full gap-[13px] px-6 py-2.5 rounded bg-ab-darkgreen mt-4 cursor-pointer'
                onClick={registerToEvent}>
                <p className='flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-[#fff]'>Register</p>
            </div>
        </div>
    );
};

export default EventsRegister;