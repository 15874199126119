
import { useQuery } from "@apollo/client";

import { find, flatten, get, has } from "lodash";
import { Link } from "react-router-dom";
import { formatMoney } from "../../utils/numbers";
//@ts-ignore
import { toPng } from 'html-to-image';
import { useCallback, useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import { FiDownload } from "react-icons/fi";
import { SEARCH_QUERY } from "../../queries/search";
import { useDataContainerContext } from "../../elements/DataContainer";
import { BUCKET_ORGS } from "../../constants";

const getTextForFunding = (funding: any) => {
    if (has(funding, 'value.gte') && has(funding, 'value.lte')) {
        return <span className="inline">between <span className="font-semibold">{formatMoney(get(funding, 'value.gte', 0))}</span> and <span className="font-semibold">{formatMoney(get(funding, 'value.lte'))}</span></span>
    }
    if (has(funding, 'value.gte')) {
        return <span className="inline">over <span className="font-semibold">{formatMoney(get(funding, 'value.gte', 0))}</span></span>
    }
    if (has(funding, 'value.lte')) {
        return <span className="inline">less <span className="font-semibold">{formatMoney(get(funding, 'value.lte', 0))}</span></span>
    }

    return <></>
}

const HoverSize = ({ size, onClick, max }: { size: number, onClick: (s: number) => void, max: number }) => {
    const ref = useRef(null)
    const hover = useHover(ref)
    return (
        <span ref={ref} className="relative">
            <span className="underline-offset-2 underline cursor-pointer">
                Top {size}
            </span>
            {
                [10, 25, 50, 100, 200].filter((v: number) => v !== size && v <= max).length > 0 && hover && (
                    <ul className="absolute top-6 left-0 bg-[#ffffff] rounded-md shadow-lg px-4 py-4 min-w-fit whitespace-nowrap">
                        {
                            [10, 25, 50, 100, 200].filter((v: number) => v !== size && v <= max).map((v: number) => {
                                return (
                                    <li className="text-normal py-1 cursor-pointer hover:underline" onClick={(ev) => {
                                        ev.preventDefault()
                                        onClick(v)
                                    }}>Top {v}</li>
                                )
                            })
                        }
                    </ul>
                )
            }
        </span>
    )
}

export default function OrganizationsGridContainer() {
    const { filtersActive, filtersQuery } = useDataContainerContext()
    const [size, setSize] = useState<number>(50);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null)
    const onButtonClick = useCallback(() => {
        setIsExporting(true)
        if (ref.current === null) {
            return
        }

        toPng(ref.current, {
            cacheBust: false,
            fetchRequestInit: {
                cache: 'no-cache',
            },
            filter: (node: HTMLElement) => {
                const exclusionClasses = ['web-only-no-image'];
                return !exclusionClasses.some((classname) => node.classList?.contains(classname));
            },
            imagePlaceholder: "https://prod-bbi-droom-assets-pub.s3.eu-west-1.amazonaws.com/placeholder-logo.png"
        })
            .then((dataUrl: any) => {
                const link = document.createElement('a')
                link.download = 'export.png'
                link.href = dataUrl
                link.click()
                setIsExporting(false)
            })
            .catch((err: any) => {
                alert("An error occurred. Please try with a different query.");
                console.log(err)
                setIsExporting(false)
            })
    }, [ref])

    const { data, loading, refetch } = useQuery(SEARCH_QUERY, {
        variables: {
            search: {
                bucket: BUCKET_ORGS,
                sort: [{ key: "total_funding", dir: "desc" }, { key: "name.keyword", dir: "asc" }],
                size: size,
                query: {
                    filter: [
                        ...filtersQuery,
                        ...[{
                            terms: {
                                "type.keyword": ["startup"],
                            },
                        }],
                    ]
                }
            }
        },
        fetchPolicy: "cache-and-network",
        // pollInterval: 10000
    });

    const activeSpans = flatten(
        filtersActive.filter((f) => f.type === "select-or")
            .map((f) => f.value)) as string[]
    const fundingFilter = find(filtersActive, { type: "range", key: "total_funding" })

    if (loading) {
        return (
            <div className="container-ab overflow-x-scroll py-3.5"><p>Loading...</p></div>
        )
    }

    const hiddens = get(data, 'search.items', []).length - get(data, 'search.items', []).filter((i: any) => i.profile_image_url).length

    return (
        <div className="container-ab overflow-x-scroll">
            <div ref={ref}>
                <div className="flex items-center flex-row">
                    <div className="flex-grow">
                        <p className="text-[#979AA6] pt-4">{get(data, 'search.total', 0)} total
                            companies</p>
                        <div className="pt-2 pb-6 flex gap-1 items-center">
                            <p className="text-xl inline font-extralight font-manrope">
                                {
                                    <span><HoverSize size={size} onClick={(s: number) => setSize(s)}
                                        max={get(data, 'search.total', 0)} /> </span>
                                }
                                {
                                    activeSpans.length < 1 && (
                                        <span>companies in our database</span>
                                    )
                                }
                                {
                                    activeSpans.length > 0 && (
                                        <span>
                                            companies tagged with <span className="inline-flex gap-1">{
                                                activeSpans.map((v: string) => <span
                                                    className="bg-secondary px-2 py-0.5 rounded-md">{v}</span>)
                                            } </span>
                                        </span>
                                    )
                                }
                                {
                                    fundingFilter && <span className="text-xl inline font-normal font-manrope"> that have
                                        raised {getTextForFunding(fundingFilter)}</span>
                                }
                            </p>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <button
                            onClick={onButtonClick}
                            disabled={isExporting}
                            className="w-full flex flex-row items-center justify-center relative gap-[13px] pl-5 pr-6 py-2.5 rounded bg-button text-primary hover:opacity-80 web-only-no-image">
                            {
                                isExporting && (
                                    <>
                                        <svg aria-hidden="true" role="status"
                                            className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB" />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor" />
                                        </svg>
                                        Saving as image...
                                    </>
                                )
                            }
                            {!isExporting && <><FiDownload /> Screenshot</>}
                        </button>
                    </div>
                </div>
                <div className="grid gap-8 grid-cols-3 sm:grid-cols-6 md:gap-y-8 md:grid-cols-6 xl:grid-cols-8 2xl:grid-cols-10">
                    {
                        get(data, 'search.items', []).filter((i: any) => i.profile_image_url).map((i: any) => {
                            return (
                                <div
                                    key={i.id}
                                    className="sm:border sm:border-[#ededed] h-[60px] w-[60px] sm:h-[60px] sm:w-[60px] rounded-md flex justify-center items-center group">
                                    <Link to={["/organizations", i.id].join("/")} className="rounded-md">
                                        <img
                                            src={i.profile_image_url || `https://placehold.co/200/fde197/2c2c2c?text=${i.name}&font=roboto@3x.png`}
                                            className="max-w-[60px] rounded-md group-hover:hidden"
                                            alt={i.name}
                                            onError={(e) => {
                                                console.log("err", e, i.name)
                                            }}
                                        />
                                        <p className="hidden text-center text-sm group-hover:block overflow-ellipsis">{i.name}</p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    hiddens > 0 && (
                        <p className="py-4 text-xs text-[#979AA6]">{hiddens} hidden {hiddens === 1 ? "company" : "companies"} because of missing logo</p>
                    )
                }
            </div>
        </div>
    )
}