import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FlagIcon } from "react-flag-kit";
import { RxCheckCircled } from "react-icons/rx";
//@ts-ignore
import numeral from "numeral"
import { find, flatten, get, uniq, uniqBy, xorBy } from "lodash";

import { Persona, PersonaHovering } from "../../elements/Persona";
import { DataGridGraphQL } from "../../elements/GraphQL/DataGridGQL";
import { useDataContainerContext } from "../../elements/DataContainer";
import { SEARCH_QUERY } from "../../queries/search";
import { IDataGridHeader } from "../../elements/DataGrid/types";

import { useProjectDocumentTitle } from "../../utils/document";
import { BUCKET_ORGS } from "../../constants";

const defaultHeaders = [
    {
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        sortingKey: "name.keywords",
        render: (value: any) => {

            return (
                <Link to={['/organizations', value.id].join("/")} className="font-semibold">
                    <PersonaHovering text={value.name} size="xs" imageURL={value.profile_image_url}>
                        <div className="w-full">
                            <div className="border-b px-4 py-4 font-semibold">
                                <Persona text={value.name} size="md" imageURL={value.profile_image_url} />
                            </div>
                            <div className="px-4 py-2">
                                <p className="text-xs pb-1 text-gray-400">Business Model</p>
                                <p className="font-medium">{
                                    value?.business_models && value?.business_models.map((b: any) => {
                                        return <span>{b.name}</span>
                                    })
                                }{(!value?.business_models || value?.business_models.length) < 1 && <span className="uppercase">Not recorded</span>}</p>
                            </div>
                            <div className="px-4 py-2">
                                <p className="text-xs pb-1 text-gray-400">Countries of Operation</p>
                                <p className="font-medium flex flex-wrap gap-2">{
                                    value.ops_countries.map((o: any) => {
                                        return (
                                            <span className="pr-2 inline-flex gap-2 text-sm items-center">
                                                <FlagIcon code={o.alpha2} className="object-fit" size={14} /> {o.name}
                                            </span>
                                        )
                                    })
                                }</p>
                                {
                                    (!value?.ops_countries || value?.ops_countries.length) < 1 && (
                                        <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                            No operating countries recorded
                                        </span>
                                    )
                                }
                            </div>
                            <div className="px-4 py-2 pb-4">
                                <p className="text-xs pb-1 text-gray-400">Stage raised</p>
                                <p className="font-semibold text-xs flex flex-wrap gap-2">{
                                    uniqBy(value?.agg_deals, 'type').map((o: any) => {
                                        return (
                                            <span className="inline-flex px-2 py-1 gap-2 items-center uppercase bg-[#bdeddd] rounded-md">
                                                <RxCheckCircled /> {o.type}
                                            </span>
                                        )
                                    })
                                }</p>
                                {
                                    (!value?.agg_deals || value?.agg_deals.length) < 1 && (
                                        <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                            No deals recorded
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </PersonaHovering>
                </Link>
            )
        }
    },
    // {
    //     title: "Investors",
    //     dataIndex: "investors",
    //     key: "investors",
    //     render: (value: any) => {
    //         const investors = uniqBy(flatten(get(value, 'agg_deals', []).map((deals: any) => {
    //             return deals?.investors
    //         })), "id")

    //         if (investors.length < 0) return "-"
    //         if (investors.length > 3) {
    //             return (
    //                 <>
    //                     {
    //                         investors.slice(0, 3).map((i: any) => i.name).join(", ")
    //                     } +{investors.length - 3}
    //                 </>
    //             )
    //         }
    //         return investors.map((i: any) => i.name).join(", ")
    //     }
    // },
    {
        title: "Category",
        dataIndex: "agbase_category",
        key: "agbase_category",
        render: (value: any) => {
            return get(value, 'type', '') === "investor"
                ? uniq(flatten(get(value, 'investments', [])?.map((i: any) => get(i, 'target.agbase_category', [])?.map((s: any) => s.name)))).join(", ")
                : get(value, 'agbase_category', [])?.map((s: any) => s.name).join(", ")
        }
    },
    {
        title: "Value Chain",
        dataIndex: "agbase_value_chain",
        key: "agbase_value_chain",
        render: (value: any) => {
            return get(value, 'type', '') === "investor"
                ? uniq(flatten(get(value, 'investments', [])?.map((i: any) => get(i, 'target.agbase_value_chain', [])?.map((s: any) => s.name)))).join(", ")
                : get(value, 'agbase_value_chain', [])?.map((s: any) => s.name).join(", ")
        }
    },
    {
        title: "Regional Headquarter",
        dataIndex: "location",
        key: "location",
        render: (value: any) => {
            const office = find(get(value, 'offices', []), { is_hq_regional: true }) || { country: { name: "Not available", alpha2: undefined } };

            return (
                <span className="border-r last:border-r-0 pr-2 inline-flex gap-2 text-xs items-center">
                    {office?.country?.alpha2 && <FlagIcon code={office.country.alpha2} className="object-fit rounded-sm" />} {office?.country?.alpha2 ? [office?.city?.name, office?.country?.alpha2].join(", ") : "Not available"}
                </span>
            )
        }
    }
]

const headers = [
    ...defaultHeaders,
    {
        title: "Total Funding",
        dataIndex: "total_funding",
        key: "total_funding",
        render: (values: any) => numeral(values.total_funding).format('($ 0.00 a)')
    },
    {
        title: "No. Deals",
        dataIndex: "deals_no",
        key: "deals_no",
        render: (values: any) => numeral(values.deals_no).format('0,0')
    }
]

export default function OrganizationsDirectoryContainer() {

    const { filtersQuery } = useDataContainerContext();
    const [visibleHeaders, setVisibleHeaders] = useState(defaultHeaders as IDataGridHeader[])

    useProjectDocumentTitle("Organizations Directory")

    return (
        <div className="container-ab overflow-x-scroll">
            <DataGridGraphQL
                availableColums={headers}
                loading={false}
                query={SEARCH_QUERY}
                root="search"
                property="items"
                onColumnsChange={(value: object) => {
                    const visible = xorBy(visibleHeaders, [value], "key") as IDataGridHeader[]
                    setVisibleHeaders(visible)
                }}
                filters={filtersQuery}
                variables={{
                    search: {
                        bucket: BUCKET_ORGS,
                        sort: [{ key: "total_funding", dir: "desc" }],
                        query: {
                            filters: [{ term: { enabled: true } }],
                        },
                    },
                }}
                entityName="organizations"
                options={{
                    colsWidth: "auto"
                }}
                headers={visibleHeaders}
                data={[]}
            />
        </div >
    )
}