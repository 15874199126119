import React, { useState } from "react";
import { RxCaretLeft, RxCaretRight, RxChevronDown, RxChevronUp, RxMixerVertical, RxStarFilled } from "react-icons/rx";
import { useDataContainerContext } from "./context/DataContainerContext";
import classNames from "classnames";
import FilterFactory from "./filters/FilterFactory";
import _ from "lodash";
import { FiFilter } from "react-icons/fi";

const countActiveFilters = (filters: any[], actives: string[]): number => {
    const a = filters?.filter((f) => actives.includes(f.key));
    return a ? a.length : 0;
}

export function DataContainerFilters() {
    const {
        filters,
        resetFilters,
        isDirty,
        filtersActive,
        filtersChanges,
        applyFilters,
        clearAllFilters,
        areFiltersVisible,
        setAreFiltersVisibile
    } = useDataContainerContext();

    const clsContainer = classNames({
        "filters fixed flex flex-col border-r bg-ab-darkergreen text-ab-lightteal h-[calc(100vh-55px)]": true,
        "w-[20rem] flex-grow max-w-[20rem]": areFiltersVisible,
        "hidden xl:block xl:w-10": !areFiltersVisible
    })

    const activeFiltersKeys = filtersActive.map((f) => f.key);
    const changesFilterKeys = filtersChanges.map((f) => f.key);

    return (
        <div className="">
            {
                !areFiltersVisible && (
                    <button
                        className="fixed bottom-0 right-0 mx-4 z-50 flex gap-2 font-medium items-center bg-ab-lightdarkgreen text-ab-lightteal px-4 py-1 justify-center rounded-md my-3"
                        onClick={(ev) => {
                            setAreFiltersVisibile(true)
                            ev.preventDefault();
                        }}
                    >
                        <FiFilter /> Filters
                    </button>
                )
            }
            <div
                className={clsContainer}>
                <button
                    onClick={() => setAreFiltersVisibile(!areFiltersVisible)}
                    className="absolute rounded-full top-2 p-1.5 bg-ab-lime transition-all duration-[250ms] right-0 rounded-r-none text-black text-xl z-10">
                    {
                        !areFiltersVisible && (
                            <RxCaretRight />
                        )
                    }
                    {
                        areFiltersVisible && (
                            <RxCaretLeft />
                        )
                    }
                </button>
                {
                    false && (
                        <div className="w-full pt-4">
                            <div className="w-full">
                                <div className="flex flex-col px-4 w-full">
                                    <div className="flex gap-8 text-ab-darkteal font-medium text-base">
                                        <button className="flex gap-2 items-center text-ab-lime"><RxMixerVertical /> Filters</button>
                                        <button className="flex gap-2 items-center"><RxStarFilled /> Templates</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !areFiltersVisible && (
                        <p className="text-base uppercase -rotate-90 text-ab-lightteal mt-24 tracking-widest">Filters</p>
                    )
                }
                {
                    false && (
                        <div className="flex pt-8 pb-4 gap-4 items-center px-4">
                            <p className="grow text-lg">All Filters</p>
                            {
                                (!isDirty && activeFiltersKeys.length > 0) && (
                                    <button
                                        className="bg-ab-lime text-black text-xs px-2 py-1 rounded-md"
                                        onClick={clearAllFilters}>Save search</button>
                                )
                            }
                        </div>
                    )
                }


                <FilterContainerFactory filters={filters} activeFiltersKeys={activeFiltersKeys} changesFilterKeys={changesFilterKeys} />
                <div className="absolute bottom-0 left-0 right-0 bg-ab-darkergreen border-t border-t-ab-lightdarkgreen">
                    <div className="flex items-center justify-center px-4 w-full gap-4 py-4">
                        {
                            isDirty && (
                                <button className="px-4 py-2 rounded-lg bg-ab-darkgreen text-ab-lightteal" onClick={resetFilters}>Clear All</button>
                            )
                        }
                        {
                            (!isDirty && activeFiltersKeys.length > 0) && (
                                <button className="px-4 py-2 rounded-lg bg-ab-darkgreen text-ab-lightteal" onClick={clearAllFilters}>Clear All</button>
                            )
                        }
                        {
                            isDirty && (
                                <button className="bg-ab-yellow text-black px-4 py-2 rounded-lg" onClick={applyFilters}>Apply Filters</button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


const FilterContainerFactory = ({ filters, activeFiltersKeys, changesFilterKeys }: any) => {
    const { areFiltersVisible } = useDataContainerContext();
    const [indexVisible, setIndexVisible] = useState<number>(0)
    const clsFilters = classNames({
        "px-4 h-full mt-10 pb-16 pt-4 overflow-y-auto": true,
        "hidden": !areFiltersVisible
    })

    return (
        <div className={clsFilters}>
            {
                filters.map((category: any, index: number) => {
                    return <Filter index={index}
                        isVisible={indexVisible} onFilterAccordion={(index: number) => setIndexVisible(index)}
                        category={category}
                        activeFiltersKeys={activeFiltersKeys}
                        changesFilterKeys={changesFilterKeys}
                    />
                })
            }
        </div>
    )

}

const Filter = ({ index, category, activeFiltersKeys, changesFilterKeys, isVisible, onFilterAccordion }: { index: any, category: any, activeFiltersKeys: any, changesFilterKeys: any, isVisible: number, onFilterAccordion: any }) => {
    const {
        filtersActive,
        filtersChanges,
        addFilter,
        removeFilter,
    } = useDataContainerContext();
    const categoryActiveFiltersCount = countActiveFilters(category?.filters, activeFiltersKeys);
    const categoryChangeFilterCount = countActiveFilters(category?.filters, changesFilterKeys);

    const subfilters = _.get(category, 'filters', []);
    return (
        <div className="mb-5" key={category.title}>
            <div className="flex cursor-pointer select-none mb-2.5 items-center"
                onClick={(ev) => {
                    ev.preventDefault();
                    onFilterAccordion(index)
                }
                }>
                <p className="uppercase font-semibold text-xs flex-grow">
                    {category.title}
                    {
                        ((categoryChangeFilterCount > 0) || (categoryActiveFiltersCount > 0)) && (
                            <span
                                className={classNames({
                                    "font-semibold py-0.5 px-2 text-xs ml-2 rounded-md": true,
                                    "bg-ab-yellow text-black": categoryChangeFilterCount === categoryActiveFiltersCount,
                                    "bg-ab-lightteal text-black": categoryChangeFilterCount !== categoryActiveFiltersCount,
                                })}>
                                {categoryChangeFilterCount}
                            </span>
                        )
                    }
                </p>
                {
                    index === isVisible && (
                        <RxChevronUp className="text-2xl bg-ab-lightdarkgreen rounded-md py-1 px-1" />
                    )
                }
                {
                    index !== isVisible && (
                        <RxChevronDown className="text-2xl  rounded-md py-1 px-1" />
                    )
                }
            </div>
            <div
                className="font-normal space-y-3.5 transition-all duration-300 ease-in-out"
                style={{ display: (index === isVisible) ? "block" : "none" }}>
                {
                    subfilters.map((filter: any) => {
                        return (
                            <FilterFactory
                                key={filter.key}
                                filtersChanges={filtersChanges}
                                filtersActive={filtersActive}
                                filter={{ ...filter, addFilter, removeFilter }}
                                addFilter={addFilter}
                                removeFilter={removeFilter}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}