import { FilterTitle } from "./fragments/FilterTitle";
import React, { useEffect, useState } from "react";
import { IFilter } from "./FilterFactory";
import { get } from "lodash";
import classNames from "classnames";

interface IFilterBoolean extends IFilter {
}

export default function FilterBoolean({ ...props }: IFilterBoolean) {
    const { addFilter, removeFilter, title, type, id, defaultValues } = props
    const value = get(defaultValues, 'value', false);
    const [checked, setChecked] = useState(value);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    useEffect(() => {
        if (checked) {
            addFilter({ type, key: id, value: true })
        } else {
            removeFilter({ type, key: id, value: false })
        }
    }, [checked]);

    const clses = classNames({
        "pt-0 px-2": true,
        "text-ab-lightteal": checked
    })

    return (
        <div
            className="text-sm flex items-center py-1">
            <input
                type="checkbox"
                onChange={(ev) => {
                    setChecked(ev.target.checked)
                }}
                checked={checked}
                className="accent-white border border-ab-lightteal rounded-sm bg-white checked:bg-white w-5 h-5"
            />

            <FilterTitle title={title} overrideStyle={clses} />
        </div>
    )
}