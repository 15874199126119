import { useLazyQuery } from "@apollo/client";
import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TEvent } from "./types";
import { RxArrowLeft } from "react-icons/rx";
import { Persona } from "../../elements/Persona";
import { FlagIcon } from "react-flag-kit";
import { GET_EVENT } from "../../queries/events";
import { NavBar } from "../../components/NavBar";
import EventsRegister from "./fragments/EventRegisterFrag";

export const EventDetailsContainer = () => {
    return (
        <div className="h-full min-h-screen">
            <NavBar />
            <EventsDetailsComponent />
        </div>
    )
}

export const EventsDetailsComponent = () => {
    const location = useLocation();
    const [getEvent] = useLazyQuery(GET_EVENT);
    const { state } = location;
    const params = useParams();
    const navigate = useNavigate();

    const [event, setEvent] = React.useState<TEvent | null>(null);

    React.useEffect(() => {
        if (state) {
            // @ts-ignore
            setEvent(state.event);
        } else {
            getEvent({
                variables: {
                    id: params.id,
                },
            }).then((res) => {
                setEvent(res?.data?.initiative);
            });
        }
        // eslint-disable-next-line
    }, [state, navigate, getEvent]);

    return (
        <>
            {event && (
                <div className='flex flex-col items-center justify-start w-full h-full overflow-x-auto mt-12 sm:mt-0'>
                    <div className='flex flex-col items-center justify-start w-full h-[220px] '>
                        <img src={event.banner} alt={event.title} className='w-full h-[220px] object-cover bg-no-repeat' />
                    </div>
                    <div className='w-full flex flex-col items-start lg:flex-row justify-between px-3 md:px-40 sm:px-20 py-8 relative rounded-[30px] bg-white pb-8 gap-4'>
                        <div className='items-center cursor-pointer text-ab-darkgreen'>
                            <Link to='/events' className="text-4xl">
                                <RxArrowLeft />
                            </Link>
                        </div>
                        <div className='lg:w-2/3 w-full h-full sm:mb-20 ml-2'>
                            <p className='text-xl lg:text-[32px] ml-4 sm:ml-0 font-normal text-left text-gray-4100 uppercase'>{event.title}</p>
                            {event.organizers?.length > 0 ? (
                                <p className='w-auto flex justify-start gap-4 text-sm font-normal text-left'>
                                    <span className='w-auto text-sm font-normal text-left text-gray-3400'>Organized by </span>
                                    {event.organizers?.map((org) => (
                                        <span className='text-sm font-normal text-left text-gray-3400' key={org.id}>
                                            <Link to={["/companies", org?.id].join("/")}>
                                                <Persona imageURL={org?.profile_image_url} text={org.name} />
                                            </Link>
                                        </span>
                                    ))}
                                </p>
                            ) : null}
                            <div className='py-6'>
                                <hr />
                            </div>
                            <p className='text-sm font-normal text-left text-gray-4100'>{event?.description}</p>
                            <div className='py-6'>
                                <hr />
                            </div>
                            <p className='text-sm font-normal text-left text-gray-4100'>{event.body}</p>

                            {event?.organizations_speakers?.length > 0 ? (
                                <>
                                    <div className='py-6'>
                                        <hr />
                                    </div>
                                    <p className='text-sm font-normal text-left text-gray-3400'>Speakers</p>
                                    <div className='flex flex-row items-center justify-start gap-4 mt-4'>
                                        {event.organizations_speakers?.map((speaker) => (
                                            <div className='bg-background px-4 py-2 rounded-lg' key={speaker.id}>
                                                <Link to={["/companies", speaker?.id].join("/")}>
                                                    <Persona imageURL={speaker?.profile_image_url} text={speaker.name} />
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : null}

                            {event?.sponsors?.length > 0 ? (
                                <>
                                    <div className='py-6'>
                                        <hr />
                                    </div>
                                    <p className='text-sm font-normal text-left text-gray-3400'>Sponsors</p>
                                    <div className='flex flex-row items-center justify-start gap-4 mt-4'>
                                        {event.sponsors?.map((sponsor) => (
                                            <div className='bg-background px-4 py-2 rounded-lg' key={sponsor.id}>
                                                <Link to={["/companies", sponsor?.id].join("/")}>
                                                    <Persona imageURL={sponsor?.profile_image_url} text={sponsor.name} />
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : null}
                            <div className='w-auto h-auto my-4'>
                                {event?.sectors_of_interests?.length > 0 ? (
                                    <>
                                        <p className='text-sm font-medium text-left text-gray-3400 mt-5'>Sectors</p>
                                        <div className='flex flex-col justify-start items-start gap-2 mt-4'>
                                            <div className='flex justify-start items-center gap-2 flex-wrap'>
                                                {event?.sectors_of_interests?.map((sector) => (
                                                    <div
                                                        className='px-2 py-1 text-sm font-medium rounded-md bg-background text-gray-4100'
                                                        key={sector.id}>
                                                        {sector?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                                {event?.countries_of_interests?.length > 0 ? (
                                    <>
                                        <div className='flex flex-col justify-start items-start gap-[5px] mt-5'>
                                            <p className='text-sm font-medium text-left text-gray-3400'>Countries</p>

                                            <div className='flex justify-start items-center gap-2 flex-wrap'>
                                                {event?.countries_of_interests?.map((country) => (
                                                    <div
                                                        className='px-2 py-1 text-sm font-medium rounded-md bg-background text-gray-4100'
                                                        key={country?.id}>
                                                        <FlagIcon code={country?.alpha2} />
                                                        &nbsp;
                                                        {country?.name}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <EventsRegister event={event} />
                    </div>
                </div>
            )}
        </>
    );
};