import React from "react";
import { useDataContainerContext } from "../../../../elements/DataContainer";
import { Annotation, AnnotationCircleSubject, AnnotationConnector, AnnotationLabel, Axis, BarSeries, Grid, Tooltip, XYChart } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import { gql, useQuery } from "@apollo/client";
import { get } from "lodash";
//@ts-ignore
import { TickLabelProps } from "@visx/axis";
import { scaleLinear } from "@visx/scale";
import { SpinnerChartBlock } from "../../../../elements/Spinners";
import { Text } from "@visx/text";
import { BUCKET_ORGS } from "../../../../constants";
import { formatMoney } from "../../../../utils/numbers";

const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
};

interface INumberInPerspectiveTable {
    maxRows?: number
    tableKey: string;
    tableLabel: string;
}

interface INumberInPerspectiveChart {
    color: string;
    histogramLabel: string;
    histogramFieldKey: string;
    maxBars?: number
    height?: number
}

const tickLabelProps: TickLabelProps<any> = () =>
({
    fill: "#000",
    fontSize: 10,
    textAnchor: "middle",
    verticalAnchor: "start",
    width: 10,

} as const);

const getColorByIndex = (index: number) => {
    const colors = ['#0A2F51', '#0E4D64', '#137177', '#188977', '#1D9A6C',
        '#39A96B', '#56B870', '#74C67A', '#99D492', '#BFE1B0', '#DEEDCF'];
    return colors[index % colors.length]
}

export const NumberInPerspectiveTable = ({ ...props }: INumberInPerspectiveTable) => {
    const { tableKey, maxRows = 10, tableLabel } = props
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: BUCKET_ORGS,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "agg",
                        type: "terms",
                        params: {
                            size: maxRows,
                            field: tableKey,
                        },
                        extra: {
                            aggs: {
                                country: {
                                    terms: {
                                        field: "agbase_offices.country.name.keyword",
                                        order: {
                                            sum: "desc"
                                        }
                                    },
                                    aggs: {
                                        sum: {
                                            sum: {
                                                field: "agg_deals.amount"
                                            }
                                        },
                                        max: {
                                            max: {
                                                field: "agg_deals.amount"
                                            }
                                        },
                                        orgCount: {
                                            cardinality: {
                                                field: "id.keyword"
                                            }
                                        },
                                        dealsCount: {
                                            cardinality: {
                                                field: "agg_deals.id.keyword"
                                            }
                                        },
                                        avg: {
                                            avg: {
                                                "field": "agg_deals.amount"
                                            }
                                        }
                                    }
                                },
                                sum: {
                                    sum: {
                                        field: "agg_deals.amount"
                                    }
                                },
                                max: {
                                    max: {
                                        field: "agg_deals.amount"
                                    }
                                },
                                orgCount: {
                                    cardinality: {
                                        field: "id.keyword"
                                    }
                                },
                                dealsCount: {
                                    cardinality: {
                                        field: "agg_deals.id.keyword"
                                    }
                                },
                                avg: {
                                    avg: {
                                        "field": "agg_deals.amount"
                                    }
                                }
                            }
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",
    });

    console.log(get(data, 'aggregate.aggregations.agg.buckets', []))
    const xydata = get(data, 'aggregate.aggregations.agg.buckets', [])
        .map((datum: any) => ({ x: datum.key, y: get(datum, 'doc_count', 0), ...datum }))

    if (get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0) > 0) {
        xydata.push({
            x: "Others", y: get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0)
        })
    }

    return (
        <table className="table-auto w-full">
            <tr>
                <th>{tableLabel}</th>
                <th>Count</th>
                <th>AVG</th>
                <th>MAX</th>
                <th>SUM</th>
            </tr>
            {
                xydata.map((datum: any) => {
                    console.log(datum)
                    return (
                        <tr key={datum.y}>
                            <td>{datum.x}</td>
                            <td>{datum.y}</td>
                            <td>{formatMoney(datum.avg?.value || 0)}</td>
                            <td>{formatMoney(datum.max?.value || 0)}</td>
                            <td>{formatMoney(datum.sum?.value || 0)}</td>
                        </tr>
                    )
                })
            }
        </table>
    )
}

export const NumberInPerspectiveChart = ({ ...props }: INumberInPerspectiveChart) => {
    const { color, histogramFieldKey, histogramLabel, maxBars = 1000, height = 350 } = props
    const { filtersQuery } = useDataContainerContext()
    const { data, error, loading } = useQuery(gql`
        query ($agg: AggregationRequest!) {
            aggregate(aggregations: $agg) {
                aggregations
                took
            }   
        }
    `, {
        variables: {
            agg: {
                bucket: BUCKET_ORGS,
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "agg",
                        type: "terms",
                        params: {
                            size: maxBars,
                            field: histogramFieldKey,
                        },
                    },
                ],
            },
        },
        fetchPolicy: "network-only",

    });

    const xydata = get(data, 'aggregate.aggregations.agg.buckets', [])
        .map((datum: any) => ({ x: datum.key, y: get(datum, 'doc_count', 0) }))

    if (get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0) > 0) {
        xydata.push({
            x: "Others", y: get(data, 'aggregate.aggregations.agg.sum_other_doc_count', 0)
        })
    }

    const colors = scaleLinear({
        domain: [
            Math.min(...xydata.map((v: any) => v.y)),
            Math.max(...xydata.map((v: any) => v.y)),
        ],
        range: ['#eee', '#015659']
    })

    return (
        <ParentSize className="w-full">
            {(parent) => {
                const xticks = xydata.length > 0 ? parent.width / xydata.length / 20 : 20
                const yticks = xydata.length > 0 ? height / xydata.length / 3 : 20
                return (
                    <XYChart
                        margin={{ bottom: 75, left: 20, right: 0, top: 25 }}
                        width={parent.width} height={height} xScale={{ type: "band", padding: 0.25 }} yScale={{ type: 'linear' }}>
                        <Axis orientation="bottom" numTicks={xticks} tickFormat={(v: string) => v} tickLabelProps={tickLabelProps} />
                        <Axis orientation="left" hideZero hideAxisLine numTicks={yticks} tickFormat={(v: any) => v} />
                        <Grid columns={false} numTicks={data?.length} lineStyle={{ color: "#eee" }} />
                        <BarSeries dataKey={histogramLabel} data={xydata} {...accessors}
                            barPadding={0.3} colorAccessor={(datum: any, index: number) => colors(get(datum, 'y', 0))}
                        />
                        <Tooltip
                            snapTooltipToDatumX
                            snapTooltipToDatumY
                            showVerticalCrosshair
                            showSeriesGlyphs
                            renderTooltip={({ tooltipData }) => (
                                <div className="font-normal cursor-pointer px-2 py-4">
                                    {accessors.xAccessor(tooltipData?.nearestDatum?.datum)}
                                    {': '}
                                    {accessors.yAccessor(tooltipData?.nearestDatum?.datum)}
                                </div>
                            )}
                        />
                        {
                            loading && (
                                <Text verticalAnchor="middle" textAnchor="middle" x={parent.width / 2} y={height / 2}>
                                    Loading chart...
                                </Text>
                            )
                        }
                    </XYChart>
                )
            }}
        </ParentSize>
    )
}