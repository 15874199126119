
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';

export interface FeatureShape {
    type: 'Feature';
    id: string;
    geometry: { coordinates: [number, number][][]; type: 'Polygon' };
    properties: { name: string, adm0_a2: string };
}

export interface ICountrySVG {
    path: string | null;
    feature: FeatureShape;
    onClick: any;
    fill?: string;
    stroke?: string;
}
export const CountryMap = ({ path, feature, onClick, fill, stroke }: ICountrySVG) => {

    const hoverRef = useRef(null)
    const isHover = useHover(hoverRef)

    return (
        <path
            style={{ cursor: "pointer" }}
            ref={hoverRef}
            key={`map-feature-${feature.id}`}
            d={path || ''}
            fill={fill ? fill : isHover ? "url(#pattern_wpxtW)" : "#fdfdfd"}
            stroke={stroke ? stroke : isHover ? "#02BA7E" : "#ccc"}
            strokeWidth={0.5}
            onClick={(ev) => {
                ev.preventDefault();
                onClick(feature)
            }}
        />
    )
}