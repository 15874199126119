import { useQuery } from "@apollo/client";
import React from "react"
import { FETCH_COMPANY_BY_ID } from "../../queries/organizations";
import { get, uniqBy } from "lodash";
import { Spinner } from "../../elements/Spinners";
import { Persona } from "../../elements/Persona";
import { FlagIcon } from "react-flag-kit";
import { RxCheckCircled } from "react-icons/rx";
//@ts-ignore
import numeral from "numeral"

interface IOrgCard {
    id: string;
}

export const OrgCard = ({ id }: IOrgCard) => {
    const { data, error, loading } = useQuery(FETCH_COMPANY_BY_ID, {
        variables: {
            id: id
        },
        fetchPolicy: "cache-and-network"
    })
    const org = get(data, 'organization', {})
    return (
        <div className="flex bg-white">
            {
                loading && (
                    <div className="grow flex items-center justify-center px-4 py-4">
                        <Spinner message="Loading company..." />
                    </div>
                )
            }
            {
                !loading && (
                    <div className="w-96">
                        <div className="border-b px-4 py-4 font-semibold">
                            <Persona text={org?.name} size="md" imageURL={org?.profile_image_url} />
                        </div>
                        <div className="px-4 py-2">
                            <p className="text-xs pb-1 text-gray-400">Business Model</p>
                            <p className="font-medium">{
                                org?.business_models && org?.business_models.map((b: any) => {
                                    return <span>{b.name}</span>
                                })
                            }{(!org?.business_models || org?.business_models.length) < 1 && <span className="uppercase">Not recorded</span>}</p>
                        </div>
                        <div className="px-4 py-2">
                            <p className="text-xs pb-1 text-gray-400">Countries of Operation</p>
                            <p className="font-medium flex flex-wrap gap-2">{
                                org?.ops_countries.map((o: any) => {
                                    return (
                                        <span className="pr-2 inline-flex gap-2 text-sm items-center">
                                            <FlagIcon code={o?.alpha2} className="object-fit" size={14} /> {o?.name}
                                        </span>
                                    )
                                })
                            }</p>
                            {
                                (!org?.ops_countries || org?.ops_countries.length) < 1 && (
                                    <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                        No operating countries recorded
                                    </span>
                                )
                            }
                        </div>
                        <div className="px-4 py-2 pb-4">
                            <p className="text-xs pb-1 text-gray-400">Stage raised</p>
                            <p className="font-semibold text-xs flex flex-wrap gap-2">{
                                uniqBy(org?.deals, 'type').map((o: any) => {
                                    return (
                                        <span className="inline-flex px-2 py-1 gap-2 items-center uppercase bg-[#bdeddd] rounded-md">
                                            <RxCheckCircled /> {o?.type}
                                        </span>
                                    )
                                })
                            }</p>
                            {
                                (!org?.deals || org?.deals.length) < 1 && (
                                    <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                        No deals recorded
                                    </span>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}