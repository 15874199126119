import React, { ChangeEvent, useEffect, useState } from "react";
import { FilterTitle } from "./fragments/FilterTitle";
import { IFilter } from "./FilterFactory";
import { get } from "lodash";
import { FiX } from "react-icons/fi";

/**
 *
 */

interface IFilterRange extends IFilter {
    min?: number;
    max?: number;
}

export default function FilterRange({ ...props }: IFilterRange) {
    const { addFilter, removeFilter, title, type, id, defaultValues, min = 1995, max = 2024 } = props
    const [values, setValues] = useState(get(defaultValues, 'value', {}))

    const update = (ev: ChangeEvent<HTMLInputElement>) => {
        const filterValues = { ...values, [ev.target.name]: ev.target.value }
        setValues(filterValues)
        addFilter({ type, key: id, value: filterValues })
    }

    useEffect(() => {
        if (get(defaultValues, 'value.lte', undefined) || get(defaultValues, 'value.gte', undefined)) {
            setValues(get(defaultValues, 'value', {}))
        }
    }, [defaultValues])

    const Actions = (get(values, 'lte', undefined) || get(values, 'gte', undefined))
        ? <button className="text-ab-lightteal text-xs" onClick={() => {
            removeFilter({ type, key: id, value: {} })
            setValues({})
        }}>x</button> : <></>
    return (
        <div className="w-full max-w-full">
            <FilterTitle title={title} actions={Actions} />
            <div className="flex items-center gap-4 justify-between pt-2">
                <input type="text" name="gte" value={get(values, 'gte', '')} onChange={update}
                    className="bg-ab-darkgreen border border-ab-lightdarkgreen rounded-md text-center outline-ab-darkteal py-1.5 min-w-0"
                    autoComplete="off"
                />
                <span>and</span>
                <input type="text" name="lte" value={get(values, 'lte', '')} onChange={update}
                    className="bg-ab-darkgreen border border-ab-lightdarkgreen rounded-md text-center outline-ab-darkteal py-1.5 min-w-0"
                />
            </div>
        </div>
    )
}