import React, { useEffect, useState } from "react";
import srcAgBaseLogo from "./agbase-logo-dark.png"

export const LoginLayout = ({ children }: any) => {

    return (
        <div className="container-ab">
            <div className="w-full py-8">
                <img src={srcAgBaseLogo} className="max-w-20" />
                <div className="flex">
                    {children}
                </div>
            </div>
        </div>
    )
}