import { gql, useQuery } from "@apollo/client";
import { useDataContainerContext } from "../../../elements/DataContainer";
import { get, times } from "lodash";
//@ts-ignore
import numeral from "numeral"
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FaArrowAltCircleRight, FaArrowRight, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FiArrowDownRight, FiArrowRight, FiArrowUpLeft } from "react-icons/fi";

export interface IEMTable {
    pivot: string;
    pivotLabel: string;
}

export const EMTable = ({ pivot, pivotLabel }: IEMTable) => {
    const { filtersQuery } = useDataContainerContext()
    const [isCollapsedAll, setIsCollapsedAll] = useState<boolean>(true)
    const { data, error, loading } = useQuery(gql`
    query ($agg: AggregationRequest!) {
        aggregate(aggregations: $agg) {
            aggregations
            took
        }   
    }

    `, {
        variables: {
            agg: {
                bucket: "deals",
                query: {
                    filter: filtersQuery,
                },
                list: [
                    {
                        key: "rows",
                        type: "terms",
                        params: {
                            field: pivot,
                            size: 100,
                            order: {
                                sum: "desc"
                            }
                        },
                        extra: {
                            aggs: {
                                country: {
                                    terms: {
                                        field: "target.agbase_offices.country.name.keyword",
                                        order: {
                                            sum: "desc"
                                        }
                                    },
                                    aggs: {
                                        sum: {
                                            sum: {
                                                field: "amount"
                                            }
                                        },
                                        max: {
                                            max: {
                                                field: "amount"
                                            }
                                        },
                                        orgCount: {
                                            cardinality: {
                                                field: "target.id.keyword"
                                            }
                                        },
                                        dealsCount: {
                                            cardinality: {
                                                field: "id.keyword"
                                            }
                                        },
                                        avg: {
                                            avg: {
                                                "field": "amount"
                                            }
                                        }
                                    }
                                },
                                sum: {
                                    sum: {
                                        field: "amount"
                                    }
                                },
                                max: {
                                    max: {
                                        field: "amount"
                                    }
                                },
                                orgCount: {
                                    cardinality: {
                                        field: "target.id.keyword"
                                    }
                                },
                                dealsCount: {
                                    cardinality: {
                                        field: "id.keyword"
                                    }
                                },
                                avg: {
                                    avg: {
                                        "field": "amount"
                                    }
                                }
                            }
                        },
                    },
                ],
            },
        },
        fetchPolicy: "cache-and-network",

    });

    const thcls = classNames({
        "text-sm text-left bg-ab-lightteal text-ab-darkergreen px-2 py-2 uppercase first:rounded-l-md last:rounded-r-md group-hover/highlight:bg-gray-200": true
    })

    return (
        <div className="w-full flex flex-col py-5">
            <table className="w-full text-base table-auto">
                <thead>
                    <tr className="">
                        <th className={[thcls, "flex items-center gap-2"].join(" ")}>{pivotLabel} {
                            isCollapsedAll
                                ? <button className="text-xs font-normal flex items-center gap-0.5 cursor-pointer" onClick={((ev) => { ev.preventDefault(); setIsCollapsedAll(false) })}>
                                    expand all <FiArrowDownRight />
                                </button>
                                : <button className="text-xs font-normal flex items-center gap-0.5 cursor-pointer" onClick={((ev) => { ev.preventDefault(); setIsCollapsedAll(true) })}>
                                    collapse all <FiArrowUpLeft />
                                </button>
                        }</th>
                        <th className={thcls}>Total Amount</th>
                        <th className={thcls}># of Companies</th>
                        <th className={thcls}># of Deals</th>
                        <th className={thcls}>Max Deal Amount</th>
                        <th className={thcls}>Average Deal Size</th>
                    </tr>
                </thead>
                {
                    !loading && get(data, 'aggregate.aggregations.rows.buckets', []).map((row: any, index: number) => {
                        return <EMTablRow key={row.key} row={row} index={index} isCollapsedAll={isCollapsedAll} />
                    })
                }
            </table>
            {
                loading && times(5).map((i) => {
                    const cls = classNames({
                        "h-5 bg-gray-200 animate-pulse odd:bg-gray-100 rounded w-full mb-2": true
                    })
                    return (
                        <div className={cls} />
                    )
                })
            }
        </div>
    )
}


const EMTablRow = ({ row, index, isCollapsedAll }: any) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(!isCollapsedAll)

    useEffect(() => {
        setIsExpanded(!isCollapsedAll)
    }, [isCollapsedAll])
    const classes = classNames({
        "px-2.5 py-1.5 text-left": true,
        "group-hover/highlight:bg-ab-lime cursor-pointer": true
    })
    const trClasses = classNames({
        "group/highlight font-normal": true,
        "bg-ab-lime": isExpanded
    })
    return (
        <>
            <tr key={row.key} className={trClasses} onClick={(ev) => {
                ev.preventDefault();
                setIsExpanded(!isExpanded)
            }}>
                <td className={classes}>{row.key} {!isExpanded && <FiArrowDownRight className="inline" />}</td>
                <td className={classes}>{numeral(get(row, 'sum.value', '0')).format('$0,0')}</td>
                <td className={classes}>{numeral(get(row, 'orgCount.value', '-')).format('0')}</td>
                <td className={classes}>{numeral(get(row, 'dealsCount.value', '-')).format('0')}</td>
                <td className={classes}>{numeral(get(row, 'max.value', '0')).format('$0,0')}</td>
                <td className={classes}>{numeral(get(row, 'avg.value', '0')).format('$0,0')}</td>
            </tr>
            {
                isExpanded && get(row, 'country.buckets', {}).map((subrow: any) => {
                    return (
                        <tr key={[row.key, 'expand'].join("-")} className="text-sm  border border-slate-200 font-light">
                            <td className={classes}>{subrow.key}</td>
                            <td className={classes}>{numeral(get(subrow, 'sum.value', '0')).format('$0,0')}</td>
                            <td className={classes}>{numeral(get(subrow, 'orgCount.value', '-')).format('0')}</td>
                            <td className={classes}>{numeral(get(subrow, 'dealsCount.value', '-')).format('0')}</td>
                            <td className={classes}>{numeral(get(subrow, 'max.value', '0')).format('$0,0')}</td>
                            <td className={classes}>{numeral(get(subrow, 'avg.value', '0')).format('$0,0')}</td>
                        </tr>
                    )
                })
            }
        </>
    )
}