import React from "react"
import { useDocumentTitle } from "usehooks-ts"
//@ts-ignore
import { sprintf } from "sprintf-js"

export const PROJECT_TITLE = "AgBase - %s"

export function useProjectDocumentTitle(title: string): void {
    const titleDOM = sprintf(PROJECT_TITLE, title);
    useDocumentTitle(titleDOM, { preserveTitleOnUnmount: false })
}