import React, { useState } from "react";

import { DataGridGraphQL } from "../../elements/GraphQL/DataGridGQL";
import { useDataContainerContext } from "../../elements/DataContainer";
import { SEARCH_QUERY } from "../../queries/search";
import { Link } from "react-router-dom";
import { Persona, PersonaHovering } from "../../elements/Persona";
import { FlagIcon, FlagIconCode, FlagIconProps } from "react-flag-kit";
import { RxCheckCircled } from "react-icons/rx";

//@ts-ignore
import numeral from "numeral"
import { first, get, has, uniqBy, xorBy } from "lodash";
import { IDataGridHeader } from "../../elements/DataGrid/types";
import { format } from "date-fns";

const defaultHeaders = [
    {
        title: "Company Name",
        dataIndex: "name",
        key: "name",
        sortingKey: "name.keywords",
        render: (value: any) => {

            return (
                <Link to={['/organizations', value.target.id].join("/")} className="font-semibold">
                    <PersonaHovering text={get(value, 'target.name')} size="xs" imageURL={get(value, 'target.profile_image_url')}>
                        <div className="w-full">
                            <div className="border-b px-4 py-4 font-semibold">
                                <Persona text={get(value, 'target.name')} size="md" imageURL={get(value, 'target.profile_image_url')} />
                            </div>
                            <div className="px-4 py-2">
                                <p className="text-xs pb-1 text-gray-400">Business Model</p>
                                <p className="font-medium">{
                                    get(value, 'target.business_models', []).length > 0 && get(value, 'target.business_models', []).map((b: any) => {
                                        return <span>{b.name}</span>
                                    })
                                }{get(value, 'target.business_models', []).length < 0 && <span className="uppercase">Not recorded</span>}</p>
                            </div>
                            <div className="px-4 py-2">
                                <p className="text-xs pb-1 text-gray-400">Countries of Operation</p>
                                <p className="font-medium flex flex-wrap gap-2">{
                                    get(value, 'target.ops_countries', []).map((o: any) => {
                                        return (
                                            <span className="pr-2 inline-flex gap-2 text-sm items-center">
                                                <FlagIcon code={o.alpha2} className="object-fit" size={14} /> {o.name}
                                            </span>
                                        )
                                    })
                                }</p>
                                {
                                    get(value, 'target.ops_countries', []).length < 1 && (
                                        <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                            No operating countries recorded
                                        </span>
                                    )
                                }
                            </div>
                            <div className="px-4 py-2 pb-4">
                                <p className="text-xs pb-1 text-gray-400">Stage raised</p>
                                <p className="font-semibold text-xs flex flex-wrap gap-2">{
                                    uniqBy(get(value, 'target.aggs_deals', []), 'type').map((o: any) => {
                                        return (
                                            <span className="inline-flex px-2 py-1 gap-2 items-center uppercase bg-[#bdeddd] rounded-md">
                                                <RxCheckCircled /> {o.type}
                                            </span>
                                        )
                                    })
                                }</p>
                                {
                                    get(value, 'target.aggs_deals', []) && (
                                        <span className="border-r last:border-r-0 pr-4 flex gap-2 text-xs items-center uppercase">
                                            No deals recorded
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </PersonaHovering>
                </Link>
            )
        }
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (values: any) => get(values, 'amount', 0) === 0 ? "UNDISCLOSED" : numeral(get(values, 'amount', 0)).format('($ 0.00 a)')
    },
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (values: any) => format(get(values, 'date', new Date()), 'P')
    },
    {
        title: "Investors",
        dataIndex: "investors",
        key: "investors",
        render: (value: any) => {
            const investors = get(value, 'investors', [])

            if (investors.length < 0) return "-"
            if (investors.length > 3) {
                return (
                    <>
                        {
                            investors.slice(0, 3).map((i: any) => i.name).join(", ")
                        } +{investors.length - 3}
                    </>
                )
            }
            return investors.map((i: any) => i.name).join(", ")
        }
    },
    {
        title: "Category",
        dataIndex: "agbase_category",
        key: "target.agbase_category",
        render: (value: any) => {
            return get(value, 'target.agbase_category', []).map((s: any) => s.name).join(", ")
        }
    },
    {
        title: "Value Chain",
        dataIndex: "agbase_value_chain",
        key: "target.agbase_value_chain",
        render: (value: any) => {
            return get(value, 'target.agbase_value_chain', []).map((s: any) => s.name).join(", ")
        }
    },
    {
        title: "Regional Headquarter",
        dataIndex: "location",
        key: "location",
        render: (value: any) => {
            const office = first(get(value, 'target.offices_hq_regional', [])) as {} || { country: { name: "Not available", alpha2: undefined } };
            return (
                <span className="border-r last:border-r-0 pr-2 inline-flex gap-2 text-xs items-center">
                    {
                        //@ts-ignore
                        has(office, 'country.alpha2') && <FlagIcon code={get(office, 'country.alpha2', '')} className="object-fit rounded-sm" />} {get(office, 'country.alpha2') ? [get(office, 'city.name'), get(office, 'country.alpha2')].join(", ") : "Not available"
                    }
                </span>
            )
        }
    }
]

const headers = [
    ...defaultHeaders,
    {
        title: "Total Funding",
        dataIndex: "total_funding",
        key: "target.total_funding",
        render: (values: any) => numeral(get(values, 'target.total_funding', 0)).format('($ 0.00 a)')
    },
    {
        title: "No. Deals",
        dataIndex: "deals_no",
        key: "target.deals_no",
        render: (values: any) => numeral(get(values, 'target.deals_no', 0)).format('0,0')
    }
]

export default function DealsDirectoryContainer() {

    const { filtersQuery } = useDataContainerContext();
    const [visibleHeaders, setVisibleHeaders] = useState(defaultHeaders as IDataGridHeader[])

    return (
        <div className="container-ab overflow-x-scroll">
            <DataGridGraphQL
                availableColums={headers}
                loading={false}
                query={SEARCH_QUERY}
                root="search"
                property="items"
                onColumnsChange={(value: object) => {
                    const visible = xorBy(visibleHeaders, [value], "key") as IDataGridHeader[]
                    setVisibleHeaders(visible)
                }}
                filters={filtersQuery}
                variables={{
                    search: {
                        bucket: "deals",
                        sort: [{ key: "date", dir: "desc" }],
                        query: {
                            filters: [{ term: { enabled: true } }],
                        },
                    },
                }}
                entityName="deals"
                options={{
                    colsWidth: "auto"
                }}
                headers={visibleHeaders}
                data={[]}
            />
        </div >
    )
}