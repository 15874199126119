import React from 'react';
import { scaleLinear, scaleQuantile } from '@visx/scale';
import { Mercator } from '@visx/geo';
// @ts-ignore
import * as topojson from 'topojson-client';
import topology from './../africa-topo.json';
import { ParentSize } from "@visx/responsive";
import { find, get, xor } from 'lodash';
import { LegendItem, LegendLabel, LegendLinear, LegendThreshold } from '@visx/legend';
//@ts-ignore
import numeral from "numeral"
import { text } from 'd3';
import { useDataContainerContext } from '../../../elements/DataContainer';
import { upsert } from '../../../utils/arrays';
export const background = '#f9f7e8';

export type GeoMercatorProps = {
    events?: boolean;
    label: string;
    values: {
        key: string,
        value: number
    }[]
};

interface FeatureShape {
    type: 'Feature';
    id: string;
    geometry: { coordinates: [number, number][][]; type: 'Polygon' };
    properties: { name: string };
}

// const world = topojson.feature(topology, topology) as {
//     type: 'FeatureCollection';
//     features: FeatureShape[];
// };

export const EMGeoHeatmap = ({ events = false, values = [], label = "" }: GeoMercatorProps) => {
    // const colors = scaleLinear({
    //     domain: [
    //         0,
    //         Math.max(...values.map((v) => v.value)),
    //     ],
    //     // range: ['#eee', '#B3D9D9', '#6AA9A2', '#02BA7E', '#057a7e', '#015659', '#014447'],
    //     // range: ['#eee', '#02BA7E', '#057a7e', '#015659'],
    //     range: ['#ececec', '#015659'],
    //     // range: ['#014447', '#275659', '#42696b', '#5c7c7d', '#768f91', '#90a3a4', '#abb8b8', '#c6cdcd', '#e2e2e2'].reverse()
    //     // range: ['#eee', '#ffa020', '#ff9221', '#ff8424', '#ff7425', '#fc5e2f', '#f94b3a', '#f63a48'],

    // })
    const { addFilterAndApply, removeFilterAndApply, filtersActive } = useDataContainerContext()
    const onClick = (feature: any) => {
        console.log(filtersActive)
        const geo = find(filtersActive, { key: "target.agbase_offices.country.name.keyword" });
        if (geo) {
            //@ts-ignore
            const values = xor([feature.properties.name], geo.value)
            const filter = {
                type: "select-or",
                key: "target.agbase_offices.country.name.keyword",
                value: values
            };
            if (values.length > 1) {
                addFilterAndApply(filter)
            } else {
                removeFilterAndApply(filter)
            }
        } else {
            const filter = {
                type: "select-or",
                key: "target.agbase_offices.country.name.keyword",
                value: [feature.properties.name]
            };
            addFilterAndApply(filter)
        }
    }
    const colors = scaleLinear({
        domain: [
            0,
            1,
            Math.max(...values.map((v) => v.value)),
        ],
        range: ['#eee', '#AACAAB', '#015659']
    })

    return (
        <ParentSize className='min-h-[350px] relative'>
            {(parent) => {
                const centerX = parent.width / 2.5;
                const centerY = parent.height / 2.75;
                const scale = (parent.width / 500) * 200;
                const legendGlyphSize = 10
                return (
                    <>
                        <div className='absolute left-2.5 bottom-2.5 bg-transparent'>
                            <p className='uppercase font-semibold'>{label}</p>
                            <LegendLinear
                                scale={colors}
                                labelFormat={(d, i) => numeral(d).format('0.0a')}
                            >
                                {(labels) =>
                                    labels.reverse().map((label, i) => {
                                        return (
                                            <LegendItem
                                                key={`legend-quantile-${i}`}
                                                className='text-sm'
                                            >
                                                <svg width={legendGlyphSize} height={legendGlyphSize}>
                                                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                                                </svg>
                                                <LegendLabel align="left" margin="0 0 0 0.65rem">
                                                    {label.text}
                                                    {/* {
                                                        //@ts-ignore
                                                        label?.extent.map((v) => numeral(v).format('0.0a')).join(" to ")
                                                    } */}
                                                </LegendLabel>
                                            </LegendItem>
                                        )
                                    })
                                }
                            </LegendLinear>
                        </div>
                        <svg width={parent.width} height={parent.height}>
                            <rect x={0} y={0} width={parent.width} height={parent.height} fill={"#fff"} rx={14} />
                            <Mercator<FeatureShape>
                                //@ts-ignore
                                data={topology.features}
                                scale={scale}
                                translate={[centerX, centerY + 50]}
                            >
                                {(mercator) => (
                                    <g>

                                        {mercator.features.map(({ feature, path }, i) => {
                                            const entry = find(values, { 'key': get(feature, 'properties.adm0_a3', '') })
                                            return (
                                                <path
                                                    key={`map-feature-${i}`}
                                                    d={path || ''}
                                                    fill={colors(get(entry, 'value', 1))}
                                                    className="hover:fill-slate-500 hover:cursor-pointer"
                                                    stroke={background}
                                                    strokeWidth={0.5}
                                                    onClick={() => {
                                                        onClick(feature)
                                                    }}
                                                />
                                            )
                                        })}
                                    </g>
                                )}
                            </Mercator>
                        </svg>
                    </>
                )
            }}
        </ParentSize>
    );
}