import React, { useState } from "react"
import { Persona } from "../../elements/Persona"
import { useQuery } from "@apollo/client"
import { SEARCH_QUERY } from "../../queries/search"
import { get, has, truncate } from "lodash"
import { format, formatDistance } from "date-fns"

import { useDataContainerContext } from "../../elements/DataContainer"
import { useProjectDocumentTitle } from "../../utils/document"
import { OrgsOverviewChartBlock } from "./fragments/OrgOverviewCharts"
import { Tabs } from "../../elements/Tabs"
import { RxCaretDown, RxCaretUp } from "react-icons/rx"
import { EcosystemHistoryChartNoCompanies } from "./fragments/charts/EcosystemHistoryChart"
import { NumberInPerspectiveChart } from "./fragments/charts/NumberInProspectiveChart"
import { SectionHeading } from "../../elements/Sections"
import Marquee from "react-fast-marquee"
import { EcosystemTreeMap } from "./fragments/charts/EcosystemTreeMap"
import { Link } from "react-router-dom"
import numeral from "numeral"
import { NumberInPerspectiveRangeChart } from "../../components/Charts"
import { BUCKET_ORGS } from "../../constants"

const navs = {
    investments: {
        key: "investments",
        pane: <OrgsOverviewChartBlock />,
        title: "Compare investments",
    },
    categories: {
        key: "categories",
        pane: <p>Categoris</p>,
        title: "Compare categories",
    }
}

const ranges = [
    { to: 10000 },
    { from: 10000, to: 50000 },
    { from: 50000, to: 100000 },
    { from: 100000, to: 250000 },
    { from: 250000, to: 500000 },
    { from: 500000, to: 1000000 },
    { from: 1000000, to: 10000000 },
    { from: 10000000 },
].map((range) => {
    let key = ""
    if (has(range, 'from') && has(range, 'to')) {
        key = `${numeral(range.from || 0).format("$(0.0a)")}-${numeral(range.to || 0).format("$(0.0a)")}`
    } else if (has(range, 'from') && !has(range, 'to')) {
        //@ts-ignore
        key = `more than ${numeral(range.from || 0).format("$(0.0a)")}`
    } else if (!has(range, 'from') && has(range, 'to')) {
        key = `less than ${numeral(range.to || 0).format("$(0.0a)")}`
    }

    return ({
        from: range?.from,
        to: range?.to,
        key: key
    })
})

export default function OrganizationsOverviewContainer() {
    const [isCollapsed, setIsCollapsed] = useState(false);

    useProjectDocumentTitle("Organisations Overview")

    return (
        <div className="container-ab py-2">
            <div className="hidden md:flex relative">
                <h1 className="font-semibold text-sm py-2 grow uppercase">{!isCollapsed && "Latest additions"}</h1>
                <div>
                    <button className="hidden md:flex items-center bg-ab-lime text-black text-xs px-4 py-1 justify-center rounded-md" onClick={((ev) => {
                        ev.preventDefault();
                        setIsCollapsed(!isCollapsed)
                    })}>
                        {
                            isCollapsed && (
                                <>Show latest <RxCaretDown className="text-2xl" /></>
                            )
                        }
                        {
                            !isCollapsed && (
                                <>Hide <RxCaretUp className="text-2xl" /></>
                            )
                        }

                    </button>
                </div>
            </div>
            {
                !isCollapsed && (
                    <div className="ab-container hidden md:block">
                        <LatestOrgsBlock />
                        <hr />
                    </div>
                )
            }
            <div className="w-full pb-4">
                <OrgsOverviewChartBlock />
            </div>
            <div className="w-full pb-4">
                <SectionHeading title="Ecosystem History" description="No. of companies by foundation year" />
                <EcosystemHistoryChartNoCompanies color="#eefcfa" histogramLabel="No. of companies"
                    histogramFieldKey="founded" histogramFieldValueCount="id.keyword" />
            </div>
            <div className="w-full pb-4">
                <SectionHeading title="State of the Ecosystem" description="Companies by total funding" />
                <EcosystemTreeMap />
            </div>
            <div className="w-full pb-4">
                <SectionHeading title="Numbers in prospective" description="Breakdown of no. of companies" />
                <Tabs
                    index="countries" style="pills" qs="ps"
                    tabs={{
                        countries: {
                            title: "Countries",
                            key: "countries",
                            pane: <NumberInPerspectiveChart maxBars={20} color="#eee" histogramLabel="Countries"
                                histogramFieldKey="agbase_offices.country.name.keyword" />
                        },
                        categories: {
                            title: "Categories",
                            key: "categories",
                            pane: <NumberInPerspectiveChart maxBars={20} color="#eee" histogramLabel="Categories"
                                histogramFieldKey="agbase_category.name.keyword" />
                        },
                        valuechain: {
                            title: "Value Chain",
                            key: "valuechain",
                            pane: <NumberInPerspectiveChart maxBars={20} color="#eee" histogramLabel="Value Chains"
                                histogramFieldKey="agbase_value_chain.name.keyword" />
                        },
                        sdgs: {
                            title: "SDGs",
                            key: "sdgs",
                            pane: <NumberInPerspectiveChart maxBars={20} color="#eee" histogramLabel="SDGs"
                                histogramFieldKey="sdgs.name.keyword" />
                        }
                    }}
                />
            </div>
            <div className="w-full pb-4 flex">
                <div className="w-full">
                    <SectionHeading title="Funding size" description="Breakdown of no. of deals of companies by funding size" />
                    <NumberInPerspectiveRangeChart extra={{
                        keyed: false,
                        ranges: ranges,
                    }}
                        aggsType="range" bucket={BUCKET_ORGS}
                        maxBars={10} color="#eee" histogramLabel="Funding Size"
                        histogramFieldKey="agg_deals.amount" />
                </div>
            </div>
            <div className="w-full pb-4 flex-col flex lg:flex-row">
                <div className="w-full lg:w-1/2">
                    <SectionHeading title="Funding stages" description="Breakdown of no. of companies by funding stages" />
                    <NumberInPerspectiveChart maxBars={10} color="#eee" histogramLabel="Funding stages" histogramFieldKey="agg_deals.stage.keyword" />
                </div>
                <div className="w-full lg:w-1/2">
                    <SectionHeading title="Funding types" description="Breakdown of no. of companies by funding types" />
                    <NumberInPerspectiveChart maxBars={10} color="#eee" histogramLabel="Funding types" histogramFieldKey="agg_deals.type.keyword" />
                </div>
            </div>
        </div>
    )
}


const LatestOrgsBlock = () => {
    const { filtersQuery } = useDataContainerContext()
    const { loading, data, error } = useQuery(SEARCH_QUERY, {
        variables: {

            search: {
                size: 5,
                bucket: BUCKET_ORGS,
                sort: [{ key: "created_at", dir: "desc", },],
                query: {
                    filter: filtersQuery,
                },

            },
            fetchPolicy: "cache-and-network",
        }
    });

    return (
        <div className="hidden md:flex w-[100%] relative transform-none overflow-x-scroll gap-4 py-4">
            {
                get(data, 'search.items', []).map((datum: any) => {
                    const investors = get(datum, 'investors', [])

                    return (
                        <div key={get(datum, 'id')} className="bg-[#eefcfa] inline-block p-4 rounded-lg border border-ab-lightteal">
                            <div className="grow flex flex-col w-96">
                                <div className="grow pb-2">
                                    <div className="font-semibold text-lg pb-2">
                                        <Link to={["/organizations", get(datum, 'id')].join("/")}>
                                            <Persona text={get(datum, 'name')} imageURL={get(datum, 'profile_image_url')} />
                                        </Link>
                                    </div>
                                    <p className="text-medium text-ab-grey">{truncate(get(datum, 'description', ''), { length: 128 })}</p>
                                </div>
                                <div className="flex items-center">
                                    <p className="text-ab-grey grow uppercase text-xs">{get(datum, 'type', '')}</p>
                                    <p className="text-ab-grey text-xs">Added {formatDistance(new Date(get(datum, 'created_at', '')), new Date(), { addSuffix: true })}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}


const ComparisonChart = () => {
    return (
        <p>Chart</p>
    )
}