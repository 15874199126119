import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import { Persona, PersonaHovering } from "../../../elements/Persona";
import { OrgCard } from "../../../components/OrganizationCards";
//@ts-ignore
import numeral from "numeral"
import { get } from "lodash";

// import { Deal, TInvestor } from "../../../types";

// interface IPropsDealHistory {
// 	deals: Array<Deal>;
// 	investor: TInvestor;
// }

export const OrgsDealHistoryFragment = ({ org }: any) => {
    return (
        <div className='w-full h-auto relative bg-white mb-20 fle overflow-x-auto'>
            <div className='w-full'>
                <p className='text-lg font-semibold text-left text-gray-4100 my-6'>Investments</p>
                {org?.investments?.length > 0 ? (
                    <table className='w-full'>
                        <thead className='font-normal'>
                            <tr className='text-left bg-[#eefcfa] text-gray-3400 text-xs font-light uppercase py-3'>
                                <th className='font-normal p-2'>Company Name</th>
                                <th className='font-normal p-2'>Co-investors</th>
                                <th className='font-normal p-2'>Type</th>
                                <th className='font-normal p-2'>Amount</th>
                                <th className='font-normal p-2'>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {org?.investments?.map((deal: any, idx: number) => (
                                <tr className='text-left text-gray-4100 text-sm font-normal border-b-[1px] border-b-gray-1500'>
                                    <td className='py-4 px-2'>
                                        <Link to={["/companies", deal?.target?.id].join("/")}>
                                            <div className='flex flex-row items-center justify-start gap-2'>
                                                <PersonaHovering imageURL={deal?.target?.profile_image_url} text={deal?.target?.name}>
                                                    <OrgCard id={deal?.target?.id} />
                                                </PersonaHovering>
                                            </div>
                                        </Link>
                                    </td>
                                    <td className="py-4 px-2 flex">
                                        {
                                            get(deal, 'investors', []).filter((i: any) => i.name !== org.name).map((i: any) => {
                                                return (
                                                    <Link to={["/organizations", i.id].join("/")}>
                                                        <Persona text={''} imageURL={i.profile_image_url} size="sm" />
                                                    </Link>
                                                )
                                            })
                                        }
                                        {
                                            get(deal, 'investors', []).filter((i: any) => i.name !== org.name).length < 1 && (
                                                <p>No co-investors recorded</p>
                                            )
                                        }
                                    </td>
                                    <td className='py-4 px-2'>{deal.type}</td>
                                    <td className='py-4 px-2 uppercase'>{deal.amount === 0 ? "Undisclosed" : (numeral(deal.amount).format('$ (0,0)'))}</td>
                                    <td className='py-4 px-2'>{format(new Date(deal.date), "dd/MM/yyyy")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className='text-sm font-normal text-left text-gray-3400'>No deals history information available for {org?.name}</p>
                )}
            </div>
        </div>
    );
};
