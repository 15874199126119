import React, { ReactNode, useRef } from "react";
import classNames from "classnames";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useHover } from "usehooks-ts";

function strToHSLColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = (hash + str.length) % 360;
    return 'hsl(' + h + ',  40%, 60%, 0.8)';
}

export function createInitials(name: string) {
    return name?.split(" ").map(str => str[0]).slice(0, 2).join("");
}

export function createAvatarFromInitals(name: string, size?: string): JSX.Element {
    const cls = classNames({
        "text-white flex self-center justify-center rounded-md": true,
        "before:bg-gradient-to-b before:from-white before:to-transparent before:opacity-30 before:content-[''] before:absolute": true,
        "w-10 h-10 before:w-10 before:h-10 text-sm": _.isUndefined(size) || size === "md",
        "w-48 h-48 text-xl": size === "lg",
        "w-16 h-16 text-xl": size === "xl",
        "w-6 h-6 text-xs": size === "xs",
        "w-8 h-8 text-xs": size === "sm"
    })
    return (
        <div
            style={{ backgroundColor: strToHSLColor(name) }}
            className={cls}>
            <span className="p-2 self-center">{createInitials(name)}</span>
        </div>
    )
}

export const Avatar = ({
    imageURL,
    text,
    size
}: { imageURL?: string, text: string, size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" }) => {
    const cls = classNames({
        "rounded-md bg-cover": true,
        "before:bg-gradient-to-b before:from-white before:to-transparent before:opacity-30 before:content-[''] before:absolute": true,
        "w-10 h-10 before:w-10 before:h-10": _.isUndefined(size) || size === "md",
        "w-48 h-48": size === "lg",
        "w-16 h-16": size === "xl",
        "w-6 h-6": size === "xs",
        "w-8 h-8 text-xs": size === "sm"
    })
    return imageURL
        ? <div className={cls} style={{ backgroundImage: `url(${imageURL})` }} />
        : createAvatarFromInitals(text, size);
}

export interface IPersona {
    text: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    secondaryText?: string;
    tertiaryText?: string;
    optionalText?: string;
    status?: string;
    imageURL?: string;
    imageInitials?: string;
    extra?: any,
    to?: string
    value?: any;
}

export interface IPersonaHovering extends IPersona {
    children: ReactNode
}


export const PersonaHovering = (props: React.PropsWithChildren<IPersonaHovering>) => {
    const { children, ...rest } = props
    const hoverRef = useRef(null);
    const isHovering = useHover(hoverRef)

    //@ts-ignore
    const rect = hoverRef && hoverRef?.current?.getBoundingClientRect();

    return (
        <div className="w-full relative" ref={hoverRef}>
            <Persona {...rest} />
            {
                isHovering && (
                    <div className="fixed max-w-96 z-10" style={{ top: rect?.top, left: rect?.left + rect?.width + 20 }}>
                        <div className="bg-white shadow-md rounded-xl min-w-96">{children}</div>
                    </div>
                )
            }
        </div>
    )
}

export const Persona = (props: React.PropsWithChildren<IPersona>) => {

    return (
        <div className="flex items-center">
            <AvatarContainer text={props.text} imageURL={props.imageURL} size={props.size} secondaryText={props.secondaryText} />
        </div>
    )
}

export const AvatarContainer = ({ ...props }: any) => {
    return (
        <div className="flex items-center gap-3">
            <div role="presentation" className="pr-2">
                <Avatar text={props.text} imageURL={props.imageURL} size={props.size || "md"} />
            </div>
            <div>
                {
                    props.to ? <Link to={props.to}>{props.text}</Link> : <p>{props.text}</p>
                }
                {
                    props.secondaryText && (
                        <p className="text-gray-500">{props.secondaryText}</p>
                    )
                }
            </div>
        </div>
    )
}