export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Organisation name",
                key: "target.name",
                id: "target.name"
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "target.operating_status.keyword",
                id: "target.operating_status.keyword",
                dynamic: false,
                bucket: "deals",
                options: [
                    {
                        "key": "y",
                        "value": "y",
                        "title": "Operating"
                    },
                    {
                        "key": "n",
                        "value": "n",
                        "title": "Shut down"
                    },
                    {
                        "key": "c",
                        "value": "c",
                        "title": "Under review"
                    }
                ]
            },
            {
                type: "range",
                title: "Deals date",
                key: "date",
                id: "date"
            },
            {
                type: "select-or",
                title: "Categories",
                key: "target.agbase_category.name.keyword",
                id: "target.agbase_category.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "target.agbase_value_chain.name.keyword",
                id: "target.agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                title: "Deals size range",
                type: "range",
                key: "amount",
                id: "amount",
            },
            {
                type: "select-or",
                title: "Investor Name",
                key: "investors.name.keyword",
                id: "investors.name.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "range",
                title: "Founded between",
                key: "target.founded",
                id: "target.founded"
            }
        ]
    },
    {
        title: "Funding Information",
        filters: [
            {
                type: "exists",
                title: "Investors information available",
                key: "investors.name",
                id: "investors.name",
            },
            {
                type: "select-or",
                title: "Investors Type",
                key: "investors.type.keyword",
                id: "investors.type.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Funding Type",
                key: "type.keyword",
                id: "type.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Investors",
                key: "investors.name.keyword",
                id: "investors.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
        ]
    },
    {
        title: "Geography",
        filters: [
            {
                type: "select-or",
                title: "Global HQ Countries",
                key: "target.offices_hq_global.country.name.keyword",
                id: "target.offices_hq_global.country.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Regional HQ Countries",
                key: "target.agbase_offices.country.name.keyword",
                id: "target.agbase_offices.country.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Countries of operations",
                key: "target.ops_countries.name.keyword",
                id: "target.ops_countries.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                type: "select-or",
                title: "Regions of operations",
                key: "target.ops_countries.region.keyword",
                id: "target.ops_countries.region.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
        ]
    },
    {
        title: "Impact Metrics",
        filters: [
            {
                title: "SDG name",
                type: "select-or",
                key: "target.sdgs.name.keyword",
                id: "target.sdgs.name.keyword",
                dynamic: true,
                bucket: 'deals',
                options: []
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "target.attributes.organization:ResourceTag/Impact",
                id: "target.attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: 'deals',
                options: []
            }
        ]
    }
];