import { get } from "lodash";
import React, { useState } from "react"
import { FlagIcon } from "react-flag-kit";
import { FiFacebook, FiLink, FiLinkedin, FiShare, FiShare2 } from "react-icons/fi";
//@ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from "classnames";


function getCompanyType(type: string) {
    switch (type) {
        case 'startup':
            return 'Startup'
    }

    return 'Organisation'
}

const SocialItem = ({ to, icon }: any) => {
    return (
        <a href={to} target="_blank" rel="noreferrer" className="md:bg-ab-lime text-ab-darkgreen text-xs px-0 md:px-6 md:text-lg py-2 rounded-md">
            {icon}
        </a>
    )
}

const ShareButton = ({ to }: any) => {
    const [copied, setCopied] = useState(false);
    const classes = classNames({
        "flex items-center gap-2 bg-[#bfe6dc] py-2 px-4 rounded-md text-sm md:text-base text-ab-lightgreen font-semibold w-full justify-center": true,
        "transition duration-300 ease-in-out": copied
    })
    return (
        <CopyToClipboard text={to} onCopy={() => setCopied(true)}>
            <button
                className={classes}>
                <FiShare2 /> {copied ? "Now you can share!" : "Share"}
            </button>
        </CopyToClipboard>
    )
}

export const OrgSidebarFragment = ({ org }: { org: any }) => {

    if (!org) return null;

    const country = get(org, 'offices[0].city.subdivision.country', undefined)
    return (

        <div className='w-full lg:h-full px-4 md:px-8' id='company-step-1'>
            <div className='flex flex-row lg:flex-col justify-center items-center w-full'>
                <div className='w-full flex lg:flex-col justify-start items-center gap-8'>
                    <img
                        src={get(org, 'profile_image_url', '')}
                        alt='company'
                        className='w-16 md:w-24 h-16 md:h-24 object-contain my-4 lg:my-8 rounded-md border border-[#f2f2f2] bg-[#ffffff]'
                    />
                    <div className="flex flex-col md:gap-2 justify-start items-start lg:justify-center lg:items-center grow">
                        <h1 className="text-sm md:text-2xl font-semibold text-left md:text-center">{get(org, 'name', '')}</h1>
                        <div className="flex gap-2 items-center">
                            <p className="text-sm md:text-base text-ab-grey">{getCompanyType(org.type)}</p>
                            {
                                country && (
                                    <div className="flex md:hidden items-center gap-2  w-4 font-semibold">
                                        <FlagIcon code={country.alpha2} className="rounded-sm" />
                                    </div>
                                )
                            }
                            <div className="md:hidden flex flex-row flex-wrap gap-2 text-xs">
                                <SocialItem to={get(org, 'socials.website')} icon={<FiLink />} />
                                <SocialItem to={get(org, 'socials.linkedin')} icon={<FiLinkedin />} />
                                <SocialItem to={`https://facebook.com/${get(org, 'socials.facebook')}`} icon={<FiFacebook />} />
                            </div>
                        </div>
                        {
                            country && (
                                <div className="hidden lg:flex items-center gap-2 font-semibold text-lg pt-4">
                                    <FlagIcon code={country.alpha2} className="rounded-sm" /> {country.name}
                                </div>
                            )
                        }
                    </div>
                    <div className="hidden md:flex flex-row md:flex-col gap-2 text-xs">
                        <div className="w-full items-center flex flex-col">
                            <ShareButton to={window.location.href} />
                        </div>
                        <div className='font-medium text-center text-gray-4100'>
                            <div className='flex sm:justify-center items-center gap-4 w-full text-sm'>
                                <SocialItem to={get(org, 'socials.website')} icon={<FiLink />} />
                                <SocialItem to={get(org, 'socials.linkedin')} icon={<FiLinkedin />} />
                                <SocialItem to={`https://facebook.com/${get(org, 'socials.facebook')}`} icon={<FiFacebook />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
