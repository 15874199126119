export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "select-or",
                title: "Type",
                key: "resource_type.name.keyword",
                id: "resource_type.name.keyword",
                dynamic: true,
                bucket: 'resources',
                options: []
            },
            {
                type: "select-or",
                title: "Categories",
                key: "agbase_category.name.keyword",
                id: "agbase_category.name.keyword",
                dynamic: true,
                bucket: 'resources',
                options: []
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "agbase_value_chain.name.keyword",
                id: "agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: 'resources',
                options: []
            },
            {
                type: "select-or",
                title: "Countries of interest",
                key: "ops_countries.country.name.keyword",
                id: "ops_countries.country.name.keyword",
                dynamic: true,
                bucket: 'resources',
                options: []
            },
        ]
    },
]