import React, { useEffect, useMemo, useState } from "react";
import { IFilter } from "./FilterFactory";
import { FilterTitle } from "./fragments/FilterTitle";
import { RxCross1, RxTriangleDown } from "react-icons/rx";
import { get } from "lodash";
import { useLazyQuery } from "@apollo/client";
import { FETCH_FACETS_BY_NAME } from "../queries";
import { useCombobox, useMultipleSelection } from "downshift";
import classNames from "classnames";

interface IFilterSelect extends IFilter {
    dynamic?: boolean;
    selection: "single" | "tags";
    bucket?: string;
}

export default function FilterSelect({ ...props }: IFilterSelect) {
    const { options = [], title, addFilter, id: filterId, type: filterType, defaultValues, removeFilter, dynamic, bucket } = props

    const [inputValue, setInputValue] = React.useState('')

    const [opts, setOpts] = useState(options || []);
    const [selectedItems, setSelectedItems] = useState([] as { key: string, value: string, title: string }[])

    const [fetch, { loading, data, called }] = useLazyQuery(FETCH_FACETS_BY_NAME, {
        variables: {
            facets: {
                bucket: bucket,
                list: [filterId]
            }
        }
    });

    useEffect(() => {
        if (dynamic) {
            fetch();
        }
    }, [dynamic]);

    useEffect(() => {
        if (called && !loading && data) {
            const o = get(data, 'facets.rows[0].values', []).map((op: any) => {
                return { key: op.key, value: op.key, title: `${op.key} (${op.count})` }
            });
            setOpts(o)
            console.log(o)
        }
    }, [data, called, loading])

    useEffect(() => {
        if (opts.length > 0) {
            const values = get(defaultValues, 'value', []);
            setSelectedItems(opts.filter((item) => {
                return values.includes(item.key)
            }) || [])
        }
    }, [defaultValues, opts])

    const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
        useMultipleSelection({
            selectedItems: selectedItems,
            onStateChange({ selectedItems: newSelectedItems, type }) {
                switch (type) {
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                    case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                    case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                        //@ts-ignore
                        setSelectedItems(newSelectedItems)
                        //@ts-ignore
                        if (newSelectedItems?.length > 0) {
                            //@ts-ignore
                            addFilter({ type: filterType, key: filterId, value: newSelectedItems.map((v) => v.value) })
                        } else {
                            removeFilter({
                                type: filterType, key: filterId, value: []
                            })
                        }
                        break
                    default:
                        break
                }
            },
        })

    const optList = opts.filter((item) => {
        //@ts-ignore
        return !selectedItems.includes(item)
    })

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox({
        //@ts-ignore
        items: optList,
        itemToString(item) {
            return item ? item.title : ''
        },
        defaultHighlightedIndex: -1, // after selection, highlight the first item.
        selectedItem: null,
        inputValue,
        stateReducer(state, actionAndChanges) {
            const { changes, type } = actionAndChanges

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                        highlightedIndex: 0, // with the first option highlighted.
                    }
                default:
                    return changes
            }
        },
        onStateChange({
            inputValue: newInputValue,
            type,
            selectedItem: newSelectedItem,
        }) {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:

                    if (newSelectedItem) {
                        //@ts-ignore
                        addFilter({ type: filterType, key: filterId, value: [...selectedItems, newSelectedItem].map((v) => v.value) })
                        //@ts-ignore
                        setSelectedItems([...selectedItems, newSelectedItem])
                        setInputValue('')
                    }
                    break

                case useCombobox.stateChangeTypes.InputChange:
                    //@ts-ignore
                    setInputValue(newInputValue)

                    break
                default:
                    break
            }
        },
    })

    return (
        <div className="w-full">
            <FilterTitle title={title} active={opts.length > 0} loading={loading} />
            <div className="relative w-full">
                <div className="w-full relative flex">
                    <RxTriangleDown className="text-xl absolute right-1.5 top-3.5" />
                    <input
                        className="w-full mt-2 border disabled:bg-ab-darkergreen disabled:placeholder:text-ab-darkteal disabled:border-ab-darkgreen border-ab-lightdarkgreen rounded-md bg-ab-darkgreen text-ab-lightteal py-1.5 outline-none focus:border-r-bs-light-black placeholder:text-ab-lightteal px-4 placeholder:font-medium"
                        placeholder={opts.length > 0 ? "All" : ""}
                        type="text"
                        disabled={opts.length < 1}
                        {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                    />
                </div>
                <div className="w-full flex gap-2 pt-2 flex-wrap">
                    {selectedItems.map(function renderSelectedItem(
                        selectedItemForRender: any,
                        index: number,
                    ) {
                        return (
                            <span
                                className="rounded-md bg-ab-lightteal cursor-pointer text-ab-darkergreen px-2 py-1 hover:bg-red-400  gap-1 inline-flex items-center"
                                key={`selected-item-${index}`}
                                {...getSelectedItemProps({
                                    selectedItem: selectedItemForRender,
                                    index,
                                })}
                                onClick={e => {
                                    e.stopPropagation()
                                    removeSelectedItem(selectedItemForRender)
                                }}
                            >
                                {
                                    //@ts-ignore
                                    selectedItemForRender.title
                                }
                                <span className="cursor-pointer text-xs">
                                    <RxCross1 />
                                </span>
                            </span>
                        )
                    })}
                </div>
                <ul
                    className={`absolute w-inherit bg-white rounded-lg mt-1 text-ab-darkgreen shadow-md max-h-80 overflow-scroll p-0 z-10 w-full ${!(isOpen && opts.length) && 'hidden'}`}
                    {...getMenuProps()}
                >
                    {isOpen &&
                        optList
                            .map((item, index) => (
                                <li
                                    className={classNames({
                                        'bg-ab-lightdarkgreen text-ab-lightteal': highlightedIndex === index,
                                        'font-bold': selectedItem === item,
                                        'py-2 px-3 shadow-sm flex flex-col w-full cursor-pointer': true
                                    })}
                                    key={`${item.key}`}
                                    {...getItemProps({ item })}
                                >
                                    <span>{item.title}</span>
                                </li>
                            ))}
                </ul>
            </div>
        </div >
    )
}

export const InputSelectMulti = () => {
    return (
        <div className="relative">
            <RxTriangleDown className="text-xl absolute right-1.5 top-3.5" />
            <input
                className="w-full mt-2 border border-ab-lightdarkgreen rounded-md bg-ab-darkgreen text-ab-lightteal py-1.5 outline-none focus:border-r-bs-light-black placeholder:text-ab-lightteal px-4 placeholder:font-medium"
                placeholder="All"
                type="text"
            />
        </div>
    )
}