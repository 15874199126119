import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import _, { flatten } from "lodash";
import { upsert } from "../../../utils/arrays";
import { decode, encode } from "js-base64";
import { useSearchParams } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

export type TDataContainerFilterCallback = (value: IDataContainerFilter) => void;

export interface IDataContainerFilterValue {
    id: string;
    value: string;
}

export interface IDataContainerFilter {
    type: string;
    key: string;
    value: IDataContainerFilterValue[] | string[] | boolean | string | object;
}

export interface IDataContainerSort {
    key: string;
    dir: string;
}

export interface IDataContainerContext {
    filters: any;
    isDirty: boolean;
    filtersActive: IDataContainerFilter[];
    filtersChanges: IDataContainerFilter[];
    activeSort: IDataContainerSort[];
    filtersQuery: any;
    areFiltersVisible?: boolean;
    sortBy?: (sort: IDataContainerSort) => void; // @todo make it mandatory
    resetFilters: () => void;
    clearAllFilters: () => void;
    addFilter: TDataContainerFilterCallback;
    removeFilter: TDataContainerFilterCallback;
    applyFilters: () => void;
    addFilterAndApply: TDataContainerFilterCallback;
    removeFilterAndApply: TDataContainerFilterCallback;
    setAreFiltersVisibile: (visibility: boolean) => void;
}

const DataContainerContext = createContext<IDataContainerContext>(
    {} as IDataContainerContext
);

interface IDataContainerQueryContext {
    filtersQuery: any;
}

const DataContainerQueryContext = createContext<IDataContainerQueryContext>(
    {} as IDataContainerQueryContext
);

function mapFilterTypeToGraphQL(type: string, value: any, key: string): any {
    switch (type) {
        case 'search':
        case 'search-fulltext':
            return { "query_string": { "query": `*${value}*`, "default_field": key } }
        case 'select-and':
            return { "term": { [key]: value } }
        case 'select-or':
            return { "terms": { [key]: value } }
        case 'negate':
            return { "term": { [key]: false } }
        case 'boolean':
            return { "term": { [key]: value } }
        case 'exists':
            return { "exists": { "field": key } }
        case 'exists_nth':
            return { "exists": { "field": key, "nth": value } }
        case 'range':
            return { "range": { [key]: value } } // {"range": { "founded": { "lte": "2022", "gte": "2020" } }
        case 'missing':
            return {
                "bool": {
                    "must_not": {
                        "exists": {
                            "field": key
                        }
                    }
                }
            }
        default:
            return {
                key: 'null',
                value: null
            }
    }
}

function mapFilterToGraphQL(filters: IDataContainerFilter[]) {
    const f = filters.map((f) => {
        return mapFilterTypeToGraphQL(f.type, f.value, f.key)
    })
    return flatten(f);
}

interface IDataContainerProvider {
    children: ReactNode;
    filters: any[]
    sort?: IDataContainerSort[],
    defaultFilters?: any
}

export function DataContainerProvider({ children, filters, sort, defaultFilters }: IDataContainerProvider) {
    const [isVisible, setIsVisible] = useLocalStorage('showFilters', true)
    const [state, setState] = useState({
        filters: filters || [],
        isDirty: false,
        activeSort: sort as IDataContainerSort[],
        filtersActive: defaultFilters || [] as IDataContainerFilter[],
        filtersChanges: [] as IDataContainerFilter[],
        filtersQuery: defaultFilters || [] as any,
        areFiltersVisible: isVisible
    });
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('f') !== null) {
            const filters: IDataContainerFilter[] = JSON.parse(decode(searchParams.get('f') || '{}'));
            setState({
                ...state,
                filtersQuery: mapFilterToGraphQL(filters),
                filtersActive: filters,
                filtersChanges: filters,
                areFiltersVisible: isVisible
            });
        } else {
            setState({ ...state, areFiltersVisible: isVisible })
        }

    }, [searchParams, isVisible]);

    // useEffect(() => {
    //     setState({ ...state, areFiltersVisible: isVisible })
    // }, [isVisible])

    const sortBy = (sort: IDataContainerSort) => {
        setState({
            ...state, activeSort: [sort]
        })
    }

    const setAreFiltersVisibile = (visibility: boolean) => {
        setIsVisible(visibility)
        setState({
            ...state,
            areFiltersVisible: visibility
        })
    }

    const resetFilters = () => {
        setState({ ...state, filtersChanges: [], isDirty: false })
    }

    const clearAllFilters = () => {
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL([]),
            filtersChanges: [],
            filtersActive: []
        });

        updateSearchParams([]);
    }

    const updateSearchParams = (filters: IDataContainerFilter[]) => {
        const qs = encode(JSON.stringify(filters), true);
        searchParams.delete('f');
        searchParams.append('f', qs);
        setSearchParams(searchParams);
    }

    const applyFilters = () => {
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL(state.filtersChanges),
            filtersActive: state.filtersChanges,
            filtersChanges: state.filtersChanges,
            isDirty: false
        });
        updateSearchParams(state.filtersChanges);
    }

    const addFilter = (filter: IDataContainerFilter) => {
        console.log("addFilter", filter)
        const filters = upsert(state.filtersChanges, filter, 'key')

        setState({
            ...state, filtersChanges: filters, isDirty: !_.isEqual(filters, state.filtersActive)
        })
    }

    const addFilterAndApply = (filter: IDataContainerFilter) => {
        const filters = upsert(state.filtersChanges, filter, 'key')
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL(filters),
            filtersActive: filters,
            filtersChanges: filters,
            isDirty: false
        });
        updateSearchParams(filters);
    }

    const removeFilterAndApply = (filter: IDataContainerFilter) => {
        const filters = _.filter(state.filtersChanges, (f: any) => {
            return f.key !== filter.key
        })
        setState({
            ...state,
            filtersQuery: mapFilterToGraphQL(filters),
            filtersActive: filters,
            filtersChanges: filters,
            isDirty: false
        });
        updateSearchParams(filters);
    }

    const removeFilter = (filter: any) => {
        const filters = _.filter(state.filtersChanges, (f: any) => {
            return f.key !== filter.key
        })
        setState({
            ...state, filtersChanges: filters, isDirty: !_.isEqual(filters, state.filtersActive)
        })
    }

    return (
        <DataContainerContext.Provider
            value={{ ...state, resetFilters, addFilter, removeFilter, clearAllFilters, applyFilters, sortBy, addFilterAndApply, removeFilterAndApply, setAreFiltersVisibile }}>
            <DataContainerQueryContext.Provider value={{ filtersQuery: state.filtersQuery }}>
                {children}
            </DataContainerQueryContext.Provider>
        </DataContainerContext.Provider>
    )
}

export function useDataContainerContext() {
    return useContext(DataContainerContext)
}

export function useDataContainerQueryContext() {
    return useContext(DataContainerQueryContext)
}