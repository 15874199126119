import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
	query search($search: SearchRequest!) {
		search(search: $search) {
			items
			total
			took
			cursor
		}
	}
`;

export const FETCH_AGGREGATE_DATA = gql`
	query ($aggs: AggregationRequest!) {
		aggregate(aggregations: $aggs) {
			aggregations
			took
		}
	}
`;