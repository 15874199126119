import React, { ReactComponentElement, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { RxInfoCircled } from "react-icons/rx";

export const FilterTitle = ({
    title,
    help,
    overrideStyle = '',
    active = true,
    loading = false,
    actions
}: { title: string, help?: string, overrideStyle?: string, actions?: ReactElement, active?: boolean, loading?: boolean }) => {
    const cls = classNames({
        "text-sm text-ab-darkteal flex items-center flex-grow items-center gap-2": true,
    }, overrideStyle)

    return (
        <p className={cls}>
            <span>{title}</span> {!active && !loading && <span className="text-ab-lightgreen">(coming soon!)</span>} {loading && <span>Loading..</span>}
            {
                help && (
                    <RxInfoCircled className="ml-2 h-5 w-5 text-bs-light-black" />
                )
            }
            {
                actions && (
                    <span>{actions}</span>
                )
            }
        </p>
    )
}